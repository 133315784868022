import React, { useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  ListItemText,
  Paper,
  Typography,
  Chip,
  IconButton,
  Stack,
} from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import PhoneIcon from "@mui/icons-material/Phone";
import AlternateEmailRoundedIcon from "@mui/icons-material/AlternateEmailRounded";
import flowerIcon from "../../../../assets/images/agents/flowerIcon.png";
import flowerIconWhite from "../../../../assets/images/agents/flowerIconWhite.png";
import AttachMLSNumber from "../../modals/attachMLS";
import UpdateListing from "../../modals/updateListing";
import AvatarCustom from "../../../../components/uiElements/avatar/index";
import { ListingColors } from "../../../../utils/listingStatusColor";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import BuyerCompensation from "../../modals/BuyerCompensaction";
import ListingExpiryDate from "../../modals/listingExpiryDate";
import moment from "moment";
const ListingHeaderCard = (props) => {
  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openBuyer, setOpenBuyer] = useState(false);
  const [openExpiry, setOpenExpiry] = useState(false);
  let { ListingData, classes } = props;
  const [imageFlower, setImageFlower] = useState(flowerIcon);
  const [mlsData, setMlsData] = useState({});
  return (
    <>
      <Paper
        sx={{ backgroundColor: "white" }}
        className={classes.listingStatsCard}
      >
        <Box className={classes.listingStatsCardLeft}>
          <Avatar
            variant="rounded"
            src={
              mlsData.property_img
                ? mlsData?.property_img
                : ListingData.property_img
                ? ListingData.property_img
                : process.env.REACT_APP_NO_PROPERTY_IMAGE
            }
          />
        </Box>
        <Box className={classes.listingStatsCardRight}>
          <Grid container direction="row">
            <Grid
              md={8}
              lg={9}
              xs={12}
              className={classes.TransactionUserInfoLeftPanel}
            >
              <Box>
                <Typography
                  classes={{ root: classes.TransactionUserInfoLeftName }}
                >
                  {ListingData.street}
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    classes={{ root: classes.userInfoDetailPhone }}
                    variant="subtitle1"
                  >
                    {ListingData.city}, {ListingData.state}{" "}
                    {ListingData.zipcode}
                  </Typography>
                </Box>
                <Box sx={{ mt: "10px" }}>
                  <Chip
                    label={ListingData.status}
                    size="small"
                    color="success"
                    sx={{
                      backgroundColor:
                        ListingColors[ListingData.status] || "black",
                      color: "white",
                      textTransform: "capitalize",
                      minWidth: "100px",
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid md={4} lg={3} xs={12} className={classes.userInfoRightPanel}>
              <Stack
                sx={{ mb: "15px" }}
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Typography
                  variant="h6"
                  sx={{
                    display: "inline-block",
                    marginRight: "22px",
                    fontSize: "14px",
                  }}
                >
                  Listed By
                </Typography>
                <Typography
                  sx={{ fontSize: "14px", textAlign: "left", minWidth: "10ch" }}
                >
                  {ListingData.agent?.first_name} <br />{" "}
                  {ListingData.agent?.last_name}{" "}
                </Typography>
                {ListingData?.agent ? (
                  <AvatarCustom
                    sx={{
                      height: "45px",
                      width: "45px",
                      mr: "10px",
                      ml: "15px",
                    }}
                    variant="rounded"
                    src={
                      ListingData?.agent?.profile_images?.profile_img_thumbnail
                    }
                  >
                    {`${ListingData?.agent.first_name?.slice(
                      0,
                      1
                    )}${ListingData?.agent.last_name?.slice(0, 1)}`}
                  </AvatarCustom>
                ) : null}
              </Stack>
              <Button
                onClick={() => window.open("tel:" + ListingData?.agent?.phone)}
                variant="outlined"
              >
                <PhoneIcon />
              </Button>
              {ListingData?.agent?.admin_channel_id ? (
                <Button
                  onMouseEnter={() => {
                    setImageFlower(flowerIconWhite);
                  }}
                  onMouseLeave={() => {
                    setImageFlower(flowerIcon);
                  }}
                  variant="outlined"
                  onClick={() =>
                    window.open(
                      `slack://channel?team=${ListingData?.agent?.slack_team_id}&id=${ListingData?.agent?.admin_channel_id}`
                    )
                  }
                >
                  <img
                    style={{ width: "24px" }}
                    src={imageFlower}
                    alt="flower icon"
                  />
                </Button>
              ) : null}
              <Button
                onClick={() =>
                  window.open("mailto:" + ListingData?.agent?.email)
                }
                variant="outlined"
              >
                <AlternateEmailRoundedIcon />
              </Button>
              <br />{" "}
              {/* <Box className={classes.editableLockArea}>
            <IconButton className={classes.editableLock} onClick={() => setIsEditAble(!isEditAble)} aria-label="locked">
              {isEditAble ? <LockOpenIcon sx={{ color: "rgba(0, 0, 0, 0.38)" }} /> : <LockIcon sx={{ color: "rgba(0, 0, 0, 0.38)" }} />}
            </IconButton>
          </Box> */}
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{ mt: "37px" }}
          >
            <Grid item xs={2.1}>
              <Box className={classes.statsItem}>
                <ListItemText
                  primary={
                    <Typography
                      classes={{
                        root: classes.TransactionUserInfoDetailStateValue,
                      }}
                    >
                      {ListingData.price
                        ? `$${new Intl.NumberFormat().format(
                            ListingData.price
                          )}`
                        : "-"}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      classes={{
                        root: classes.TransactionUserInfoDetailStateDes,
                      }}
                    >
                      Price
                    </Typography>
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={1.4}>
              <Box className={classes.statsItem}>
                <ListItemText
                  primary={
                    <Typography
                      classes={{
                        root: classes.TransactionUserInfoDetailStateValue,
                      }}
                    >
                      {ListingData.bedrooms || mlsData.bedrooms || "0"}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      classes={{
                        root: classes.TransactionUserInfoDetailStateDes,
                      }}
                    >
                      Bedrooms
                    </Typography>
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={1.4}>
              <Box className={classes.statsItem}>
                <ListItemText
                  primary={
                    <Typography
                      classes={{
                        root: classes.TransactionUserInfoDetailStateValue,
                      }}
                    >
                      {ListingData.baths || mlsData.baths || "-"}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      classes={{
                        root: classes.TransactionUserInfoDetailStateDes,
                      }}
                    >
                      Bathrooms
                    </Typography>
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={1.4}>
              <Box className={classes.statsItem}>
                <ListItemText
                  primary={
                    <Typography
                      classes={{
                        root: classes.TransactionUserInfoDetailStateValue,
                      }}
                    >
                      {ListingData.sq_feet || mlsData.sq_feet || "-"}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      classes={{
                        root: classes.TransactionUserInfoDetailStateDes,
                      }}
                    >
                      Sq. Feet
                    </Typography>
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={1.7}>
              <Box
                className={
                  ListingData?.buyer_compensation
                    ? classes.statsItem
                    : classes.statsItemEmpty
                }
                sx={{ cursor: "pointer" }}
                onClick={() => setOpenBuyer(true)}
              >
                <ListItemText
                  primary={
                    <Typography
                      classes={{
                        root: classes.TransactionUserInfoDetailStateValue,
                      }}
                    >
                      {ListingData?.buyer_compensation ? (
                        `${ListingData.buyer_compensation}%`
                      ) : (
                        <Typography sx={{ fontSize: "15px" }}>ADD</Typography>
                      )}
                      {ListingData?.buyer_compensation && (
                        <IconButton
                          onClick={() => setOpen(true)}
                          className={`${classes.editMLlIcon} editButton`}
                          sx={{ display: "none" }}
                          size="small"
                        >
                          <EditRoundedIcon fontSize="small" />
                        </IconButton>
                      )}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      classes={{
                        root: classes.TransactionUserInfoDetailStateDes,
                      }}
                    >
                      Buyer Comp.
                    </Typography>
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box
                className={
                  ListingData?.expire_date
                    ? classes.statsItem
                    : classes.statsItemEmpty
                }
                sx={{ cursor: "pointer" }}
                onClick={() => setOpenExpiry(true)}
              >
                <ListItemText
                  primary={
                    <Typography
                      classes={{
                        root: classes.TransactionUserInfoDetailStateValue,
                      }}
                      sx={{ fontSize: "18px !important" }}
                    >
                      {ListingData?.expire_date ? (
                        moment(ListingData.expire_date).format("l")
                      ) : (
                        <Typography sx={{ fontSize: "15px" }}>ADD</Typography>
                      )}
                      {ListingData?.expire_date && (
                        <IconButton
                          onClick={() => setOpen(true)}
                          className={`${classes.editMLlIcon} editButton`}
                          sx={{ display: "none" }}
                          size="small"
                        >
                          <EditRoundedIcon fontSize="small" />
                        </IconButton>
                      )}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      classes={{
                        root: classes.TransactionUserInfoDetailStateDes,
                      }}
                    >
                      Expire Date
                    </Typography>
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box
                className={
                  ListingData.mls_id || ListingData.fmls_number
                    ? classes.statsItem
                    : classes.statsItemEmpty
                }
                onClick={() => setOpen(true)}
                sx={{ cursor: "pointer" }}
              >
                <ListItemText
                  primary={
                    <Typography
                      classes={{
                        root: classes.TransactionUserInfoDetailStateValue,
                      }}
                    >
                      {ListingData.mls_id
                        ? ListingData.mls_id
                        : ListingData.fmls_number
                        ? ListingData.fmls_number
                        : mlsData
                        ? mlsData.mlsId
                        : "-"}
                      {(ListingData.mls_id ||
                        ListingData.fmls_number ||
                        mlsData.mlsId) && (
                        <IconButton
                          onClick={() => setOpen(true)}
                          className={`${classes.editMLlIcon} editButton`}
                          sx={{ display: "none" }}
                          size="small"
                        >
                          <EditRoundedIcon fontSize="small" />
                        </IconButton>
                      )}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      classes={{
                        root: classes.TransactionUserInfoDetailStateDes,
                      }}
                    >
                      MLS Number
                    </Typography>
                  }
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>

      {open && (
        <AttachMLSNumber
          // isUpdate={ListingData.fmls_number || mlsData.mlsId}
          ListingData={ListingData}
          setMlsData={setMlsData}
          open={open}
          id={ListingData.id}
          setOpenUpdate={setOpenUpdate}
          setOpen={setOpen}
        />
      )}
      <UpdateListing
        open={openUpdate}
        id={ListingData.id}
        setOpen={setOpenUpdate}
      />
      <BuyerCompensation
        open={openBuyer}
        setOpen={setOpenBuyer}
        ListingData={ListingData}
      />
      <ListingExpiryDate
        open={openExpiry}
        setOpen={setOpenExpiry}
        ListingData={ListingData}
      />
    </>
  );
};

export default withStyles(styleSheet, { name: "ListingHeaderCardStyle" })(
  ListingHeaderCard
);
