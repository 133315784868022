import { Alert, Box, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Button from "../../../../../components/uiElements/button";
import { createNoteAgentRecruitment } from "../../../../../redux/agents/agentRecruitment/createNoteAgentRecruitment";
import { MentionsInput, Mention } from "react-mentions";
import defaultStyle from "../../../../leads/common/Tabs/TabContent/mentions/defaultStyle.js";
import defaultMentionStyle from "../../../../leads/common/Tabs/TabContent/mentions/defaultMentionStyle";
export const NoteTab = () => {
  const param = useParams();
  const dispatch = useDispatch();
  const { currentTab } = useSelector((item) => item.agentProfile);
  const [routeId, setRouteId] = useState();
  const [noteText, setNoteText] = useState("");
  const [userIds, setUserIds] = useState([]);
  const [open, setOpen] = useState({ state: false, type: "", desc: "" });
  const agentRosterList = useSelector((item) => item.users.storedListOfUsers);
  const adminAuth = useSelector((state) => state.adminAuth);
  useEffect(() => {
    if (param) {
      setRouteId(param?.id);
    }
  }, [param]);

  const createNoteFunc = () => {
    const schema = {
      agentRecruitmentId: routeId,
      body: noteText,
      userIds: userIds.filter((item, index) => userIds.indexOf(item) === index),
    };
    if (noteText) {
      dispatch(createNoteAgentRecruitment({ ...schema }));
      setOpen({
        state: true,
        type: "success",
        desc: "You have created note successfully!",
      });
    } else {
      setOpen({
        state: true,
        type: "error",
        desc: "You can't create empty notes!",
      });
    }
    setNoteText("");
    setUserIds([]);
  };

  const handleClose = (e) => {
    setOpen({ state: false, type: "", desc: "" });
  };

  return (
    <>
      <MentionsInput
        value={noteText}
        onChange={(event, newValue, newPlainTextValue, mentions) => {
          setUserIds(mentions?.map((item) => item.id));
          setNoteText(event.target.value);
        }}
        style={defaultStyle}
        onKeyDown={(event) => {
          if (event.key === "Enter" && !event.shiftKey) {
            createNoteFunc();
            setNoteText("");
          }
        }}
      >
        <Mention
          trigger="@"
          data={
            agentRosterList?.list?.length
              ? agentRosterList?.list
                  ?.filter((it) => it.id !== adminAuth?.adminDetail?.id)
                  ?.map((item) => ({
                    id: item.id,
                    display: item?.first_name + " " + item.last_name,
                  }))
              : []
          }
          style={defaultMentionStyle}
          markup={"@[__display__^__id__]"}
          // renderSuggestion={this.renderUserSuggestion}
        />
      </MentionsInput>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "15px",
        }}
      >
        <Button onClick={createNoteFunc} variant="contained">
          {currentTab?.loading ? "Creating..." : "Create note"}
        </Button>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open?.type}
        autoHideDuration={1500}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={open?.type}
          sx={{ width: "100%" }}
        >
          {open?.desc}
        </Alert>
      </Snackbar>
    </>
  );
};
