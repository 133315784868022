import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Typography,
  InputAdornment,
  IconButton,
  OutlinedInput,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Skeleton,
} from "@mui/material";
import AgentsSubHeader from "../agentListing/subHeader";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import MapRoundedIcon from "@mui/icons-material/MapRounded";
import SearchIcon from "@mui/icons-material/Search";
import ButtonGroups from "../../../components/uiElements/buttonGroup";
import { marketCustom } from "../../../utils/markets";
import { useSelector, useDispatch } from "react-redux";
import MapSection from "./map";
import { getAgentRosterList } from "../../../redux/agents/agentRoster/getAgentRosterList";
import { getMarkets } from "../../../redux/agents/addRecruitAgent";
function AgentMap(props) {
  let { classes } = props;
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [search, setSearch] = useState(false);
  const dispatch = useDispatch();
  const data = useSelector((item) => item.agentRoster.agentRosterList);
  const markets = useSelector((state) => state.agentList.markets);
  const { list, totalAgents, loading } = data;
  const [filters, setFilters] = useState({
    only_agents: true,
    pageSize: 500,
    pageNumber: 1,
    search: "",
    market_id: "",
  });
  useEffect(() => {
    if (!markets || markets.length === 0) {
      dispatch(getMarkets());
    }
  }, []);
  useEffect(() => {
    dispatch(getAgentRosterList(filters));
  }, [filters]);

  useEffect(() => {
    if (search !== false) {
      const timeOutId = setTimeout(
        () => setFilters({ ...filters, search }),
        500
      );
      return () => clearTimeout(timeOutId);
    }
  }, [search]);

  return (
    <Box>
      <AgentsSubHeader disableAdd {...props} />
      <Container maxWidth="lg" sx={{ mb: "30px" }}>
        <Box className={classes.agentMapHeader}>
          <Box className={classes.agentMapHeaderLeft}>
            <MapRoundedIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />{" "}
            <Typography className={classes.agentMapHeading}>
              Agent Map
            </Typography>{" "}
          </Box>
          <Box className={classes.agentMapHeaderRight}>
            <ButtonGroups
              id="market-button-menu"
              bgColor="#0B0909"
              bgColorHover="#0B0909"
              placement={"bottom"}
              height="38.5px"
              size="medium"
              color="white"
              options={
                markets?.length
                  ? markets.map((item) => ({
                      title: item.name,
                      value: item.id,
                    }))
                  : []
              }
              onChangeMenu={(value) =>
                setFilters((pre) => ({ ...pre, market_id: value }))
              }
              placeholder="Market"
              {...props}
            />
            <OutlinedInput
              onChange={(e) => {
                if (setSearch) {
                  setSearch(e.target.value);
                }
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton edge="end">
                    <SearchIcon fontSize="small" />
                  </IconButton>
                </InputAdornment>
              }
              size="small"
              placeholder="Search..."
            />
          </Box>
        </Box>
        <Grid container spacing={2}>
          <Grid item sm={12} md={4} lg={4} xl={3}>
            <Box className={classes.agentListBox}>
              <Typography className={classes.agentListBoxHeading}>
                Agent List
              </Typography>
              <List disablePadding className={classes.agentList}>
                {loading
                  ? Array.from(Array(20).keys())?.map((_, i) => (
                      <ListItem key={i} disablePadding divider>
                        <ListItemButton>
                          <ListItemAvatar sx={{ minWidth: "40px" }}>
                            <Skeleton
                              variant="rounded"
                              sx={{ width: "28px", height: "28px" }}
                            />
                          </ListItemAvatar>
                          <ListItemText
                            classes={{ primary: classes.listItemContent }}
                            primary={<Skeleton variant="text" />}
                          />
                        </ListItemButton>
                      </ListItem>
                    ))
                  : null}
                {list?.length
                  ? list?.map((item) => (
                      <ListItem key={item.id} disablePadding divider>
                        <ListItemButton onClick={() => setSelectedAgent(item)}>
                          <ListItemAvatar sx={{ minWidth: "40px" }}>
                            <Avatar
                              sx={{
                                width: "25px",
                                height: "25px",
                                background: "#8e8e8e",
                              }}
                              variant="rounded"
                              src={item?.profile_images?.profile_img}
                            />
                          </ListItemAvatar>
                          <ListItemText
                            classes={{ primary: classes.listItemContent }}
                            primary={item.full_name}
                          />
                        </ListItemButton>
                      </ListItem>
                    ))
                  : null}
              </List>
            </Box>
          </Grid>
          <Grid item sm={12} md={8} lg={8} xl={9}>
            <Box className={classes.agentMapBox}>
              <MapSection
                {...props}
                users={list}
                loading={loading}
                selectedAgent={selectedAgent}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
export default withStyles(styleSheet, { name: "AgentMapStyle" })(AgentMap);
