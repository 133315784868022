import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import {
  Button,
  Card,
  Chip,
  Container,
  InputBase,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  Skeleton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import DataGridComponent from "../projects/DataGridComponent";
import Avatar from "../../components/uiElements/avatar/index";
import { getTeams } from "../../redux/teams/team";
import TeamForm from "./teamForm";
import { getTeamLeaders } from "../../redux/teams/teamLeaders";
import { Link, useLocation } from "react-router-dom";
import TabMenu from "../agentRoster/agentListing/TabMenu";
import { styled, alpha } from "@mui/material/styles";
import { Add } from "@mui/icons-material";
import moment from "moment";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(0),
    width: "auto",
  },
  height: "36px",
  border: "1px solid rgba(0, 0, 0, 0.23)",
  marginLeft: 0,
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  height: "35px",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(0)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    "&::placeholder": {
      color: "rgba(0, 0, 0, 0.6) !important",
    },
    color: "rgba(0, 0, 0, 0.6)",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  TopUsersBarArea: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px 20px",
    height: "50px",
  },
  TopUsersBarAreaLeft: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "15px",
  },
  UsersTabsUI: {
    "& .Mui-selected": {
      color: "#0B0909 !important",
    },
    "& .MuiTab-root": {
      fontSize: "13px !important",
      minHeight: "40px !important",
    },
    "& .MuiTabs-root": {
      minHeight: "40px  !important",
    },
    "& .MuiTabs-scroller": {
      height: "36px  !important",
    },
  },
  grid: {
    height: "calc(100vh - 300px)",
    backgroundColor: "white",
  },
  title: {
    fontSize: "25px!important",
    fontFamily: "AvenirNext!important",
    fontWeight: "500!important",
    display: "flex !important",
    alignItems: "center !important",
  },
  dialogTitle: {
    fontSize: "24px!important",
    fontFamily: "AvenirNext!important",
  },
  header: {
    display: "flex",
    gap: "30px",
    alignItems: "center",
    marginBottom: "20px!important",
  },
  button: {
    flexShrink: "0 !important",
    display: "flex !important",
    alignItems: "center !important",
    justifyContent: "center !important",
    gap: "2px !important",
    fontWeight: "400  !important",
    fontSize: "12.5px !important",
    backgroundColor: "#0B0909!important",
    color: "#fff!important",
    fontFamily: "AvenirNext !important",
    height: "34px !important",
    borderRadius: "9px  !important",
    "&:hover": {
      borderColor: "#0B0909!important",
    },
    "& span": {
      marginTop: "4px !important",
    },
  },
  saveButton: {
    fontSize: "16px!important",
    backgroundColor: "#0B0909!important",
    color: "#fff!important",
    fontFamily: "AvenirNext !important",
    fontWeight: "500 !important",
    width: "107px",
    height: "42px",
    "&:hover": {
      borderColor: "#0B0909!important",
    },
  },
  closeButton: {
    fontSize: "16px!important",
    color: "#0B0909!important",
    borderColor: "#0B0909!important",
    border: "1px",
    width: "107px",
    height: "42px",
    fontFamily: "AvenirNext !important",
    fontWeight: "600 !important",
    letterSpacing: "0.46px",
    "&:hover": {
      borderColor: "#0B0909!important",
    },
  },
}));
const columns = [
  {
    field: "name",
    headerName: "Team Name",
    renderCell: (params) => {
      return <>{params.value}</>;
    },
    flex: 1,
    sortable: true,
  },
  {
    field: "team_leader",
    headerName: "Team Leader",
    sortComparator: (v1, v2) => v1?.first_name?.localeCompare(v2?.first_name),
    renderCell: (params) => {
      if (params.value)
        return (
          <ListItem
            alignItems="flex-start"
            sx={{ width: "100%", maxWidth: 360, padding: 0, margin: 0 }}
          >
            <ListItemAvatar>
              <Avatar
                sx={{ marginRight: "10px", borderRadius: "4px" }}
                src={params.value?.profile_img_thumbnail?.profile_img_thumbnail}
                variant="square"
              >{`${params.value?.first_name?.slice(
                0,
                1
              )}${params.value?.last_name?.slice(0, 1)}`}</Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={params.value.first_name}
              secondary={
                <React.Fragment>{params.value.last_name}</React.Fragment>
              }
            />
          </ListItem>
        );
      else return null;
    },
    width: 200,
    sortable: true,
  },
  {
    field: "team_color",
    headerName: "Team Color",
    renderCell: (params) => {
      return (
        <>
          {params.value ? (
            <Chip
              label={params.value}
              sx={{
                background: `${params.value}`,
                borderRadius: "16px!important",
                width: "98px!important",
                height: "30px!important",
                color: "#000!important",
                fontSize: "16px!important",
              }}
            />
          ) : null}
        </>
      );
    },
    flex: 1,
    sortable: true,
  },
  {
    field: "agents_count",
    headerName: "Agent Count",
    renderCell: (params) => {
      return <>{params.value}</>;
    },
    type: "number",
    width: 150,
    sortable: true,
  },
  {
    field: "createdAt",
    headerName: "Created On",
    type: "dateTime",
    renderCell: (params) => {
      return <>{moment(params.value).format("MM/DD/YYYY HH:MM:SS a")}</>;
    },
    flex: 1,
    width: 200,
    sortable: true,
  },
];
function Teams() {
  /**
   * Variables and States
   */
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();

  const makeDropDown = useMediaQuery("(max-width:1024px)");

  const [openTeamDetails, setOpenTeamDetails] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const [team, setTeam] = React.useState(null); // selected team to edit
  const [pageNo, setPageNo] = React.useState(1);

  const teams = useSelector((state) => state.teams.teams);
  const { data, isLoading } = teams;
  /**
   * End Region: Variables and States
   */
  /**
   * Helpers
   */
  const handleOpenTeam = (row) => {
    setTeam(row);
    setOpenTeamDetails(true);
  };

  const handleClose = () => {
    setOpenTeamDetails(false);
  };
  /**
   * End Region: Helpers
   */
  /**
   * useEffets Hooks
   */
  useEffect(() => {
    dispatch(getTeams({ pageNumber: pageNo, pageSize: 15 }));
    dispatch(getTeamLeaders());
  }, []);
  /**
   * End Region: useEffect Hooks
   */

  return (
    <>
      <Card
        variant="outlined"
        sx={{ position: "sticky", top: "64px", zIndex: "100" }}
      >
        <Box className={classes.TopUsersBarArea}>
          <Box className={classes.TopUsersBarAreaLeft}>
            <Box>
              <Box className={classes.UsersTabsUI}>
                <Tabs
                  value={location.pathname}
                  variant="standard"
                  TabIndicatorProps={{
                    sx: { background: "black", color: "black" },
                  }}
                  aria-label="project tabs"
                >
                  <Tab
                    disableFocusRipple={true}
                    disableRipple={true}
                    label="Agent Roster"
                    to={`/agent_roster`}
                    component={Link}
                    sx={{ padding: "0px 10px" }}
                  />
                  <Tab
                    disableFocusRipple={true}
                    disableRipple={true}
                    label="Users"
                    to={`/users`}
                    component={Link}
                    sx={{ padding: "0px 10px" }}
                  />
                  <Tab
                    label="Lenders"
                    value={`/users/lenders`}
                    to={`/users/lenders`}
                    component={Link}
                    sx={{ padding: "0px 10px" }}
                  />
                  <Tab
                    disableFocusRipple={true}
                    disableRipple={true}
                    label="Teams"
                    value={`/teams`}
                    to={`/teams`}
                    sx={{ padding: "0px 10px" }}
                  />
                  <Tab
                    label="Map"
                    value={`/agent_roster/map`}
                    to={`/agent_roster/map`}
                    component={Link}
                    sx={{ padding: "0px 10px" }}
                  />
                </Tabs>
              </Box>
            </Box>
          </Box>
          <Box className={classes.TopUsersBarAreaLeft}>
            <Button
              className={classes.button}
              onClick={() => handleOpenTeam(null)}
            >
              <Add sx={{ fontSize: "22px" }} /> <span>Add Team</span>
            </Button>
            <Search>
              <StyledInputBase
                onChange={(e) => setSearchText(e.target.value)}
                placeholder="Search Teams"
                inputProps={{ "aria-label": "search" }}
              />
            </Search>
          </Box>
        </Box>
      </Card>
      <Container maxWidth="lg" sx={{ mt: "30px" }}>
        <TeamForm
          classes={classes}
          teamData={team}
          open={openTeamDetails}
          handleClose={handleClose}
          setPageNo={setPageNo}
        />

        <Box className={classes.header}>
          <Typography className={classes.title}>
            {isLoading ? (
              <Skeleton
                variant="rectangular"
                width="30px"
                height="30px"
                animation="wave"
                sx={{ mr: "8px" }}
              />
            ) : (
              data?.count || 0
            )}{" "}
            Teams
          </Typography>
        </Box>

        <Box className={classes.grid}>
          <DataGridComponent
            rows={data?.teams || []}
            rowHeight={50}
            columns={columns}
            hideFooter
            loading={isLoading}
            onRowsScrollEnd={() => {
              if (data?.teams.length < data?.count && data?.teams.length) {
                let updatedPageNo = pageNo + 1;
                setPageNo(updatedPageNo);
                setTimeout(() => {
                  dispatch(
                    getTeams({ pageNumber: updatedPageNo, pageSize: 15 })
                  );
                }, 500);
              }
            }}
            initialState={{
              sorting: {
                sortModel: [
                  { field: "team_leader", sort: "asc" },
                  { field: "agents_count", sort: "asc" },
                ],
              },
            }}
            onCellClick={(row) => handleOpenTeam(row)}
            disableColumnMenu
          />
        </Box>
      </Container>
    </>
  );
}

export default Teams;
