import React, { useEffect, useState } from "react";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  TextField,
  Stack,
  Box,
  Typography,
  InputAdornment,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import linkdinIcon from "../../../../../assets/images/social/linkdinIcon.png";
import zillowIcon from "../../../../../assets/images/social/zillowIcon.png";
import Instagram from "../../../../../assets/images/social/Instagram.png";
import tiktok from "../../../../../assets/images/social/tiktok.png";
import Snapchat from "../../../../../assets/images/social/Snapchat.png";
import faceBook from "../../../../../assets/images/social/faceBook.jpg";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { updateAgentBusinessCard } from "../../../../../redux/agents/agentRosterDetail/businessCardDetail";
import { useParams } from "react-router-dom";
import LockIcon from "@mui/icons-material/Lock";
import LoadingButton from "@mui/lab/LoadingButton";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { updateAgentRosterProfile } from "../../../../../redux/agents/agentRoster/updateProfile";
import ResponseAlert from "../../../../../components/responseAlert";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import {
  storeAgentAchievements,
  getAgentAchievements,
  updateAchievement,
  deleteAchievement,
} from "../../../../../redux/agents/agentRosterDetail/achievements";
const SocialProfileAndCardButton = ({ classes }) => {
  const [isEditAble, setIsEditAble] = useState(false);
  const [isEditAble1, setIsEditAble1] = useState(false);
  const [isEditMis, setIsEditMis] = useState(false);
  const [awards, setAwards] = useState([]);
  const [education, setEducation] = useState([]);
  const [specialties, setSpecialties] = useState([]);
  const [buttonData, setButtonData] = useState([
    {
      button_index: 1,
      text: "",
      url: "",
    },
  ]);
  const [accountDetail, setAccountDetail] = useState({
    zillow_account: "",
    tiktok_account: "",
    linked_in_account: "",
    instagram_account: "",
    facebook_account: "",
    snapchat_account: "",
  });
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });

  const dispatch = useDispatch();
  const { id } = useParams();
  const agentRosterProfile = useSelector(
    (item) => item.agentRoster.agentRosterProfile
  );
  const { profile, updateLoading } = agentRosterProfile;

  useEffect(() => {
    if (
      profile?.agent_bussiness_card_buttons &&
      profile?.agent_bussiness_card_buttons.length > 0
    ) {
      setButtonData(profile?.agent_bussiness_card_buttons);
    }
  }, [profile?.agent_bussiness_card_buttons]);

  useEffect(() => {
    if (profile?.id) {
      let newDetail = {
        zillow_account: "",
        tiktok_account: "",
        linked_in_account: "",
        instagram_account: "",
        facebook_account: "",
        snapchat_account: "",
      };
      if (profile.zillow_account && profile.zillow_account !== "0") {
        newDetail.zillow_account = profile.zillow_account;
      }
      if (profile.tiktok_account && profile.tiktok_account !== "0") {
        newDetail.tiktok_account = profile.tiktok_account;
      }
      if (profile.linked_in_account && profile.linked_in_account !== "0") {
        newDetail.linked_in_account = profile.linked_in_account;
      }
      if (profile.instagram_account && profile.instagram_account !== "0") {
        newDetail.instagram_account = profile.instagram_account;
      }
      if (profile.instagram_account && profile.instagram_account !== "0") {
        newDetail.instagram_account = profile.instagram_account;
      }
      if (profile.facebook_account && profile.facebook_account !== "0") {
        newDetail.facebook_account = profile.facebook_account;
      }
      if (profile.snapchat_account && profile.snapchat_account !== "0") {
        newDetail.snapchat_account = profile.snapchat_account;
      }
      setAccountDetail(newDetail);
    }
  }, [profile?.id]);

  const handleUpdatePreData = (data) => {
    if (data?.award?.length) {
      setAwards(data?.award);
    }
    if (data?.education?.length) {
      setEducation(data?.education);
    }
    if (data?.speciality?.length) {
      setSpecialties(data?.speciality);
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(
        getAgentAchievements({ id, handleSuccess: handleUpdatePreData })
      );
    }
  }, []);

  const handleSuccess = () => {
    setErrorAlert({
      errorMsg: "You have successfully update social detail",
      errorType: "success",
      isOpen: true,
    });
    setIsEditAble(false);
  };
  const handleError = (error) => {
    setErrorAlert({
      errorMsg: JSON.stringify(error),
      errorType: "error",
      isOpen: true,
    });
  };

  const handleSave = () => {
    dispatch(
      updateAgentRosterProfile({
        ...accountDetail,
        id: profile?.id,
        type: "social",
        handleSuccess,
        handleError,
      })
    );
  };
  const handleUpdateDetail = (field, value) => {
    setAccountDetail({ ...accountDetail, [field]: value });
  };
  const handleAddButton = () => {
    let buttonData_new = [...buttonData];
    buttonData_new.push({
      button_index: buttonData?.length + 1,
      text: "",
      url: "",
    });
    setButtonData(buttonData_new);
  };
  const ordinal_suffix_of = (i) => {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return "st";
    }
    if (j == 2 && k != 12) {
      return "nd";
    }
    if (j == 3 && k != 13) {
      return "rd";
    }
    return "th";
  };
  const handleDeleteButton = (index) => {
    let buttonData_new = [...buttonData];
    buttonData_new.splice(index, 1);
    setButtonData(buttonData_new);
  };
  const handleSaveButtonsData = () => {
    let new_buttonData = [...buttonData];
    let checkFlag = new_buttonData.filter((item) => item.text && item.url);
    if (checkFlag?.length > 0) {
      buttonData.map(async (item, id) => {
        const formData = {
          button_index: item.button_index,
          text: item.text,
          url: item.url,
          id: profile?.id,
        };
        dispatch(updateAgentBusinessCard(formData));
        setIsEditAble1(false);
        setErrorAlert({
          errorMsg: "Saved successfully",
          errorType: "success",
          isOpen: true,
        });
      });
    } else {
      setErrorAlert({
        errorMsg: "Please enter the card details first",
        errorType: "warning",
        isOpen: true,
      });
    }
  };

  const handleAddField = (type) => {
    if (type === "award") {
      setAwards((award) => [...award, { value: "" }]);
    }
    if (type === "education") {
      setEducation((education) => [...education, { value: "" }]);
    }
    if (type === "speciality") {
      setSpecialties((specialties) => [...specialties, { value: "" }]);
    }
  };

  const handleRemoveField = (item, index, type) => {
    if (type === "award") {
      let newAwards = [...awards];
      setAwards(newAwards.filter((it, id) => id !== index));
    }
    if (type === "education") {
      let newEducation = [...education];
      setEducation(newEducation.filter((it, id) => id !== index));
    }
    if (type === "speciality") {
      let newSpecialties = [...specialties];
      setSpecialties(newSpecialties.filter((it, id) => id !== index));
    }
    if (item?.id) {
      dispatch(deleteAchievement({ id: item.id }));
    }
  };
  const handleUpdateFieldValue = (value, index, type) => {
    if (type === "award") {
      let updatedArray = [
        ...awards.slice(0, index),
        { ...awards[index], value: value },
        ...awards.slice(index + 1),
      ];
      setAwards(updatedArray);
    }
    if (type === "education") {
      let updatedArray = [
        ...education.slice(0, index),
        { ...education[index], value: value },
        ...education.slice(index + 1),
      ];
      setEducation(updatedArray);
    }
    if (type === "speciality") {
      let updatedArray = [
        ...specialties.slice(0, index),
        { ...specialties[index], value: value },
        ...specialties.slice(index + 1),
      ];
      setSpecialties(updatedArray);
    }
  };

  const handleStoreAchievement = (value, item, type) => {
    if (item.id && value) {
      dispatch(
        updateAchievement({
          id: item?.id,
          value,
        })
      );
    } else if (value) {
      dispatch(
        storeAgentAchievements({
          achievements: [{ type, value }],
          id,
        })
      );
    }
  };

  return (
    <Grid container direction="row" spacing={6}>
      <Grid item lg={6} xs={12}>
        <Card
          variant="outlined"
          sx={{ backgroundColor: "white" }}
          className={classes.pageRoot}
        >
          <CardHeader
            classes={{ title: classes.pageHeading }}
            title={" Social Profiles"}
            action={
              <div>
                <IconButton
                  onClick={() => setIsEditAble(!isEditAble)}
                  aria-label="locked"
                >
                  {isEditAble ? (
                    <LockOpenIcon sx={{ color: "rgba(0, 0, 0, 0.38)" }} />
                  ) : (
                    <LockIcon sx={{ color: "rgba(0, 0, 0, 0.38)" }} />
                  )}
                </IconButton>
              </div>
            }
          />
          <CardContent>
            <Grid container direction="row" spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <CardHeader
                    classes={{
                      title: classes.agentProfileDetailHeader,
                      subheader: classes.agentProfileDetail,
                    }}
                    avatar={<Avatar src={zillowIcon} />}
                    title="Zillow"
                    subheader={
                      isEditAble ? (
                        <TextField
                          size="small"
                          fullWidth
                          variant="outlined"
                          onChange={(e) =>
                            handleUpdateDetail("zillow_account", e.target.value)
                          }
                          value={accountDetail.zillow_account}
                        />
                      ) : accountDetail.zillow_account ? (
                        accountDetail.zillow_account
                      ) : (
                        "Not Exist"
                      )
                    }
                  />
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <CardHeader
                    classes={{
                      title: classes.agentProfileDetailHeader,
                      subheader: classes.agentProfileDetail,
                    }}
                    avatar={<Avatar src={linkdinIcon} />}
                    title="Linkedin"
                    subheader={
                      isEditAble ? (
                        <TextField
                          size="small"
                          fullWidth
                          variant="outlined"
                          onChange={(e) =>
                            handleUpdateDetail(
                              "linked_in_account",
                              e.target.value
                            )
                          }
                          value={accountDetail.linked_in_account}
                        />
                      ) : accountDetail.linked_in_account ? (
                        accountDetail.linked_in_account
                      ) : (
                        "Not Exist"
                      )
                    }
                  />
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <CardHeader
                    classes={{
                      title: classes.agentProfileDetailHeader,
                      subheader: classes.agentProfileDetail,
                    }}
                    avatar={<Avatar src={Instagram} />}
                    title="Instagram"
                    subheader={
                      isEditAble ? (
                        <TextField
                          size="small"
                          fullWidth
                          variant="outlined"
                          onChange={(e) =>
                            handleUpdateDetail(
                              "instagram_account",
                              e.target.value
                            )
                          }
                          value={accountDetail.instagram_account}
                        />
                      ) : accountDetail.instagram_account ? (
                        accountDetail.instagram_account
                      ) : (
                        "Not Exist"
                      )
                    }
                  />
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <CardHeader
                    classes={{
                      title: classes.agentProfileDetailHeader,
                      subheader: classes.agentProfileDetail,
                    }}
                    avatar={<Avatar src={tiktok} />}
                    title="Tiktok"
                    subheader={
                      isEditAble ? (
                        <TextField
                          size="small"
                          fullWidth
                          variant="outlined"
                          onChange={(e) =>
                            handleUpdateDetail("tiktok_account", e.target.value)
                          }
                          value={accountDetail.tiktok_account}
                        />
                      ) : accountDetail.tiktok_account ? (
                        accountDetail.tiktok_account
                      ) : (
                        "Not Exist"
                      )
                    }
                  />
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <CardHeader
                    classes={{
                      title: classes.agentProfileDetailHeader,
                      subheader: classes.agentProfileDetail,
                    }}
                    avatar={<Avatar src={Snapchat} />}
                    title="Snapchat"
                    subheader={
                      isEditAble ? (
                        <TextField
                          size="small"
                          fullWidth
                          variant="outlined"
                          onChange={(e) =>
                            handleUpdateDetail(
                              "snapchat_account",
                              e.target.value
                            )
                          }
                          value={accountDetail.snapchat_account}
                        />
                      ) : accountDetail.snapchat_account ? (
                        accountDetail.snapchat_account
                      ) : (
                        "Not Exist"
                      )
                    }
                  />
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <CardHeader
                    classes={{
                      title: classes.agentProfileDetailHeader,
                      subheader: classes.agentProfileDetail,
                    }}
                    avatar={<Avatar src={faceBook} />}
                    title="Facebook"
                    subheader={
                      isEditAble ? (
                        <TextField
                          size="small"
                          fullWidth
                          variant="outlined"
                          onChange={(e) =>
                            handleUpdateDetail(
                              "facebook_account",
                              e.target.value
                            )
                          }
                          value={accountDetail.facebook_account}
                        />
                      ) : accountDetail.facebook_account ? (
                        accountDetail.facebook_account
                      ) : (
                        "Not Exist"
                      )
                    }
                  />
                </Card>
              </Grid>
              <Grid hidden={!isEditAble} item xs={12}>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={2}
                >
                  <LoadingButton
                    onClick={handleSave}
                    loading={updateLoading === "social"}
                    classes={{ root: classes.saveButTon }}
                    color="inherit"
                    autoFocus
                    size="small"
                    loadingPosition="start"
                  >
                    Save
                  </LoadingButton>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={6} xs={12}>
        <Card
          variant="outlined"
          sx={{ backgroundColor: "white" }}
          className={classes.pageRoot}
        >
          <CardHeader
            classes={{ title: classes.pageHeading }}
            title={
              <div>
                Digital Card Buttons
                <IconButton
                  aria-label="locked"
                  onClick={() => {
                    if (isEditAble1) {
                      handleAddButton();
                    }
                  }}
                >
                  <AddCircleIcon sx={{ color: "rgba(0, 0, 0, 0.87)" }} />
                </IconButton>
              </div>
            }
            action={
              <div>
                <IconButton
                  onClick={() => setIsEditAble1(!isEditAble1)}
                  aria-label="locked"
                >
                  {isEditAble1 ? (
                    <LockOpenIcon sx={{ color: "rgba(0, 0, 0, 0.38)" }} />
                  ) : (
                    <LockIcon sx={{ color: "rgba(0, 0, 0, 0.38)" }} />
                  )}
                </IconButton>
              </div>
            }
          />
          <CardContent>
            <Grid container direction="row" spacing={6}>
              <Grid item xs={12}>
                {buttonData.map((item, index) => (
                  <Grid key={index} item xs={12} sx={{ mt: "10px" }}>
                    <Card>
                      <CardHeader
                        classes={{
                          title: classes.agentProfileDetailHeader,
                          subheader: classes.agentProfileDetail,
                        }}
                        avatar={
                          <>
                            {index + 1}
                            <sub>{ordinal_suffix_of(index + 1)}</sub>
                          </>
                        }
                        title={
                          isEditAble1 ? (
                            <TextField
                              size="small"
                              fullWidth
                              placeholder="Enter Card button title"
                              variant="outlined"
                              onChange={(e) => {
                                item.text = e.target.value;
                                setButtonData([...buttonData]);
                              }}
                              value={item.text}
                            />
                          ) : item.text ? (
                            item.text
                          ) : (
                            "Enter Card button title"
                          )
                        }
                        subheader={
                          isEditAble1 ? (
                            <TextField
                              size="small"
                              fullWidth
                              sx={{ mt: "10px" }}
                              placeholder="Enter card button Url"
                              variant="outlined"
                              onChange={(e) => {
                                item.url = e.target.value;
                                setButtonData([...buttonData]);
                              }}
                              value={item.url}
                            />
                          ) : item.url ? (
                            item.url
                          ) : (
                            "Enter card button Url"
                          )
                        }
                        action={
                          index > 0 && isEditAble1 ? (
                            <IconButton
                              onClick={() => handleDeleteButton(index)}
                              aria-label="locked"
                            >
                              <DeleteIcon
                                sx={{ color: "rgba(0, 0, 0, 0.38)" }}
                              />{" "}
                            </IconButton>
                          ) : (
                            ""
                          )
                        }
                      />
                    </Card>
                  </Grid>
                ))}
                <Grid hidden={!isEditAble1} item xs={12}>
                  <Stack
                    sx={{ mt: "20px" }}
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={2}
                  >
                    <LoadingButton
                      onClick={handleSaveButtonsData}
                      classes={{ root: classes.saveButTon }}
                      color="inherit"
                      autoFocus
                      size="small"
                      loadingPosition="start"
                    >
                      Save
                    </LoadingButton>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card
          variant="outlined"
          sx={{ backgroundColor: "white" }}
          className={classes.pageRoot}
        >
          <CardHeader
            classes={{ title: classes.pageHeading }}
            title={"Miscellaneous Details"}
            action={
              <div>
                <IconButton
                  onClick={() => setIsEditMis((flag) => !flag)}
                  aria-label="locked"
                >
                  {isEditMis ? (
                    <LockOpenIcon sx={{ color: "rgba(0, 0, 0, 0.38)" }} />
                  ) : (
                    <LockIcon sx={{ color: "rgba(0, 0, 0, 0.38)" }} />
                  )}
                </IconButton>
              </div>
            }
          />
          <CardContent sx={{ pointerEvents: isEditMis ? "" : "none" }}>
            <Grid container direction="row" spacing={6}>
              <Grid item xs={12} md={9}>
                <Box className={classes.miscellaneousDetailItem}>
                  <Typography
                    className={classes.miscellaneousDetailItemHeading}
                  >
                    Awards
                  </Typography>{" "}
                  <IconButton onClick={() => handleAddField("award")}>
                    <AddCircleIcon sx={{ color: "rgba(0, 0, 0, 0.87)" }} />
                  </IconButton>
                </Box>
                <Box className={classes.miscellaneousDetailItemFields}>
                  {awards?.map((item, index) => (
                    <TextField
                      variant="standard"
                      size="small"
                      key={`awards${item?.value}${index}`}
                      fullWidth
                      value={item?.value}
                      autoFocus={isEditMis ? true : false}
                      onBlur={(e) =>
                        handleStoreAchievement(e.target.value, item, "award")
                      }
                      onChange={(e) =>
                        handleUpdateFieldValue(e.target.value, index, "award")
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                handleRemoveField(item, index, "award")
                              }
                              edge="end"
                              tabIndex={-1}
                            >
                              <ClearIcon fontSize="small" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  ))}
                </Box>
                <br />
                <Box className={classes.miscellaneousDetailItem}>
                  <Typography
                    className={classes.miscellaneousDetailItemHeading}
                  >
                    Education
                  </Typography>{" "}
                  <IconButton onClick={() => handleAddField("education")}>
                    <AddCircleIcon sx={{ color: "rgba(0, 0, 0, 0.87)" }} />
                  </IconButton>
                </Box>
                <Box className={classes.miscellaneousDetailItemFields}>
                  {education?.map((item, index) => (
                    <TextField
                      variant="standard"
                      size="small"
                      key={`Education${item?.value}${index}`}
                      fullWidth
                      value={item?.value}
                      onBlur={(e) =>
                        handleStoreAchievement(
                          e.target.value,
                          item,
                          "education"
                        )
                      }
                      autoFocus={isEditMis ? true : false}
                      onChange={(e) =>
                        handleUpdateFieldValue(
                          e.target.value,
                          index,
                          "education"
                        )
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                handleRemoveField(item, index, "education")
                              }
                              edge="end"
                              tabIndex={-1}
                            >
                              <ClearIcon fontSize="small" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  ))}
                </Box>
                <br />
                <Box className={classes.miscellaneousDetailItem}>
                  <Typography
                    className={classes.miscellaneousDetailItemHeading}
                  >
                    Specialties
                  </Typography>{" "}
                  <IconButton onClick={() => handleAddField("speciality")}>
                    <AddCircleIcon sx={{ color: "rgba(0, 0, 0, 0.87)" }} />
                  </IconButton>
                </Box>
                <Box className={classes.miscellaneousDetailItemFields}>
                  {specialties?.map((item, index) => (
                    <TextField
                      variant="standard"
                      size="small"
                      key={`Specialties${item?.value}${index}`}
                      fullWidth
                      value={item?.value}
                      autoFocus={isEditMis ? true : false}
                      onBlur={(e) =>
                        handleStoreAchievement(
                          e.target.value,
                          item,
                          "speciality"
                        )
                      }
                      onChange={(e) =>
                        handleUpdateFieldValue(
                          e.target.value,
                          index,
                          "speciality"
                        )
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                handleRemoveField(item, index, "speciality")
                              }
                              edge="end"
                              tabIndex={-1}
                            >
                              <ClearIcon fontSize="small" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  ))}
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </Grid>
  );
};

export default withStyles(styleSheet, {
  name: "agentRosterSocialProfileAndCardButtonStyle",
})(SocialProfileAndCardButton);
