import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import {
  Chip,
  InputLabel,
  Grid,
  Card,
  CardHeader,
  TextField,
  Box,
  Stack,
  Typography,
  IconButton,
  Checkbox,
  FormControlLabel,
  Autocomplete,
  OutlinedInput,
  InputAdornment,
  Select,
  MenuItem,
} from "@mui/material";
import agentLockIcon from "../../../../assets/images/agents/agentLockIcon.png";
import agentHouseIcon from "../../../../assets/images/agents/agentHouseIcon.jpg";
import ResponseAlert from "../../../../components/responseAlert";
import { updateData } from "../../../../utils/updateData";
import { getInventoryLogHistory } from "../../../../redux/agents/agentRosterDetail/inventory/getLogsHistory";
import { updateInventoryItem } from "../../../../redux/inventory";
import { getInventorySpecificLogHistory } from "../../../../redux/agents/agentRosterDetail/inventory/getSpecificLogs";
import { getAgentInventories } from "../../../../redux/agents/agentRosterDetail/inventory/getAgentInventory";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ButtonGroups from "../../../../components/uiElements/buttonGroup";
import Avatar from "../../../../components/uiElements/avatar";
import { getAllPropertyList } from "../../../../redux/propertyListing/getAllPropertyListing";
import HistoryIcon from "@mui/icons-material/History";
import checkIcon from "../../../../assets/checkIcon.png";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import LoadingButton from "@mui/lab/LoadingButton";
import SwapHorizontalCircleIcon from "@mui/icons-material/SwapHorizontalCircle";
import {
  updateCheckoutInventory,
  updateAssignUser,
  attachInventoryListing,
  updateListAfterCheckIn,
  updateCheckoutState,
  markAsLost,
  updateMarkAsLost,
} from "../../../../redux/inventory";
import { checkoutAgentInventory } from "../../../../redux/agents/agentRosterDetail/inventory";
import { ListingColors } from "../../../../utils/listingStatusColor";
import { handleUpdateSuperWebStatus } from "../../../../redux/agents/agentRosterDetail/inventory";
import { updateSuperWebsiteStatus } from "../../../../redux/inventory/index";
import ChatBubbleOutlineRoundedIcon from "@mui/icons-material/ChatBubbleOutlineRounded";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CommentSection from "./commentSection";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

function InventoryItemDetailModal(props) {
  let {
    open,
    setOpen,
    classes,
    lockBoxDetail,
    setLockBoxDetail,
    IsAgent,
    updateSupraWeb,
    setLockBoxData,
    isListing,
  } = props;
  const [openAssign, setOpenAssign] = useState(false);
  const [loading, setLoading] = useState(false);
  const [manage, setManage] = useState("");
  const [assigningUser, setAssigningUser] = useState("");
  const [attachingList, setAttachingList] = useState("");
  const [detachFlag, setDetachFlag] = useState(false);
  const [autoAttchFlag, setAutoAttchFlag] = useState(false);
  const [IsSupraWeb, setIsSupraWeb] = useState(false);
  const [openComments, setOpenComments] = useState(false);
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [editableField, setEditField] = useState("");
  const { id } = useParams();
  const dispatch = useDispatch();
  const logsHistory = useSelector(
    (state) => state.agentRoster.agentInventory.specificLogsHistory
  );
  const agentRosterList = useSelector((item) => item.users.storedListOfUsers);
  const propertyList = useSelector((item) => item.propertyList);
  const checkout = useSelector(
    (state) => state.inventory.InventoryData.checkout
  );
  const lostMark = useSelector(
    (state) => state.inventory.InventoryData.lostMark
  );

  const checkOut = useSelector(
    (state) => state.agentRoster.agentInventory.checkOut
  );

  useEffect(() => {
    if (open && lockBoxDetail) {
      setIsSupraWeb(lockBoxDetail.IsSupraWeb);
    }
  }, [open]);
  useEffect(() => {
    if (checkOut.success && !checkOut.isLoading && open) {
      setErrorAlert({
        errorMsg: assigningUser?.id
          ? "You have successfully assigned the user"
          : "You have successfully attached the listing ",
        errorType: "success",
        isOpen: true,
      });
      if (setLockBoxDetail && assigningUser?.id) {
        setLockBoxDetail({
          ...lockBoxDetail,
          users_v2: agentRosterList?.list?.find(
            (item) => item.id === assigningUser?.id
          ),
          id: lockBoxDetail?.id || checkOut.data?.id,
        });
        dispatch(
          updateAssignUser({
            ...lockBoxDetail,
            users_v2: agentRosterList?.list?.find(
              (item) => item.id === assigningUser?.id
            ),
            checkoutId: checkOut.data?.id,
          })
        );
        if (autoAttchFlag) {
          let obj = {
            ListingId: attachingList?.id,
            transaction_listings_v2: propertyList?.list?.data?.find(
              (item) => item.id === attachingList?.id
            ),
            InventoryId: lockBoxDetail.InventoryId,
            id: checkOut.data?.id,
          };
          dispatch(updateCheckoutInventory(obj));
        }
      }
      if (setLockBoxDetail && attachingList?.id && !autoAttchFlag) {
        setLockBoxDetail({
          ...lockBoxDetail,
          transaction_listings_v2: propertyList?.list?.data?.find(
            (item) => item.id === attachingList?.id
          ),
        });
        if (!isListing) {
          dispatch(
            attachInventoryListing({
              ...lockBoxDetail,
              transaction_listings_v2: propertyList?.list?.data?.find(
                (item) => item.id === attachingList?.id
              ),
              checkoutId: checkOut.data?.id,
            })
          );
        }
      }
      if (autoAttchFlag) {
        setAutoAttchFlag(false);
      } else {
        setOpenAssign(false);
        setAttachingList("");
      }
      setAssigningUser("");
    } else if (
      !checkOut.isLoading &&
      checkOut.errMsg &&
      open &&
      !lockBoxDetail?.id
    ) {
      setErrorAlert({
        errorMsg: JSON.stringify(checkOut.errMsg),
        errorType: "error",
        isOpen: true,
      });
    }
  }, [checkOut.isLoading]);

  useEffect(() => {
    if (checkout.success && !checkout.isLoading && open && lockBoxDetail?.id) {
      dispatch(updateCheckoutState());
      if (errorAlert.isOpen) {
        setTimeout(() => {
          setErrorAlert({
            errorMsg: detachFlag
              ? "You have successfully detach the listing from this inventory"
              : assigningUser?.id
              ? "You have successfully assigned the user"
              : "You have successfully attached the listing ",
            errorType: "success",
            isOpen: true,
          });
        }, 1000);
      } else {
        setErrorAlert({
          errorMsg: detachFlag
            ? "You have successfully detach the listing from this inventory"
            : assigningUser?.id
            ? "You have successfully assigned the user"
            : "You have successfully attached the listing f",
          errorType: "success",
          isOpen: true,
        });
      }
      if (setLockBoxDetail && attachingList?.id) {
        setLockBoxDetail({
          ...lockBoxDetail,
          transaction_listings_v2: propertyList?.list?.data?.find(
            (item) => item.id === attachingList?.id
          ),
        });
        if (!isListing) {
          dispatch(
            attachInventoryListing({
              ...lockBoxDetail,
              transaction_listings_v2: propertyList?.list?.data?.find(
                (item) => item.id === attachingList?.id
              ),
              checkoutId: checkout.data?.InventoryId || checkout.data?.id,
            })
          );
        }
        if (isListing && lockBoxDetail.ListingId !== attachingList?.id) {
          if (setLockBoxData) {
            setLockBoxData(null);
          }
        }
      }
      if (detachFlag) {
        setDetachFlag(false);
        setLockBoxDetail({
          ...lockBoxDetail,
          transaction_listings_v2: null,
        });
        if (!isListing) {
          dispatch(
            attachInventoryListing({
              ...lockBoxDetail,
              transaction_listings_v2: null,
              checkoutId: checkout.data?.InventoryId || checkout.data?.id,
            })
          );
        } else {
          if (setLockBoxData) {
            setLockBoxData(null);
          }
        }
      }
      if (IsAgent && id) {
        dispatch(getAgentInventories({ id }));
        dispatch(getInventoryLogHistory({ id: id }));
      }
      setOpenAssign(false);
      setAssigningUser("");
      setAttachingList("");
    } else if (
      !checkout.isLoading &&
      checkout.errMsg &&
      open &&
      lockBoxDetail?.id
    ) {
      setErrorAlert({
        errorMsg: JSON.stringify(checkout.errMsg),
        errorType: "error",
        isOpen: true,
      });
      if (detachFlag) {
        setDetachFlag(false);
      }
    }
  }, [checkout.isLoading]);

  useEffect(() => {
    if (lockBoxDetail.InventoryId && open) {
      dispatch(
        getInventorySpecificLogHistory({
          id: lockBoxDetail.InventoryId,
          agent_id: lockBoxDetail?.users_v2?.id,
        })
      );
    }
  }, [lockBoxDetail.InventoryId, open]);

  useEffect(() => {
    if (!lockBoxDetail.transaction_listings_v2 || isListing) {
      dispatch(getAllPropertyList({}));
    }
  }, []);
  const handleCheckIN = async () => {
    setLoading(true);
    const result = await updateData(
      `${process.env.REACT_APP_BASE_URL}api/inventory/check-in/${lockBoxDetail.id}`,
      {}
    );
    if (result.status === 200) {
      setErrorAlert({
        errorMsg: "You have successfully checked in",
        errorType: "success",
        isOpen: true,
      });
      if (setLockBoxData && isListing) {
        setLockBoxData(null);
      }
      setLoading(false);
      if (IsAgent && id) {
        dispatch(getAgentInventories({ id }));
        dispatch(getInventoryLogHistory({ id: id }));
      } else {
        dispatch(
          updateListAfterCheckIn({ InventoryId: lockBoxDetail.InventoryId })
        );
      }
      setOpen(false);
    } else {
      setLoading(false);
      setErrorAlert({
        errorMsg: JSON.stringify(result.message),
        errorType: "error",
        isOpen: true,
      });
    }
  };
  const handleFilterOptionForManage = (data) => {
    if (
      !lockBoxDetail?.users_v2 &&
      !lockBoxDetail?.transaction_listings_v2 &&
      lockBoxDetail?.AssetStatus !== "lost" &&
      lockBoxDetail?.AssetStatus !== "available"
    ) {
      return data.filter(
        (item) =>
          item.value !== "detach" &&
          item.value !== "swap" &&
          item.value !== "mark_as_lost" &&
          item.value !== "check_in"
      );
    } else if (
      !lockBoxDetail?.users_v2 &&
      !lockBoxDetail?.transaction_listings_v2 &&
      lockBoxDetail?.AssetStatus === "available"
    ) {
      return data.filter(
        (item) =>
          item.value !== "detach" &&
          item.value !== "swap" &&
          item.value !== "check_in"
      );
    } else if (
      !lockBoxDetail?.users_v2 &&
      !lockBoxDetail?.transaction_listings_v2 &&
      lockBoxDetail?.AssetStatus === "lost"
    ) {
      return data.filter(
        (item) =>
          item.value !== "detach" &&
          item.value !== "swap" &&
          item.value !== "mark_as_lost"
      );
    } else if (
      !lockBoxDetail?.users_v2 &&
      lockBoxDetail?.transaction_listings_v2
    ) {
      return data.filter(
        (item) =>
          item.value !== "check_in" &&
          item.value !== "detach" &&
          item.value !== "swap" &&
          item.value !== "mark_as_lost"
      );
    } else if (
      lockBoxDetail?.users_v2 &&
      !lockBoxDetail?.transaction_listings_v2 &&
      lockBoxDetail?.AssetStatus !== "lost"
    ) {
      return data.filter(
        (item) => item.value !== "detach" && item.value !== "swap"
      );
    } else if (
      lockBoxDetail?.users_v2 &&
      !lockBoxDetail?.transaction_listings_v2 &&
      lockBoxDetail?.AssetStatus === "lost"
    ) {
      return data.filter(
        (item) =>
          item.value !== "detach" &&
          item.value !== "swap" &&
          item.value !== "mark_as_lost"
      );
    } else {
      return data;
    }
  };
  const handleAssigning = () => {
    if (openAssign === "user") {
      if (assigningUser?.id) {
        if (lockBoxDetail?.id) {
          let obj = {
            AgentId: assigningUser?.id,
            id: lockBoxDetail?.id ? lockBoxDetail?.id : null,
          };
          dispatch(updateCheckoutInventory(obj));
        } else {
          let obj = {
            AgentId: assigningUser?.id,
            InventoryId: lockBoxDetail.InventoryId,
          };
          dispatch(checkoutAgentInventory(obj));
        }
      } else {
        setErrorAlert({
          errorMsg: "You need to select the user first",
          errorType: "warning",
          isOpen: true,
        });
      }
    } else {
      if (attachingList?.id) {
        if (lockBoxDetail?.id) {
          let obj = {
            ListingId: attachingList?.id,
            transaction_listings_v2: propertyList?.list?.data?.find(
              (item) => item.id === attachingList?.id
            ),
            InventoryId: lockBoxDetail.InventoryId,
            id: lockBoxDetail?.id ? lockBoxDetail?.id : null,
          };
          if (isListing && attachingList?.id !== lockBoxDetail?.ListingId) {
            obj.detach = true;
          }
          dispatch(updateCheckoutInventory(obj));
        } else {
          setAutoAttchFlag(true);
          let agent = propertyList?.list?.data?.find(
            (item) => item.id === attachingList?.id
          );
          setAssigningUser({ id: agent?.agent_id, ...agent });
          let obj = {
            AgentId: agent?.agent_id,
            InventoryId: lockBoxDetail.InventoryId,
          };
          dispatch(checkoutAgentInventory(obj));
        }
      } else {
        setErrorAlert({
          errorMsg: "You need to select the listing first for assigning",
          errorType: "warning",
          isOpen: true,
        });
      }
    }
  };
  const handleDetachListing = () => {
    let obj = {
      ListingId: null,
      transaction_listings_v2: null,
      InventoryId: lockBoxDetail?.InventoryId || lockBoxDetail?.inventory?.id,
      id: lockBoxDetail?.id ? lockBoxDetail?.id : null,
      detach: true,
    };
    setDetachFlag(true);
    dispatch(updateCheckoutInventory(obj));
  };
  const handleUpdateSuperWeb = (flag) => {
    setIsSupraWeb(flag);
    if (updateSupraWeb) {
      updateSupraWeb(flag);
    } else {
      if (IsAgent) {
        dispatch(
          handleUpdateSuperWebStatus({ id: lockBoxDetail.id, IsSupraWeb: flag })
        );
      } else {
        dispatch(
          updateSuperWebsiteStatus({
            id: lockBoxDetail.InventoryId,
            defaultData: [
              {
                ...lockBoxDetail.defaultData["check-out"][0],
                IsSupraWeb: flag,
              },
            ],
          })
        );
      }
    }
    updateData(
      `${process.env.REACT_APP_BASE_URL}api/inventory/supra-web-status/${lockBoxDetail.id}`,
      { IsSupraWeb: flag }
    );
  };
  const handleMarkSuccess = () => {
    setOpen(false);
    if (!IsAgent) {
      dispatch(
        updateMarkAsLost({
          InventoryId:
            lockBoxDetail?.InventoryId || lockBoxDetail?.inventory?.id,
        })
      );
    } else {
      if (IsAgent && id) {
        dispatch(getAgentInventories({ id }));
        dispatch(getInventoryLogHistory({ id: id }));
      }
    }
    setErrorAlert({
      errorMsg: "You have successfully marked as lost",
      errorType: "success",
      isOpen: true,
    });
  };
  const handleMarkError = (error) => {
    setErrorAlert({
      errorMsg: JSON.stringify(error),
      errorType: "warning",
      isOpen: true,
    });
  };
  const handleMarkAsLost = () => {
    let obj = {
      inventory_occupied_id:
        lockBoxDetail?.id ||
        lockBoxDetail?.InventoryId ||
        lockBoxDetail?.defaultData?.id,
      type: lockBoxDetail?.id ? "inventory_occupied" : "inventory",
      handleMarkSuccess,
      handleMarkError,
    };
    dispatch(markAsLost(obj));
  };

  const copyContent = (id) => {
    let value = document.getElementById(id)?.value;
    if (value) {
      navigator.clipboard.writeText(value);
      setErrorAlert({
        errorMsg: "Copied successfully!",
        errorType: "success",
        isOpen: true,
      });
    }
  };

  const handleInventoryData = (id, field, preValue) => {
    let value = id ? document.getElementById(id)?.value : preValue;
    if (value) {
      let obj = {
        id: lockBoxDetail?.inventory?.id,
        schema: {
          [field]: value,
          AssetType: "lockbox",
        },
      };
      dispatch(updateInventoryItem(obj));
    }
    setEditField("");
  };

  return (
    <React.Fragment>
      <Dialog
        disablePortal
        sx={{
          "& .MuiDialog-container": { mt: "30px" },
        }}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <Box
          sx={{ width: openComments ? "950px" : "650px", minHeight: "40vh" }}
        >
          <Grid container spacing={2}>
            <Grid sm={12} md={openComments ? 8 : 12}>
              {manage === "view_history" ? null : (
                <DialogTitle
                  sx={{ mt: "25px", ml: "16px" }}
                  className={classes.modalHeading}
                  id="alert-dialog-title"
                >
                  <Box>Lockbox Detail</Box>
                  <Stack direction="row" spacing={2} alignItems={"center"}>
                    {loading || detachFlag || lostMark.isLoading ? (
                      <CircularProgress size={25} />
                    ) : (
                      ""
                    )}
                    <ButtonGroups
                      id="Manage-button-menu"
                      bgColor="#0B0909"
                      bgColorHover="#0B0909"
                      placement={"bottom"}
                      height="36px"
                      size="medium"
                      color="white"
                      fontSize="13px"
                      borderRadius="8px"
                      minWidth="120px"
                      paddingLeft="13px"
                      lineHeight="20px"
                      options={handleFilterOptionForManage([
                        {
                          title: "Check in",
                          value: "check_in",
                          icon: (
                            <img
                              style={{ width: "18px", marginLeft: "4px" }}
                              src={checkIcon}
                              alt={"checkIcon"}
                            />
                          ),
                        },
                        {
                          title: "Detach Listing",
                          value: "detach",
                          icon: <RemoveCircleOutlineIcon />,
                        },
                        {
                          title: "Swap Listing",
                          value: "swap",
                          icon: <SwapHorizontalCircleIcon />,
                        },
                        {
                          title: "View History",
                          value: "view_history",
                          icon: <HistoryIcon />,
                        },
                        {
                          title: "Mark as Lost",
                          value: "mark_as_lost",
                          icon: <HelpRoundedIcon />,
                        },
                      ])}
                      onChangeMenu={(value) => {
                        if (value === "check_in") {
                          handleCheckIN();
                        } else if (value === "swap") {
                          setOpenAssign("listing");
                        } else if (value === "detach") {
                          handleDetachListing();
                        } else if (value === "mark_as_lost") {
                          handleMarkAsLost();
                        } else {
                          setManage(value);
                        }
                      }}
                      value={manage}
                      placeholder={"Manage"}
                      {...props}
                    />
                    <IconButton onClick={() => setOpenComments(!openComments)}>
                      <ChatBubbleOutlineRoundedIcon fontSize="small" />
                    </IconButton>
                  </Stack>
                </DialogTitle>
              )}
              <DialogContent sx={{ ml: "16px", paddingBottom: "0px" }}>
                {manage === "" ? (
                  <Grid
                    container
                    direction="row"
                    spacing={3}
                    sx={{ mt: "0px" }}
                  >
                    <Grid item xs={12} sx={{ zIndex: "1" }}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6} sx={{ zIndex: "1" }}>
                          <InputLabel className={classes.boxLabelStyle}>
                            Serial Number
                          </InputLabel>
                          <OutlinedInput
                            fullWidth
                            size="small"
                            defaultValue={lockBoxDetail.inventory?.SerialNumber}
                            readOnly={
                              editableField === "SerialNumber" ? false : true
                            }
                            id="SerialNumber-update"
                            endAdornment={
                              <InputAdornment position="end">
                                {editableField === "SerialNumber" ? (
                                  <IconButton
                                    onClick={() =>
                                      handleInventoryData(
                                        "SerialNumber-update",
                                        "SerialNumber"
                                      )
                                    }
                                    size="small"
                                    edge="end"
                                  >
                                    <CheckCircleRoundedIcon
                                      color="success"
                                      fontSize="small"
                                    />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    onClick={() => setEditField("SerialNumber")}
                                    size="small"
                                    edge="end"
                                  >
                                    <EditRoundedIcon fontSize="small" />
                                  </IconButton>
                                )}
                                <IconButton
                                  onClick={() =>
                                    copyContent("SerialNumber-update")
                                  }
                                  size="small"
                                  edge="end"
                                >
                                  <ContentCopyIcon fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            }
                            placeholder="Enter serial number"
                          />
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ zIndex: "1" }}>
                          <InputLabel className={classes.boxLabelStyle}>
                            CBS Code
                          </InputLabel>
                          <OutlinedInput
                            fullWidth
                            size="small"
                            defaultValue={lockBoxDetail.inventory?.CBScode}
                            readOnly={
                              editableField === "CBScode" ? false : true
                            }
                            focused={editableField === "CBScode" ? true : false}
                            id="CBScode-update"
                            endAdornment={
                              <InputAdornment position="end">
                                {editableField === "CBScode" ? (
                                  <IconButton
                                    onClick={() =>
                                      handleInventoryData(
                                        "CBScode-update",
                                        "CBScode"
                                      )
                                    }
                                    size="small"
                                    edge="end"
                                  >
                                    <CheckCircleRoundedIcon
                                      color="success"
                                      fontSize="small"
                                    />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    onClick={() => setEditField("CBScode")}
                                    size="small"
                                    edge="end"
                                  >
                                    <EditRoundedIcon fontSize="small" />
                                  </IconButton>
                                )}
                                <IconButton
                                  onClick={() => copyContent("CBScode-update")}
                                  size="small"
                                  edge="end"
                                >
                                  <ContentCopyIcon fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            }
                            placeholder="Enter CBS code"
                          />
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ zIndex: "1" }}>
                          <InputLabel className={classes.boxLabelStyle}>
                            Tag ID
                          </InputLabel>
                          <OutlinedInput
                            fullWidth
                            size="small"
                            defaultValue={lockBoxDetail.inventory?.tagId}
                            id="tagId-update"
                            readOnly={editableField === "tagId" ? false : true}
                            endAdornment={
                              <InputAdornment position="end">
                                {editableField === "tagId" ? (
                                  <IconButton
                                    onClick={() =>
                                      handleInventoryData(
                                        "tagId-update",
                                        "tagId"
                                      )
                                    }
                                    size="small"
                                    edge="end"
                                  >
                                    <CheckCircleRoundedIcon
                                      color="success"
                                      fontSize="small"
                                    />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    onClick={() => setEditField("tagId")}
                                    size="small"
                                    edge="end"
                                  >
                                    <EditRoundedIcon fontSize="small" />
                                  </IconButton>
                                )}
                                <IconButton
                                  onClick={() => copyContent("tagId-update")}
                                  size="small"
                                  edge="end"
                                >
                                  <ContentCopyIcon fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            }
                            placeholder="Enter Tag ID"
                          />
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ zIndex: "1" }}>
                          <InputLabel className={classes.boxLabelStyle}>
                            Shackle Code
                          </InputLabel>
                          <OutlinedInput
                            fullWidth
                            size="small"
                            defaultValue={lockBoxDetail.inventory?.shackleCode}
                            readOnly={
                              editableField === "shackleCode" ? false : true
                            }
                            id="shackleCode-update"
                            endAdornment={
                              <InputAdornment position="end">
                                {editableField === "shackleCode" ? (
                                  <IconButton
                                    onClick={() =>
                                      handleInventoryData(
                                        "shackleCode-update",
                                        "shackleCode"
                                      )
                                    }
                                    size="small"
                                    edge="end"
                                  >
                                    <CheckCircleRoundedIcon
                                      color="success"
                                      fontSize="small"
                                    />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    onClick={() => setEditField("shackleCode")}
                                    size="small"
                                    edge="end"
                                  >
                                    <EditRoundedIcon fontSize="small" />
                                  </IconButton>
                                )}
                                <IconButton
                                  onClick={() =>
                                    copyContent("shackleCode-update")
                                  }
                                  size="small"
                                  edge="end"
                                >
                                  <ContentCopyIcon fontSize="small" />
                                </IconButton>
                              </InputAdornment>
                            }
                            placeholder="Enter Shackle Code"
                          />
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ zIndex: "1" }}>
                          <InputLabel className={classes.boxLabelStyle}>
                            Office
                          </InputLabel>
                          <Select
                            defaultValue={lockBoxDetail.inventory?.office || ""}
                            size="small"
                            fullWidth
                            onChange={(e) => {
                              handleInventoryData(
                                null,
                                "office",
                                e.target.value
                              );
                            }}
                          >
                            <MenuItem value={""} selected disabled>
                              Select Office
                            </MenuItem>
                            <MenuItem value={"roswell"}>Roswell</MenuItem>
                            <MenuItem value={"in_town"}>In-Town</MenuItem>
                            <MenuItem value={"trp"}> TRP</MenuItem>
                          </Select>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sx={{ zIndex: "1" }}>
                      <InputLabel className={classes.boxLabelStyle}>
                        Listing attached
                      </InputLabel>
                      {lockBoxDetail?.transaction_listings_v2 ? (
                        <Card
                          variant="outlined"
                          evaluation={2}
                          sx={{ padding: "5px 15px" }}
                        >
                          <CardHeader
                            className={classes.EquipmentDetailSubBox}
                            classes={{
                              title: classes.lockboxInventoryModalCardHeader,
                              subheader: classes.agentInventoryModalCardDes,
                            }}
                            avatar={
                              <img
                                style={{
                                  width: "180px",
                                  height: "120px",
                                  objectFit: "cover",
                                  borderRadius: "6px",
                                }}
                                src={
                                  lockBoxDetail?.transaction_listings_v2
                                    ?.property_img || agentHouseIcon
                                }
                                alt="agent Method Icon White"
                              />
                            }
                            title={
                              lockBoxDetail?.transaction_listings_v2?.street ||
                              propertyList?.list?.data?.find(
                                (item) =>
                                  item.id ===
                                  lockBoxDetail?.transaction_listings_v2?.id
                              )?.street ||
                              lockBoxDetail?.transaction_listings_v2
                                ?.property_address
                            }
                            subheader={
                              <Box>
                                <Box>
                                  {lockBoxDetail?.transaction_listings_v2?.city}
                                  ,{" "}
                                  {
                                    lockBoxDetail?.transaction_listings_v2
                                      ?.state
                                  }{" "}
                                  {
                                    lockBoxDetail?.transaction_listings_v2
                                      ?.zipcode
                                  }
                                </Box>

                                <Stack
                                  sx={{ mt: "40px" }}
                                  direction="row"
                                  spacing={2}
                                  justifyContent={"space-between"}
                                  alignItems="center"
                                >
                                  <Chip
                                    sx={{
                                      background:
                                        ListingColors[
                                          lockBoxDetail?.transaction_listings_v2
                                            ?.status ||
                                            propertyList?.list?.data?.find(
                                              (item) =>
                                                item.id ===
                                                lockBoxDetail
                                                  ?.transaction_listings_v2?.id
                                            )?.status
                                        ],
                                      color: "white",
                                    }}
                                    size="small"
                                    label={
                                      lockBoxDetail?.transaction_listings_v2
                                        ?.status ||
                                      propertyList?.list?.data?.find(
                                        (item) =>
                                          item.id ===
                                          lockBoxDetail?.transaction_listings_v2
                                            ?.id
                                      )?.status
                                    }
                                  />
                                  <FormControlLabel
                                    sx={{
                                      mr: "-15px !important",
                                      color: "black",
                                      "& .MuiFormControlLabel-label": {
                                        fontSize: "13px !important",
                                        fontWeight: "400",
                                      },
                                    }}
                                    labelPlacement="start"
                                    control={
                                      <Checkbox
                                        checked={IsSupraWeb}
                                        onChange={(e) =>
                                          handleUpdateSuperWeb(e.target.checked)
                                        }
                                      />
                                    }
                                    label="SupraWeb"
                                  />
                                </Stack>
                              </Box>
                            }
                          />
                        </Card>
                      ) : (
                        <Card
                          variant="outlined"
                          evaluation={2}
                          sx={{ padding: "5px 15px" }}
                        >
                          <CardHeader
                            title="Listing not attached"
                            classes={{ title: classes.notFoundTitle }}
                            sx={{ paddingLeft: "0px" }}
                            action={
                              <IconButton
                                onClick={() => setOpenAssign("listing")}
                                aria-label="settings"
                              >
                                <AddCircleIcon sx={{ color: "black" }} />
                              </IconButton>
                            }
                          />
                        </Card>
                      )}
                    </Grid>
                    <Grid item xs={12} sx={{ zIndex: "1" }}>
                      <InputLabel className={classes.boxLabelStyle}>
                        Assigned to
                      </InputLabel>
                      {lockBoxDetail?.users_v2 ? (
                        <Card
                          variant="outlined"
                          evaluation={2}
                          sx={{ padding: "14px" }}
                        >
                          <CardHeader
                            classes={{
                              title: classes.agentInventoryModalCardHeader,
                              subheader: classes.agentInventoryModalCardDes,
                            }}
                            sx={{ padding: "0px" }}
                            avatar={
                              <Avatar
                                variant="rounded"
                                src={
                                  lockBoxDetail?.users_v2?.profile_images
                                    ?.profile_img ||
                                  lockBoxDetail?.users_v2?.profile_images
                                    ?.profile_img_thumbnail ||
                                  lockBoxDetail?.users_v2?.profile_images
                                    ?.slack_profile_img_thumbnail
                                }
                                sx={{ width: "73px", height: "73px" }}
                                alt="agent Avatar"
                              >{`${lockBoxDetail?.users_v2?.full_name?.slice(
                                0,
                                1
                              )}${lockBoxDetail?.users_v2?.full_name
                                ?.split(" ")[1]
                                ?.slice(0, 1)}`}</Avatar>
                            }
                            title={
                              lockBoxDetail?.users_v2?.full_name?.split(" ")[0]
                            }
                            subheader={
                              lockBoxDetail?.users_v2?.full_name?.split(" ")[1]
                            }
                            action={
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "10px",
                                  marginRight: "8px",
                                  marginTop: "5.5px",
                                }}
                              >
                                <Button
                                  variant="outlined"
                                  color="inherit"
                                  size="small"
                                  startIcon={
                                    <Typography
                                      sx={{
                                        fontSize: "13px !important",
                                        lineHeight: "13px",
                                      }}
                                    >
                                      {logsHistory?.activeListingCount
                                        ?.activeListings || "0"}{" "}
                                    </Typography>
                                  }
                                  sx={{
                                    height: "30px",
                                    fontSize: "11.5px",
                                    width: "140px",
                                    lineHeight: "13px",
                                  }}
                                >
                                  {" "}
                                  Active Listings
                                </Button>
                                <Button
                                  variant="contained"
                                  color="inherit"
                                  size="small"
                                  sx={{
                                    height: "30px",
                                    width: "140px",
                                    background: "black",
                                    color: "white",
                                    "&:hover": {
                                      background: "black",
                                      color: "white",
                                    },
                                    fontSize: "11.5px",
                                    lineHeight: "13px",
                                  }}
                                  startIcon={
                                    <Typography
                                      sx={{
                                        ml: "-10px",
                                        fontSize: "13px !important",
                                        lineHeight: "13px",
                                      }}
                                    >
                                      {logsHistory?.activeListingCount
                                        ?.supraTotal || "0"}
                                    </Typography>
                                  }
                                >
                                  {" "}
                                  Total Supras
                                </Button>
                              </Box>
                            }
                          />
                        </Card>
                      ) : (
                        <Card
                          variant="outlined"
                          evaluation={2}
                          sx={{ padding: "5px 15px" }}
                        >
                          <CardHeader
                            title="No Agent Assigned"
                            classes={{ title: classes.notFoundTitle }}
                            sx={{ paddingLeft: "0px" }}
                            action={
                              <IconButton
                                onClick={() => setOpenAssign("user")}
                                aria-label="settings"
                              >
                                <AddCircleIcon sx={{ color: "black" }} />
                              </IconButton>
                            }
                          />
                        </Card>
                      )}
                    </Grid>
                  </Grid>
                ) : (
                  <Box>
                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={12} md={7} sx={{ zIndex: "1" }}>
                        <Card
                          sx={{
                            minWidth: "250px",
                            padding: "5px 15px",
                            boxShadow: "none",
                            mt: "25px",
                          }}
                        >
                          <CardHeader
                            className={classes.EquipmentDetailSubBox}
                            classes={{
                              title: classes.historyLogsHeaderTitle,
                            }}
                            avatar={
                              <img
                                src={agentLockIcon}
                                alt="agent Method Icon White"
                              />
                            }
                            title={
                              lockBoxDetail.inventory?.SerialNumber ||
                              lockBoxDetail.inventory?.AssetName
                            }
                          />
                        </Card>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <Stack direction={"row"} justifyContent={"flex-end"}>
                          <Stack
                            onClick={() => setManage("")}
                            direction={"row"}
                            spacing={1}
                            sx={{ mt: "15px", cursor: "pointer" }}
                            alignItems={"center"}
                          >
                            <ArrowBackIcon
                              fontSize="small"
                              sx={{ color: "rgba(0, 0, 0, 0.54)" }}
                            />
                            <Box
                              sx={{
                                fontSize: "14px !important",
                                color: "rgba(0, 0, 0, 0.87",
                                fontFamily: "AvenirNext !important",
                              }}
                            >
                              Back to Lockbox Detail
                            </Box>
                          </Stack>
                        </Stack>
                      </Grid>
                    </Grid>
                    <Box
                      sx={{ mt: "24px", mb: "-9.5px", ml: "20px" }}
                      className={classes.historyLogsTime}
                    >
                      <Box
                        sx={{
                          display: "inline",
                          background: "white",
                          width: "30px",
                          color: "rgba(0, 0, 0, 0.54)",
                          padding: "0px 4px",
                        }}
                      >
                        History
                      </Box>{" "}
                    </Box>
                    <Card
                      variant="outlined"
                      sx={{
                        padding: "12px",
                        height:
                          !lockBoxDetail?.users_v2 &&
                          !lockBoxDetail?.transaction_listings_v2
                            ? "365px"
                            : lockBoxDetail?.users_v2 &&
                              !lockBoxDetail?.transaction_listings_v2
                            ? "370px"
                            : "451px",
                        overflow: "auto",
                      }}
                    >
                      {logsHistory.data.map((item) => (
                        <Grid container sx={{ mt: "10px" }}>
                          <Grid
                            item
                            sm={12}
                            md={4}
                            className={classes.historyLogsTime}
                          >
                            {moment(item.createdAt).format("L")} @{" "}
                            {moment(item.createdAt).format("LT")}{" "}
                          </Grid>
                          <Grid
                            sx={{ paddingLeft: "0px !important", ml: "-10px" }}
                            item
                            sm={12}
                            md={8}
                            className={classes.historyLogsDetail}
                          >
                            {item.Description}
                          </Grid>
                        </Grid>
                      ))}
                    </Card>
                  </Box>
                )}
              </DialogContent>
              <DialogActions sx={{ mb: "5px", mt: "10px", mr: "15px" }}>
                <Button
                  onClick={() => {
                    setManage("");
                    setOpen(false);
                  }}
                  color="inherit"
                  autoFocus
                  size="small"
                  variant="outlined"
                  sx={{ fontSize: "13px" }}
                >
                  Close
                </Button>
              </DialogActions>
            </Grid>
            {openComments ? (
              <Grid sm={12} md={4}>
                <CommentSection
                  height={
                    !lockBoxDetail?.users_v2 &&
                    !lockBoxDetail?.transaction_listings_v2
                      ? "687px"
                      : lockBoxDetail?.users_v2 &&
                        !lockBoxDetail?.transaction_listings_v2
                      ? "710px"
                      : "792px"
                  }
                  {...props}
                />
              </Grid>
            ) : null}
          </Grid>
        </Box>
      </Dialog>
      <Dialog
        disablePortal
        open={Boolean(openAssign)}
        onClose={() => setOpenAssign(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogContent sx={{ minWidth: "500px" }}>
          {openAssign === "user" ? (
            <Autocomplete
              disableClearable
              options={
                agentRosterList?.list?.filter((it) => it.is_agent).length > 0
                  ? agentRosterList?.list
                      ?.filter((it) => it.is_agent)
                      .sort((a, b) => a.last_name.localeCompare(b.last_name))
                  : []
              }
              getOptionLabel={(option) =>
                option.first_name + " " + option.last_name
              }
              onChange={(e, option) => {
                setAssigningUser(option);
              }}
              sx={{ mt: "12px" }}
              value={assigningUser?.id ? assigningUser : null}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Agent"
                  placeholder="Search user"
                  aria-describedby="stepper-linear-event_types"
                  size="small"
                  variant="standard"
                  fullWidth
                  required
                />
              )}
            ></Autocomplete>
          ) : (
            <Autocomplete
              disableClearable
              options={propertyList?.list?.data || []}
              getOptionLabel={(option) => option.property_address}
              onChange={(e, option) => {
                setAttachingList(option);
              }}
              sx={{ mt: "12px" }}
              value={attachingList || lockBoxDetail?.transaction_listings_v2}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Listing"
                  placeholder="Search listings"
                  aria-describedby="stepper-linear-event_types"
                  size="small"
                  variant="standard"
                  fullWidth
                  required
                />
              )}
            ></Autocomplete>
          )}
        </DialogContent>
        <DialogActions sx={{ mb: "10px", mt: "25px", mr: "15px" }}>
          <Button
            onClick={() => {
              setOpenAssign(false);
            }}
            color="inherit"
            autoFocus
            size="small"
            variant="outlined"
            sx={{ fontSize: "13px" }}
          >
            Close
          </Button>
          <LoadingButton
            onClick={handleAssigning}
            classes={{ root: classes.deleteButTon }}
            color="inherit"
            autoFocus
            size="small"
            loadingPosition="start"
            loading={checkout.isLoading || checkOut.isLoading}
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, {
  name: "InventoryItemDetailModalStyle",
})(InventoryItemDetailModal);
