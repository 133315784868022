import io from "socket.io-client";

let socket; // Store the singleton instance

const establishSocketConnection = (id) => {
  if (!socket) {
    socket = io.connect(
      `${process.env.REACT_APP_NOTIFICATION_SOCKET_BASE_URL}?userId=${id}`,
      {
        transports: ["websocket"],
        "force new connection": false, // Prevent unnecessary reconnections
        cookie: false,
        autoConnect: false,
      }
    );
    socket.connect(); // Connect on initialization
  }
  return socket;
};

export const getSocket = () => {
  if (!socket) {
    console.error(
      "Socket has not been initialized. Call establishSocketConnection first."
    );
  }
  return socket;
};

export default establishSocketConnection;
