import React from "react";
import { Box } from "@mui/material";
import { styleSheet } from "./style.js";
import { withStyles } from "@mui/styles";
import houseIcon from "../../../assets/images/houseIcon.jpg";
import { useNavigate } from "react-router-dom";
function KnowledgeBase(props) {
  let { classes, data, key, setOpenSearch } = props;
  const navigate = useNavigate();
  return (
    <Box
      onClick={() => {
        setOpenSearch(false);
        navigate(`/knowledgebase/main/${data.id}`, {
          state: data,
        });
      }}
      key={key}
      className={classes.listingArea}
    >
      <Box className={classes.knowledgeBaseAreaLeft}>
        <Box className={classes.listingAreaLeftImg}>
          <img src={data.thumbnail || houseIcon} alt="houseIcon" />
        </Box>
        <Box className={classes.listingAreaLeftContent}>
          <Box className={classes.KnowledgeBaseAreaLeftContentHead}>
            {data.title}
          </Box>
          <Box className={classes.KnowledgeBaseAreaLeftContentAdd}>
            {data?.post_categories?.title} | by Creation Username
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export default withStyles(styleSheet, { name: "KnowledgeBaseStyle" })(
  KnowledgeBase
);
