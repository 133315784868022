import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import {
  InputLabel,
  Grid,
  Card,
  CardHeader,
  Chip,
  Box,
  Typography,
  TextField,
  Avatar,
  Stack,
  CardContent,
} from "@mui/material";
import agentEquipmentIcon from "../../../../assets/images/agents/agentEquipmentIcon.png";
import agentAvatar from "../../../../assets/images/agents/agentAvatar.png";
import ButtonGroups from "../../../../components/uiElements/buttonGroup";
import agentHouseIcon from "../../../../assets/images/agents/agentHouseIcon.jpg";
import HistoryIcon from "@mui/icons-material/History";
import CircularProgress from "@mui/material/CircularProgress";
import checkIcon from "../../../../assets/checkIcon.png";
import { useDispatch, useSelector } from "react-redux";
import { updateData } from "../../../../utils/updateData";
import { getInventoryLogHistory } from "../../../../redux/agents/agentRosterDetail/inventory/getLogsHistory";
import { getInventorySpecificLogHistory } from "../../../../redux/agents/agentRosterDetail/inventory/getSpecificLogs";
import { getAgentInventories } from "../../../../redux/agents/agentRosterDetail/inventory/getAgentInventory";
import moment from "moment";
import ResponseAlert from "../../../../components/responseAlert";
import { useParams } from "react-router-dom";
function InventoryItemDetailEquipmentModal(props) {
  const [manage, setManage] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const { id } = useParams();
  let { open, setOpen, classes, equipmentBox } = props;
  const dispatch = useDispatch();
  const logsHistory = useSelector(
    (state) => state.agentRoster.agentInventory.specificLogsHistory
  );

  useEffect(() => {
    if (equipmentBox.InventoryId) {
      dispatch(
        getInventorySpecificLogHistory({ id: equipmentBox.InventoryId })
      );
    }
  }, [equipmentBox.InventoryId]);
  const handleCheckIN = async () => {
    setLoading(true);
    const result = await updateData(
      `${process.env.REACT_APP_BASE_URL}api/inventory/check-in/${equipmentBox.id}`,
      {}
    );
    if (result.status === 200) {
      setErrorAlert({
        errorMsg: "You have successfully checked in",
        errorType: "success",
        isOpen: true,
      });
      setLoading(false);
      dispatch(getAgentInventories({ id }));
      dispatch(getInventoryLogHistory({ id: id }));
      setOpen(false);
    } else {
      setLoading(false);
      setErrorAlert({
        errorMsg: JSON.stringify(result.message),
        errorType: "error",
        isOpen: true,
      });
    }
  };
  return (
    <React.Fragment>
      <Dialog
        disablePortal
        sx={{ "& .MuiDialog-container": { mt: "30px" } }}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        {manage === "view_history" ? null : (
          <DialogTitle
            className={classes.appointmentModalHeading}
            id="alert-dialog-title"
          >
            <Box> Item Detail</Box>
            <Stack direction="row" spacing={2} alignItems={"center"}>
              {loading ? <CircularProgress size={25} /> : ""}
              <ButtonGroups
                id="Manage-button-menu"
                bgColor="#0B0909"
                bgColorHover="#0B0909"
                placement={"bottom"}
                height="36px"
                size="medium"
                color="white"
                options={[
                  {
                    title: "Check in",
                    value: "check_in",
                    icon: (
                      <img
                        style={{ width: "18px", marginLeft: "4px" }}
                        src={checkIcon}
                        alt={"checkIcon"}
                      />
                    ),
                  },
                  {
                    title: "View History",
                    value: "view_history",
                    icon: <HistoryIcon />,
                  },
                ]}
                onChangeMenu={(value) => {
                  if (value === "check_in") {
                    handleCheckIN();
                  } else {
                    setManage(value);
                  }
                }}
                value={manage}
                placeholder={"Manage"}
                {...props}
              />
            </Stack>
          </DialogTitle>
        )}
        <DialogContent sx={{ width: "550px", minHeight: "28vh" }}>
          <br />
          {manage === "" ? (
            <Grid container direction="row" spacing={2}>
              <Grid item xs={6} sx={{ zIndex: "1" }}>
                <Card sx={{ minWidth: "250px", padding: "5px 15px" }}>
                  <CardHeader
                    className={classes.EquipmentDetailSubBox}
                    classes={{
                      title: classes.agentInventoryModalCardHeader,
                      subheader: classes.agentInventoryModalCardDes,
                    }}
                    avatar={
                      <img
                        src={agentEquipmentIcon}
                        alt="agent Method Icon White"
                      />
                    }
                    title={equipmentBox.inventory?.AssetName}
                    subheader={`Serial Number: ${equipmentBox.inventory?.SerialNumber}`}
                  />
                </Card>
              </Grid>
              <Grid item xs={12} sx={{ zIndex: "1" }}>
                <InputLabel
                  sx={{
                    fontWeight: "500",
                    color: "rgba(0, 0, 0, 0.87)",
                    fontSize: "14px",
                    marginBottom: "8px",
                  }}
                >
                  Listing attached
                </InputLabel>
                {equipmentBox?.transaction_listings_v2 ? (
                  <Card evaluation={2} sx={{ padding: "5px 15px" }}>
                    <CardHeader
                      className={classes.EquipmentDetailSubBox}
                      classes={{
                        title: classes.agentInventoryModalCardHeader,
                        subheader: classes.agentInventoryModalCardDes,
                      }}
                      avatar={
                        <img
                          style={{
                            width: "180px",
                            height: "120px",
                            objectFit: "cover",
                            borderRadius: "6px",
                          }}
                          src={
                            equipmentBox?.transaction_listings_v2
                              ?.property_img || agentHouseIcon
                          }
                          alt="agent Method Icon White"
                        />
                      }
                      title={equipmentBox?.transaction_listings_v2?.street}
                      subheader={
                        <Box>
                          <Typography>
                            {equipmentBox?.transaction_listings_v2?.city},{" "}
                            {equipmentBox?.transaction_listings_v2?.state}{" "}
                            {equipmentBox?.transaction_listings_v2?.zipcode}
                          </Typography>
                          <Chip
                            sx={{
                              background:
                                equipmentBox?.transaction_listings_v2
                                  ?.status === "active"
                                  ? "green"
                                  : "#ECD06F",
                              minWidth: "150px",
                              color: "white",
                              marginTop: "26px",
                            }}
                            label={
                              equipmentBox?.transaction_listings_v2?.status
                            }
                          />
                        </Box>
                      }
                    />
                  </Card>
                ) : (
                  <Card evaluation={2} sx={{ padding: "5px 15px" }}>
                    <CardContent>Listing not attached</CardContent>
                  </Card>
                )}
              </Grid>
              <Grid item xs={12} sx={{ zIndex: "1" }}>
                <InputLabel
                  sx={{
                    fontWeight: "500",
                    color: "rgba(0, 0, 0, 0.87)",
                    fontSize: "14px",
                    marginBottom: "8px",
                  }}
                >
                  Assigned to
                </InputLabel>
                <Card evaluation={2} sx={{ padding: "5px 15px" }}>
                  <CardHeader
                    className={classes.EquipmentDetailSubBox}
                    classes={{
                      title: classes.agentInventoryModalCardHeader,
                      subheader: classes.agentInventoryModalCardDes,
                    }}
                    avatar={
                      <Avatar
                        variant="rounded"
                        src={
                          equipmentBox?.users_v2?.profile_images?.profile_img ||
                          equipmentBox?.users_v2?.profile_images
                            ?.slack_profile_img ||
                          equipmentBox?.users_v2?.profile_images
                            ?.slack_profile_img_thumbnail ||
                          agentAvatar
                        }
                        alt="agent Avatar"
                      >{`${equipmentBox?.users_v2?.full_name?.slice(
                        0,
                        1
                      )}${equipmentBox?.users_v2?.full_name
                        ?.split(" ")[1]
                        ?.slice(0, 1)}`}</Avatar>
                    }
                    title={equipmentBox?.users_v2?.full_name?.split(" ")[0]}
                    subheader={equipmentBox?.users_v2?.full_name?.split(" ")[1]}
                    action={
                      <Box sx={{ marginTop: "6px" }}>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={2}
                        >
                          {" "}
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            component="div"
                          >
                            Active Listings
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            component="div"
                          >
                            3
                          </Typography>
                        </Stack>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={2}
                        >
                          {" "}
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            component="div"
                          >
                            Total Supras
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            gutterBottom
                            component="div"
                          >
                            4
                          </Typography>
                        </Stack>
                      </Box>
                    }
                  />
                </Card>
              </Grid>
            </Grid>
          ) : (
            <Box>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} md={7} sx={{ zIndex: "1" }}>
                  <Card
                    sx={{
                      minWidth: "250px",
                      padding: "0px 15px 5px 15px",
                      boxShadow: "none",
                      mt: "-10px",
                    }}
                  >
                    <CardHeader
                      className={classes.EquipmentDetailSubBox}
                      classes={{
                        title: classes.agentInventoryModalCardHeader,
                        subheader: classes.agentInventoryModalCardDes,
                      }}
                      avatar={
                        <img
                          src={agentEquipmentIcon}
                          alt="agent Method Icon White"
                        />
                      }
                      title={equipmentBox.inventory?.AssetName}
                    />
                  </Card>
                </Grid>
              </Grid>
              <TextField
                fullWidth
                readOnly
                label="History"
                sx={{ mt: "30px" }}
                value={
                  logsHistory.data?.length
                    ? `${logsHistory.data.map(
                        (item) =>
                          `${moment(item.createdAt).format("L")} @ ${moment(
                            item.createdAt
                          ).format("LT")} - ${item.Description} \n`
                      )}`
                    : "Not Exists!"
                }
                multiline
                rows={
                  logsHistory.data?.length
                    ? Number(logsHistory.data?.length) + 14
                    : 14
                }
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px" }}>
          <Button
            onClick={() => {
              setManage("");
              setOpen(false);
            }}
            classes={{ root: classes.commisionSelectionButTon }}
            color="inherit"
            autoFocus
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, {
  name: "InventoryItemDetailEquipmentModalStyle",
})(InventoryItemDetailEquipmentModal);
