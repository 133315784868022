export const styleSheet = () => ({
  mainDashboardPage: {
    marginTop: "70px",
    maxWidth: "90%",
    margin: "0 auto",
    marginBottom: "50px",
  },
  methodWeatherBox: {
    height: "100%",
    padding: "15px",
    boxShadow: "0px 0px 0px 1px #E0E0E0",
    borderRadius: "4px",
  },
  methodWeatherTop: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
  },
  methodWeatherTopH1: {
    fontSize: "1.5rem",
    fontWeight: "600",
    fontFamily: "AvenirNext!important",
    margin: "0px",
  },
  descriptionBox: {
    height: "100%",
    padding: "15px",
    boxShadow: "0px 0px 0px 1px #E0E0E0",
    borderRadius: "4px",
  },
  descriptionBoxH1: {
    margin: 0,
    padding: 0,
  },
  methodWeatherTopP: {
    fontSize: "14px",
  },
  mainCardArea: {
    display: "flex",
    justifyContent: "center",
    "&:hover": {
      "& .IsHovered": {
        display: "flex !important",
        alignItems: "flex-end",
      },
      "& .hoveredBlock": {
        width: "100%",
        height: "100%",
        borderRadius: "40px",
        background:
          "linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6))",
      },
    },
  },
  videoTextImg: {
    width: "425px",
    height: "220px",
    position: "relative !important",
    textAlign: "center",
    cursor: "pointer",
    "& .hoveredBlock": {
      position: "absolute",
      bottom: "0px",
      left: "0px",
      width: "0",
      height: "100%",
      padding: "30px 10px",
      transition: "1s ease",
    },
  },
  imgCourses: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    boxShadow:
      "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
    borderRadius: "40px",
    "&:hover": {
      filter: "brightness(50%)",
    },
  },
  upperHoverUI: {
    display: "none",
  },
  hoverText: {
    width: "100% !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "14px",
    fontFamily: "AvenirNext",
    fontWeight: "400",
    color: "white",
    "&:hover": {
      "& imgCourses": {
        filter: "brightness(50%) !important",
      },
    },
  },
  hoverTextMargin: {
    textAlign: "left",
    fontSize: "12px",
    marginBottom: "0px",
    marginLeft: "10px",
  },
  customListingChip: {
    textAlign: "left",
    marginBottom: "0px",
    border: "1px solid lightgrey",
    borderRadius: "12px",
    fontSize: "12px",
    padding: "1px 8px",
    marginLeft: "10px",
    whiteSpace: "noWrap",
  },
  hoverText2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "white",
    height: "25px",
  },
});
