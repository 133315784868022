import React, { useState, useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { DialogTitle, TextField, Typography, Box } from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import ResponseAlert from "../../../components/responseAlert";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useSelector, useDispatch } from "react-redux";
import { updateListing } from "../../../redux/listings/listingDetail/updateListing";
function BuyerCompensation(props) {
  let { open, setOpen, classes, ListingData } = props;
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const inputValue = useRef(null);
  const update = useSelector((state) => state.listings.listingDetail.update);
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
  };
  const handleSuccess = () => {
    setErrorAlert({
      errorMsg: `You have successfully ${
        ListingData?.buyer_compensation ? "Updated" : "Saved"
      } `,
      errorType: "success",
      isOpen: true,
    });
    handleClose();
  };
  const handleError = (error) => {
    setErrorAlert({
      errorMsg: JSON.stringify(error),
      errorType: "success",
      isOpen: true,
    });
  };

  const handleSubmit = () => {
    if (inputValue?.current.value) {
      dispatch(
        updateListing({
          id: ListingData.id,
          handleSuccess,
          handleError,
          schema: {
            buyer_compensation: inputValue?.current.value,
          },
        })
      );
    } else {
      setErrorAlert({
        errorMsg: "Please select the Update type coming soon or active",
        errorType: "warning",
        isOpen: true,
      });
    }
  };

  return (
    <React.Fragment>
      <Dialog
        disablePortal
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle className={classes.modalHeader} id="alert-dialog-title">
          {ListingData?.buyer_compensation ? "Update" : "Add"} Buyer
          Compensation
          <Box className={classes.modalHeaderRight}>
            <LoadingButton
              onClick={() => handleSubmit()}
              className={classes.saveModalButton}
              color="inherit"
              autoFocus
              disabled={update.isLoading}
              loading={update.isLoading}
              loadingPosition="start"
            >
              {ListingData?.buyer_compensation ? "Update" : "Save"}
            </LoadingButton>
            <Button
              onClick={() => handleClose()}
              sx={{ minWidth: "25px" }}
              variant="text"
              color="inherit"
            >
              <CloseRoundedIcon />
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center !important",
              gap: "10px",
              mt: "20px",
              mb: "15px",
            }}
          >
            <TextField
              size="small"
              placeholder="3.0"
              inputRef={inputValue}
              variant="outlined"
              type={"number"}
              defaultValue={ListingData?.buyer_compensation}
            />
            <Typography sx={{ fontSize: "18px" }}>%</Typography>
          </Box>
        </DialogContent>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "BuyerCompensationStyle" })(
  BuyerCompensation
);
