import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "../../../../components/uiElements/avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  Autocomplete,
  FormControlLabel,
  Grid,
  Switch,
  Box,
  Typography,
  IconButton,
  Checkbox,
  InputAdornment,
  ListSubheader,
} from "@mui/material";
import {
  createProject,
  setProjectModalFields,
  uploadAttachments,
  SaveProjectAsDraft,
} from "../../../../redux/projects/projects";
import { getDepartmentProjectList } from "../../../../redux/projects/common";
import { getProjectModalFields } from "../../../../redux/projects/projects";
import { getFieldSize } from "../../helpers";
import ConfirmModal from "../ConfirmModal";
import { DateTimePicker } from "@mui/x-date-pickers";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { getAllPropertyList } from "../../../../redux/propertyListing";
import ResponseAlert from "../../../../components/responseAlert";
import LoadingButton from "@mui/lab/LoadingButton";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { AdditionalClauses } from "./data";
import FileUploadRoundedIcon from "@mui/icons-material/FileUploadRounded";
import { toast } from "react-toastify";
import moment from "moment";
import SearchProperties from "./searchProperties";
import DraftProject from "../projectList/draftProject";
function CreateProject(props) {
  const dispatch = useDispatch();
  const {
    setOpen,
    handleClosePopup,
    handleAddMinimize,
    minimizeFlag,
    maximizedTab,
    projectModule,
    classes,
    draftDetail,
  } = props;
  const propertyList = useSelector((item) => item.propertyList);
  const [formData, setFormData] = useState({
    due_date: new Date(),
    agent_id: "",
    department_id: "",
    task_type_id: "",
    visibility: "visible",
  });
  const [manualToggle, setManualToggle] = useState(false);

  const [confirmationDialogOpen, openConfirmationDialog] = useState(false);
  const [formDataErrors, setFormDataErrors] = useState({});
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const [selectedClauses, setSelectedClauses] = useState([]);
  const [clauseValue, setClauseValue] = useState(null);
  const [loading, setLoading] = useState(false);

  const departments = useSelector((state) => state.project.departments);
  const departmentProjects = useSelector(
    (state) => state.project.departmentProjects
  );
  const modalFields = useSelector((state) => state.project.projectModalFields);
  const agents = useSelector((state) =>
    state.users.storedListOfUsers.list.filter(
      (user) => user.is_agent === true && user.status === "active"
    )
  );

  const usersData = useSelector((state) =>
    state.users.storedListOfUsers.list.filter(
      (user) => user.is_agent === true && user.status === "active"
    )
  );

  const users = useSelector((state) =>
    state.users.storedListOfUsers.list.filter(
      (user) => user.is_agent === true && user.status === "active"
    )
  ).map((user) => user.full_name);

  /**
   * End Region: Variables and States
   */
  /**
   * Helpers
   */
  const closeProjectForm = () => {
    openConfirmationDialog(false);
    if (setOpen) {
      setOpen(false);
    }

    if (handleClosePopup) {
      handleClosePopup();
    }
    setFormData({ visibility: "visible" });
    setFormDataErrors({});
    dispatch(setProjectModalFields([]));
  };

  const handleClose = () => {
    if (Object.keys(formData).length > 1) {
      openConfirmationDialog(true);
    } else {
      if (handleClosePopup) {
        handleClosePopup();
      }
      closeProjectForm();
    }
  };

  const handleReturnClausesFields = () => {
    let clausesFieldKeys = {};
    if (selectedClauses?.length) {
      selectedClauses?.map((item, index) => {
        if (item?.field_key) {
          if (
            item?.field_key === "stipulate_disclosures" &&
            item?.fields?.filter((it) => it.checked)?.length > 0
          ) {
            clausesFieldKeys[item?.field_key] = `${
              item?.fields?.filter((it) => it.checked)?.length === 2
                ? "BOTH"
                : item?.fields?.find((it) => it.checked)?.label
            }`;
          } else if (
            item.field_key === "common_items_remain" &&
            item?.fields?.filter((it) => it.checked)?.length > 0
          ) {
            clausesFieldKeys[item?.field_key] = `${item?.fields
              ?.slice(0, 2)
              ?.filter((it) => it.checked)
              ?.map((it) => it.label)
              ?.join(" and ")} ${
              item.fields.find(
                (it) =>
                  it.label === "Other" &&
                  it.checked &&
                  item.fields.find((it) => it.type === "textField")?.value
              )
                ? `Others:${
                    item.fields.find((it) => it.type === "textField")?.value
                  } `
                : ""
            }`;
          }
        } else {
          item.fields?.map((it, index) => {
            if (it.type === "checkbox") {
              clausesFieldKeys[it?.field_key] = it.checked;
            } else if (it.type === "datePicker") {
              clausesFieldKeys[it?.field_key] = moment(it.value).format();
            } else if (it.type === "timePicker") {
              clausesFieldKeys[it?.field_key] = moment(it.value).format(
                "HH:mm"
              );
            } else if (it.value) {
              clausesFieldKeys[it?.field_key] = `${it.value}`;
            } else if (
              it.type === "attachments" &&
              it?.uploadedAttachments?.length
            ) {
              it?.uploadedAttachments?.map((attach, idx) => {
                clausesFieldKeys[`attachment_${idx + 1}`] = attach.url;
              });
            }
          });
        }
      });
    }
    return clausesFieldKeys;
  };
  const handleCreateProject = () => {
    let errors = {};

    modalFields &&
      modalFields.map((field) => {
        if (
          !field.optional &&
          field.type !== "switch" &&
          field.type !== "combo_switch"
        ) {
          if (!formData[field.name]) {
            errors[field.name] = true;
          }
        }
        if (field.type === "combo_switch" && !field.optional) {
          if (manualToggle) {
            field.active_fields.map((subfield) => {
              if (!subfield.optional) {
                if (!formData[subfield.name]) {
                  errors[subfield.name] = true;
                }
              }
            });
          } else {
            field.inactive_fields.map((subfield) => {
              if (!subfield.optional) {
                if (!formData[subfield.name]) {
                  errors[subfield.name] = true;
                }
              }
            });
          }
        }

        if (field.type === "switch" && formData[field.name] === true) {
          setFormData({
            ...formData,
            [field.name]: formData[field.name] || false,
          });
          if (field.subfields) {
            field.subfields.map((subfield) => {
              if (!subfield.optional) {
                if (!formData[subfield.name]) {
                  errors[subfield.name] = true;
                }
              }
            });
          }
        }
      });

    if (!formData.due_date) {
      errors.due_date = true;
    }
    if (!formData.agent_id) {
      errors.agent_id = true;
    }
    if (!formData.task_type_id) {
      errors.task_type_id = true;
    }
    if (!formData.department_id) {
      errors.department_id = true;
    }
    setFormDataErrors(errors);
    if (Object.keys(errors).length === 0) {
      setLoading(true);
      dispatch(
        createProject({
          ...formData,
          ...handleReturnClausesFields(),
          handleSuccess,
          handleError,
        })
      );
    }
  };

  const handleDraftSuccess = () => {
    setLoading(false);
    setErrorAlert({
      errorMsg: "You have successfully the  project as draft",
      errorType: "success",
      isOpen: true,
    });
    setTimeout(() => {
      closeProjectForm();
    }, 2000);
  };

  const handleSaveProjectAsDraft = () => {
    setLoading(true);
    dispatch(
      SaveProjectAsDraft({
        ...formData,
        ...handleReturnClausesFields(),
        handleSuccess: handleDraftSuccess,
        handleError,
      })
    );
  };

  const handleSuccess = () => {
    setLoading(false);
    setErrorAlert({
      errorMsg: "You have successfully created the project",
      errorType: "success",
      isOpen: true,
    });
    setTimeout(() => {
      closeProjectForm();
    }, 2000);
  };
  const handleError = (error) => {
    setLoading(false);
    setErrorAlert({
      errorMsg: JSON.stringify(error),
      errorType: "error",
      isOpen: true,
    });
  };
  /**
   * End Region: Helpers
   */
  /**
   * useEffets Hooks
   */
  useEffect(() => {
    if (formData.department_id) {
      dispatch(getDepartmentProjectList(formData.department_id));
    }
  }, [formData.department_id]);

  useEffect(() => {
    if (minimizeFlag) {
      handleAddMinimize({
        ...formData,
        tabTitle: `${
          formData.department_id
            ? departments.find((item) => item.id === formData.department_id)
                ?.name
            : ""
        }${formData?.task_type_id ? " | " : ""}${
          formData?.task_type_id
            ? departmentProjects.find(
                (item) => item.id === formData.task_type_id
              )?.name
            : ""
        }`,
        user: formData.agent_id
          ? agents.find((item) => item.id === formData.agent_id)
          : "",
      });
    }
  }, [minimizeFlag]);

  useEffect(() => {
    if (maximizedTab && maximizedTab.tabDetail) {
      setFormData(maximizedTab.tabDetail);
    }
  }, [maximizedTab]);

  useEffect(() => {
    if (formData.task_type_id && formData.department_id) {
      dispatch(
        getProjectModalFields({
          deptID: formData.department_id,
          projectID: formData.task_type_id,
          agent_id: formData.agent_id,
        })
      );
    }
  }, [formData.task_type_id, formData.department_id]);

  React.useEffect(() => {
    if (formData["Is there a host agent"] && !formData["Create flyers"]) {
      setFormData({
        ...formData,
        "Create flyers": true,
      });
    }
  }, [formData]);

  useEffect(() => {
    dispatch(getAllPropertyList({ under_contract: 1 }));
  }, []);

  useEffect(() => {
    if (draftDetail) {
      let newDetail = { ...draftDetail };
      if (newDetail?.projects_data) {
        delete newDetail?.projects_data;
        newDetail = { ...newDetail, ...draftDetail.projects_data };
      }
      delete newDetail.projects_data;
      setFormData(newDetail);
    }
  }, [draftDetail]);

  /**
   * End Region: useEffect Hooks
   */
  const handleSortListing = (data) => {
    let newData = [...data];
    if (newData?.length) {
      let sortedArray = newData?.sort(
        (a, b) =>
          parseFloat(a.street?.replace("/r", "/")?.replace(/\s/g, "")) -
          parseFloat(b.street?.replace("/r", "/")?.replace(/\s/g, ""))
      );
      return sortedArray;
    }
  };
  function convertTo24HourFormat(time12h) {
    const [time, period] = time12h.split(" ");
    let [hours, minutes] = time.split(":");
    hours = parseInt(hours, 10);
    minutes = parseInt(minutes, 10);

    if (period === "PM" && hours !== 12) {
      hours += 12;
    } else if (period === "AM" && hours === 12) {
      hours = 0;
    }

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
  }

  const handleFileChange = async (files, item, field) => {
    const loadingToastId = toast.loading("Uploading...");

    const filesArray = Array.from(files);
    filesArray.sort((a, b) =>
      a.name.localeCompare(b.name, "en", { sensitivity: "base", numeric: true })
    );
    const Data = new FormData();
    filesArray?.map((file) => {
      Data.append("attachment", file);
    });

    const response = await dispatch(
      uploadAttachments({
        formData: Data,
      })
    );
    if (response?.payload?.data?.data?.url) {
      let preClauses = [...selectedClauses];
      let index = preClauses?.findIndex((it) => it?._id === item._id);
      let fieldIndex;
      if (field.type) {
        fieldIndex = preClauses[index]?.fields?.findIndex(
          (it) => it.type === field.type
        );
        preClauses[index].fields[fieldIndex].uploadedAttachments.push(
          response?.payload?.data?.data
        );
      }
      setSelectedClauses(preClauses);
      toast.update(loadingToastId, {
        render: "Successfully uploaded!",
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } else {
      toast.update(loadingToastId, {
        render: "An error occurred please try again later!",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };

  const handleRemoveAttachment = (item, field, preIndex) => {
    let preClauses = [...selectedClauses];
    let index = preClauses?.findIndex((it) => it?._id === item._id);
    let fieldIndex;
    if (field.type) {
      fieldIndex = preClauses[index]?.fields?.findIndex(
        (it) => it.type === field.type
      );
      let preAttachments = [
        ...preClauses[index].fields[fieldIndex].uploadedAttachments,
      ];
      preAttachments?.splice(preIndex, 1);
      preClauses[index].fields[fieldIndex].uploadedAttachments = preAttachments;
      setSelectedClauses(preClauses);
    }
  };

  const handleUpdateClause = (value, item, field) => {
    let preClauses = [...selectedClauses];
    let index = preClauses?.findIndex((it) => it?._id === item._id);
    let fieldIndex;
    if (field.label) {
      fieldIndex = preClauses[index]?.fields?.findIndex(
        (it) => it.label === field.label
      );
    } else if (field.endLabel) {
      fieldIndex = preClauses[index]?.fields?.findIndex(
        (it) => it.endLabel === field.endLabel
      );
    } else if (field.field_key) {
      fieldIndex = preClauses[index]?.fields?.findIndex(
        (it) => it.field_key === field.field_key
      );
    } else if (field.id) {
      fieldIndex = preClauses[index]?.fields?.findIndex(
        (it) => it.id === field.id
      );
    }
    if (field?.type === "checkbox") {
      preClauses[index].fields[fieldIndex].checked = value;
    } else if (field?.type === "textField") {
      preClauses[index].fields[fieldIndex].value = value;
    } else if (field?.type === "datePicker" || field?.type === "timePicker") {
      preClauses[index].fields[fieldIndex].value = value;
    }
    setSelectedClauses(preClauses);
  };
  return (
    <Box sx={{ padding: projectModule ? "20px 24px" : "" }}>
      <DialogTitle className={classes.modalHeader}>
        <Typography className={classes.projectTitle}>
          {" "}
          Create Project {draftDetail ? `${draftDetail ? `(Draft)` : ""}` : ""}
        </Typography>
        <Box className={classes.modalAction}>
          <Button
            className={classes.draftButton}
            variant="outlined"
            onClick={handleSaveProjectAsDraft}
            color="inherit"
          >
            SAVE AS DRAFT
          </Button>
          <LoadingButton
            className={classes.createButton}
            onClick={handleCreateProject}
            loadingPosition="start"
            loading={loading}
          >
            Create
          </LoadingButton>
          <IconButton onClick={handleClose}>
            <CloseRoundedIcon sx={{ fontSize: "30px" }} />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent className={classes.dialog}>
        <ConfirmModal
          open={confirmationDialogOpen}
          handleYes={closeProjectForm}
          handleNo={() => openConfirmationDialog(false)}
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Grid container spacing={3} className={classes.topfieldContainer}>
            <Grid item xs={6}>
              <InputLabel className={classes.inputLabelArea}>
                {" "}
                Select Agent
              </InputLabel>
              <Autocomplete
                disablePortal
                id="onboarding-coordinator-dropdown"
                value={
                  formData.agent_id
                    ? agents?.find((item) => item.id === formData.agent_id)
                    : null
                }
                onChange={(event, value) => {
                  setFormData({ ...formData, agent_id: value?.id || "" });
                  if (formDataErrors.agent_id) {
                    setFormDataErrors({ ...formDataErrors, agent_id: false });
                  }
                }}
                getOptionLabel={(option) => option.full_name}
                options={agents?.length ? [...agents] : []}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Agents"
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={formDataErrors.agent_id}
                    helperText={
                      formDataErrors.agent_id ? "* required field" : ""
                    }
                    className={classes.field}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: formData.agent_id ? (
                        <Avatar
                          sx={{
                            width: "28px",
                            height: "28px",
                            fontSize: "12px",
                            ml: "10px",
                          }}
                          alt={
                            agents.find((item) => item.id === formData.agent_id)
                              ?.full_name
                          }
                          src={
                            agents.find((item) => item.id === formData.agent_id)
                              ?.profile_images?.profile_img_thumbnail
                          } // Assuming `avatar_url` holds the image URL
                          className={classes.avatar}
                        >{`${agents
                          .find((item) => item.id === formData.agent_id)
                          ?.first_name?.slice(0, 1)}${agents
                          .find((item) => item.id === formData.agent_id)
                          ?.last_name?.slice(0, 1)}`}</Avatar>
                      ) : null,
                    }}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Avatar
                      alt={option.full_name}
                      src={
                        option?.profile_images?.profile_img ||
                        option?.profile_images?.profile_img_thumbnail
                      }
                      sx={{
                        width: "28px",
                        height: "28px",
                        fontSize: "12px",
                        mr: "10px",
                      }}
                      className={classes.avatar}
                    >{`${option?.first_name?.slice(
                      0,
                      1
                    )}${option?.last_name?.slice(0, 1)}`}</Avatar>
                    {option.full_name}
                  </li>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel className={classes.inputLabelArea}>
                Department
              </InputLabel>
              <Autocomplete
                disablePortal
                id="onboarding-coordinator-dropdown"
                value={
                  formData.department_id
                    ? departments?.find(
                        (item) => item.id === formData.department_id
                      )
                    : null
                }
                onChange={(event, value) => {
                  setFormData({
                    ...formData,
                    department_id: value?.id || "",
                    task_type_id: "",
                  });
                  dispatch(setProjectModalFields([]));
                  setSelectedClauses([]);
                  if (formDataErrors.department_id) {
                    setFormDataErrors({
                      ...formDataErrors,
                      department_id: false,
                    });
                  }
                }}
                getOptionLabel={(option) => option.name}
                options={departments?.length ? [...departments] : []}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Departments"
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={formDataErrors.department_id}
                    helperText={
                      formDataErrors.department_id ? "* required field" : ""
                    }
                    className={classes.field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel className={classes.inputLabelArea}>
                Project
              </InputLabel>

              <Autocomplete
                disablePortal
                id="onboarding-coordinator-dropdown"
                value={
                  formData.task_type_id
                    ? departmentProjects?.find(
                        (item) => item.id === formData.task_type_id
                      )
                    : null
                }
                onChange={(event, value) => {
                  setFormData({ ...formData, task_type_id: value?.id || "" });
                  if (formDataErrors.task_type_id) {
                    setFormDataErrors({
                      ...formDataErrors,
                      task_type_id: false,
                    });
                  }
                  setSelectedClauses([]);
                }}
                getOptionLabel={(option) => option.name}
                options={
                  departmentProjects?.length ? [...departmentProjects] : []
                }
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Projects"
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={formDataErrors.task_type_id}
                    helperText={
                      formDataErrors.task_type_id ? "* required field" : ""
                    }
                    className={classes.field}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel className={classes.inputLabelArea}>
                Visibility
              </InputLabel>
              <TextField
                value={formData?.visibility}
                onChange={(e) =>
                  setFormData((form) => ({
                    ...form,
                    visibility: e.target.value,
                  }))
                }
                size="small"
                select
                fullWidth
              >
                <MenuItem value="visible"> Visible</MenuItem>
                <MenuItem value="hidden"> Hidden</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6} sx={{ display: "none" }}>
              <DateTimePicker
                fullWidth
                format="MM/dd/yyyy hh:mm a"
                id={`standard-due-date`}
                label={"Due Date"}
                value={formData.due_date || null}
                placeholder={"Due Date"}
                onChange={(e) => {
                  setFormData({ ...formData, due_date: e });
                  if (formDataErrors.due_date) {
                    setFormDataErrors({ ...formDataErrors, due_date: false });
                  }
                }}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      error={formDataErrors.due_date}
                      helperText={
                        formDataErrors.due_date ? "* required field" : ""
                      }
                      autoComplete="off"
                    />
                  );
                }}
              />
            </Grid>
            {modalFields &&
              modalFields.map((field) => {
                if (field.listing_options) {
                  return (
                    <Grid item xs={getFieldSize(field.size)}>
                      <InputLabel className={classes.inputLabelArea}>
                        Select Listing
                      </InputLabel>
                      <TextField
                        id="attached_listing"
                        fullWidth
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            Listing: e.target.value.id,
                            "Property Address": e.target.value.street,
                            State: e.target.value.state,
                            Zip: e.target.value.zipcode,
                          });
                          if (formDataErrors["Listing Id"]) {
                            setFormDataErrors({
                              ...formDataErrors,
                              Listing: false,
                            });
                          }
                          // updateContractDetail("attached_listing", `${e.target.value}`);
                        }}
                        value={formData[field.name]}
                        select
                        required
                        size="small"
                        autoComplete="off"
                      >
                        {propertyList?.list?.data &&
                          propertyList?.list?.data.length > 0 &&
                          handleSortListing(propertyList?.list?.data)?.map(
                            (item, index) => (
                              <MenuItem key={index} value={item}>
                                {item.property_address}
                              </MenuItem>
                            )
                          )}
                      </TextField>
                    </Grid>
                  );
                } else if (field.type === "combo_switch") {
                  return (
                    <Grid item xs={12}>
                      <Typography className={classes.inputLabelWithToggle}>
                        {manualToggle
                          ? field?.active_fields?.[0]?.label
                          : field?.inactive_fields?.[0]?.label}
                        <Box>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={manualToggle}
                                onChange={(e) => {
                                  setManualToggle(e.target.checked);
                                  let newFormData = { ...formData };
                                  delete newFormData.City;
                                  delete newFormData["Property Address"];
                                  delete newFormData.Zip;
                                  delete newFormData.State;
                                  setFormData(newFormData);
                                }}
                                color="success"
                              />
                            }
                            label={field?.label || "Manual Input"}
                          />
                        </Box>
                      </Typography>
                      <Grid container spacing={3}>
                        {(manualToggle
                          ? field.active_fields
                          : field.inactive_fields
                        )?.map((subField, idx2) => {
                          if (subField.type === "text") {
                            return (
                              <Grid item xs={getFieldSize(subField.size)}>
                                {idx2 === 0 ? null : (
                                  <InputLabel
                                    className={classes.inputLabelArea}
                                  >
                                    {subField.label}
                                  </InputLabel>
                                )}
                                <TextField
                                  fullWidth
                                  id={`standard-${subField.name
                                    .split(" ")
                                    .join("")
                                    .toLowerCase()}`}
                                  value={formData[subField.name]}
                                  placeholder={subField.name}
                                  error={formDataErrors[subField.name]}
                                  helperText={
                                    formDataErrors[subField.name]
                                      ? "* required field"
                                      : ""
                                  }
                                  onChange={(e) => {
                                    setFormData({
                                      ...formData,
                                      [subField.name]: e.target.value,
                                    });
                                    if (formDataErrors[subField.name]) {
                                      setFormDataErrors({
                                        ...formDataErrors,
                                        [subField.name]: false,
                                      });
                                    }
                                  }}
                                  autoComplete="off"
                                  size="small"
                                />
                              </Grid>
                            );
                          } else if (subField.type === "autocomplete") {
                            return (
                              <Grid item xs={getFieldSize(subField.size)}>
                                {idx2 === 0 ? null : (
                                  <InputLabel
                                    className={classes.inputLabelArea}
                                  >
                                    {subField.label}
                                  </InputLabel>
                                )}

                                <Autocomplete
                                  options={subField.options}
                                  fullWidth
                                  id={`standard-${subField.name
                                    .split(" ")
                                    .join("")
                                    .toLowerCase()}`}
                                  value={formData[subField.name]}
                                  onChange={(e, value) => {
                                    setFormData({
                                      ...formData,
                                      [subField.name]: value,
                                    });
                                    if (formDataErrors[subField.name]) {
                                      setFormDataErrors({
                                        ...formDataErrors,
                                        [subField.name]: false,
                                      });
                                    }
                                  }}
                                  renderInput={(params) => {
                                    return (
                                      <TextField
                                        {...params}
                                        fullWidth
                                        error={formDataErrors[subField.name]}
                                        size={"small"}
                                        helperText={
                                          formDataErrors[subField.name]
                                            ? "* required field"
                                            : ""
                                        }
                                      />
                                    );
                                  }}
                                  getOptionLabel={(option) => option}
                                  freeSolo
                                />
                              </Grid>
                            );
                          } else if (subField.type === "dropdown") {
                            return (
                              <Grid item xs={getFieldSize(subField.size)}>
                                {idx2 === 0 ? null : (
                                  <InputLabel
                                    className={classes.inputLabelArea}
                                  >
                                    {subField.label}
                                  </InputLabel>
                                )}

                                <Select
                                  className={
                                    formDataErrors[subField.name]
                                      ? classes.fieldError
                                      : classes.field
                                  }
                                  fullWidth
                                  id={`standard-${subField.name
                                    .split(" ")
                                    .join("")
                                    .toLowerCase()}`}
                                  // label={subField.name}
                                  value={formData[subField.name]}
                                  placeholder={subField.name}
                                  error={formDataErrors[subField.name]}
                                  helperText={
                                    formDataErrors[subField.name]
                                      ? "* required field"
                                      : ""
                                  }
                                  onChange={(e) => {
                                    if (e.target.value.type === "listing") {
                                      setFormData({
                                        ...formData,
                                        [subField.name]: e.target.value.value,
                                        listing_id: e.target.value.id,
                                        contract_id: undefined,
                                      });
                                    } else {
                                      setFormData({
                                        ...formData,
                                        [subField.name]: e.target.value.value,
                                        contract_id: e.target.value.id,
                                        listing_id: undefined,
                                      });
                                    }
                                    if (formDataErrors[subField.name]) {
                                      setFormDataErrors({
                                        ...formDataErrors,
                                        [subField.name]: false,
                                      });
                                    }
                                  }}
                                  size="small"
                                >
                                  <MenuItem value="">
                                    <em>None</em>
                                  </MenuItem>
                                  {subField.name === "Listing" ||
                                  subField.name === "Listing or Transaction" ||
                                  subField.name === "Transaction"
                                    ? Object.entries(
                                        subField.options.reduce(
                                          (acc, option) => {
                                            const type =
                                              option.type || "Others"; // Default type if not defined
                                            acc[type] = acc[type] || [];
                                            acc[type].push(option);
                                            return acc;
                                          },
                                          {}
                                        )
                                      ).map(([type, options]) => [
                                        <ListSubheader
                                          key={type}
                                          sx={{
                                            fontSize: "18px",
                                            color: "black",
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {type}
                                        </ListSubheader>,
                                        options.map((option) => (
                                          <MenuItem
                                            key={option.value || option}
                                            value={option}
                                          >
                                            {option.label}
                                          </MenuItem>
                                        )),
                                      ])
                                    : subField?.options?.map((option) => (
                                        <MenuItem value={option}>
                                          {option?.label || option}
                                        </MenuItem>
                                      ))}
                                </Select>
                              </Grid>
                            );
                          } else if (
                            subField.type === "select" &&
                            subField.name === "Property Address"
                          ) {
                            return (
                              <Grid
                                item
                                xs={
                                  subField.name === "Property Address"
                                    ? 12
                                    : getFieldSize(subField.size)
                                }
                              >
                                {subField.name === "Property Address" ? null : (
                                  <InputLabel
                                    className={classes.inputLabelArea}
                                  >
                                    {subField.label}
                                  </InputLabel>
                                )}
                                <SearchProperties
                                  setFormData={setFormData}
                                  marketMlsId={
                                    formData.agent_id
                                      ? agents.find(
                                          (it) => it.id === formData.agent_id
                                        )?.market?.datasets[0]
                                      : ""
                                  }
                                  {...props}
                                />
                              </Grid>
                            );
                          }
                        })}
                      </Grid>
                    </Grid>
                  );
                } else if (field.type === "text") {
                  return (
                    <Grid item xs={getFieldSize(field.size)}>
                      <InputLabel className={classes.inputLabelArea}>
                        {field.label}
                      </InputLabel>
                      <TextField
                        fullWidth
                        id={`standard-${field.name
                          .split(" ")
                          .join("")
                          .toLowerCase()}`}
                        value={formData[field.name]}
                        placeholder={field.name}
                        error={formDataErrors[field.name]}
                        helperText={
                          formDataErrors[field.name] ? "* required field" : ""
                        }
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            [field.name]: e.target.value,
                          });
                          if (formDataErrors[field.name]) {
                            setFormDataErrors({
                              ...formDataErrors,
                              [field.name]: false,
                            });
                          }
                        }}
                        size="small"
                        autoComplete="off"
                      />
                    </Grid>
                  );
                } else if (field.type === "textarea") {
                  return (
                    <Grid item xs={getFieldSize(field.size)}>
                      <InputLabel className={classes.inputLabelArea}>
                        {field.label}
                      </InputLabel>
                      <TextField
                        fullWidth
                        multiline
                        aria-label="minimum height"
                        minRows={4}
                        maxRows={4}
                        id={`standard-${field.name
                          .split(" ")
                          .join("")
                          .toLowerCase()}`}
                        value={formData[field.name]}
                        placeholder={field.name}
                        error={formDataErrors[field.name]}
                        helperText={
                          formDataErrors[field.name] ? "* required field" : ""
                        }
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            [field.name]: e.target.value,
                          });
                          if (formDataErrors[field.name]) {
                            setFormDataErrors({
                              ...formDataErrors,
                              [field.name]: false,
                            });
                          }
                        }}
                        autoComplete="off"
                      />
                    </Grid>
                  );
                } else if (field.type === "date") {
                  return (
                    <Grid item xs={getFieldSize(field.size)}>
                      <InputLabel className={classes.inputLabelArea}>
                        {field.label}
                      </InputLabel>
                      <DatePicker
                        format="MM/dd/yyyy"
                        id={`standard-${field.name
                          .split(" ")
                          .join("")
                          .toLowerCase()}`}
                        value={formData[field.name] || null}
                        placeholder={field.name}
                        onChange={(e) => {
                          setFormData({ ...formData, [field.name]: e });
                          if (formDataErrors[field.name]) {
                            setFormDataErrors({
                              ...formDataErrors,
                              [field.name]: false,
                            });
                          }
                        }}
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              fullWidth
                              error={formDataErrors[field.name]}
                              helperText={
                                formDataErrors[field.name]
                                  ? "* required field"
                                  : ""
                              }
                              size="small"
                              autoComplete="off"
                            />
                          );
                        }}
                        size="small"
                      />
                    </Grid>
                  );
                } else if (field.type === "number") {
                  return (
                    <Grid item xs={getFieldSize(field.size)}>
                      <InputLabel className={classes.inputLabelArea}>
                        {field.label}
                      </InputLabel>
                      <TextField
                        fullWidth
                        id={`standard-${field.name
                          .split(" ")
                          .join("")
                          .toLowerCase()}`}
                        value={formData[field.name]}
                        placeholder={field.name}
                        error={formDataErrors[field.name]}
                        helperText={
                          formDataErrors[field.name] ? "* required field" : ""
                        }
                        size="small"
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            [field.name]: e.target.value,
                          });
                          if (formDataErrors[field.name]) {
                            setFormDataErrors({
                              ...formDataErrors,
                              [field.name]: false,
                            });
                          }
                        }}
                        autoComplete="off"
                      />
                    </Grid>
                  );
                } else if (
                  field.name === "Who is Hosting" ||
                  field.type === "autocomplete"
                ) {
                  return (
                    <Grid item xs={getFieldSize(field.size)}>
                      <InputLabel className={classes.inputLabelArea}>
                        {field.label}
                      </InputLabel>
                      <Autocomplete
                        options={
                          field.name === "Who is Hosting"
                            ? users
                            : field.options
                        }
                        fullWidth
                        id={`standard-${field.name
                          .split(" ")
                          .join("")
                          .toLowerCase()}`}
                        value={formData[field.name]}
                        onChange={(e, value) => {
                          setFormData({ ...formData, [field.name]: value });
                          if (formDataErrors[field.name]) {
                            setFormDataErrors({
                              ...formDataErrors,
                              [field.name]: false,
                            });
                          }
                        }}
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              fullWidth
                              error={formDataErrors[field.name]}
                              size={"small"}
                              helperText={
                                formDataErrors[field.name]
                                  ? "* required field"
                                  : ""
                              }
                            />
                          );
                        }}
                        freeSolo
                      />
                    </Grid>
                  );
                } else if (field.type === "dropdown") {
                  return (
                    <>
                      <Grid item xs={getFieldSize(field.size)}>
                        <InputLabel className={classes.inputLabelArea}>
                          {field.label}
                        </InputLabel>

                        <Select
                          className={
                            formDataErrors[field.name]
                              ? classes.fieldError
                              : classes.field
                          }
                          fullWidth
                          id={`standard-${field.name
                            .split(" ")
                            .join("")
                            .toLowerCase()}`}
                          // label={field.name}
                          value={formData[field.name]}
                          placeholder={field.name}
                          error={formDataErrors[field.name]}
                          helperText={
                            formDataErrors[field.name] ? "* required field" : ""
                          }
                          onChange={(e) => {
                            if (e.target.value.type === "listing") {
                              setFormData({
                                ...formData,
                                [field.name]: e.target.value.value,
                                listing_id: e.target.value.id,
                                contract_id: undefined,
                              });
                            } else {
                              setFormData({
                                ...formData,
                                [field.name]: e.target.value.value,
                                contract_id: e.target.value.id,
                                listing_id: undefined,
                              });
                            }

                            if (formDataErrors[field.name]) {
                              setFormDataErrors({
                                ...formDataErrors,
                                [field.name]: false,
                              });
                            }
                          }}
                          size="small"
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>

                          {field.name === "Listing" ||
                          field.name === "Listing or Transaction" ||
                          field.name === "Transaction"
                            ? Object.entries(
                                field.options.reduce((acc, option) => {
                                  const type = option.type || "Others"; // Default type if not defined
                                  acc[type] = acc[type] || [];
                                  acc[type].push(option);
                                  return acc;
                                }, {})
                              ).map(([type, options]) => [
                                <ListSubheader
                                  key={type}
                                  sx={{
                                    fontSize: "18px",
                                    color: "black",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {type}
                                </ListSubheader>,
                                options.map((option) => (
                                  <MenuItem
                                    key={option.value || option}
                                    value={option}
                                  >
                                    {option.label}
                                  </MenuItem>
                                )),
                              ])
                            : field?.options?.map((option) => (
                                <MenuItem value={option}>
                                  {option?.label || option}
                                </MenuItem>
                              ))}
                        </Select>
                      </Grid>

                      {formData[field.name] === "Printed" &&
                        field.subfields &&
                        field.subfields.map((subField) => {
                          if (subField.type === "date") {
                            return (
                              <Grid item xs={getFieldSize(subField.size)}>
                                <InputLabel className={classes.inputLabelArea}>
                                  {subField.label}
                                </InputLabel>
                                <DatePicker
                                  format="MM/dd/yyyy"
                                  id={`standard-${subField.name
                                    .split(" ")
                                    .join("")
                                    .toLowerCase()}`}
                                  label={subField.name}
                                  value={formData[subField.name] || null}
                                  placeholder={subField.name}
                                  onChange={(e) => {
                                    setFormData({
                                      ...formData,
                                      [subField.name]: e,
                                    });
                                    if (formDataErrors[subField.name]) {
                                      setFormDataErrors({
                                        ...formDataErrors,
                                        [subField.name]: false,
                                      });
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      fullWidth
                                      size="small"
                                      error={formDataErrors[field.name]}
                                      helperText={
                                        formDataErrors[field.name]
                                          ? "* required field"
                                          : ""
                                      }
                                    />
                                  )}
                                />
                              </Grid>
                            );
                          }
                        })}
                    </>
                  );
                } else if (field.type === "switch") {
                  if (
                    formData["Is there a host agent"] &&
                    field.name === "Create flyers"
                  ) {
                    return null;
                  } else {
                    return (
                      <Grid item xs={12}>
                        <Grid container spacing={3}>
                          <Grid item xs={getFieldSize(field.size)}>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={formData[field.name] || false}
                                  onChange={(e) => {
                                    setFormData({
                                      ...formData,
                                      [field.name]: e.target.checked,
                                    });
                                    if (formDataErrors[field.name]) {
                                      setFormDataErrors({
                                        ...formDataErrors,
                                        [field.name]: false,
                                      });
                                    }
                                  }}
                                  defaultChecked
                                />
                              }
                              label={field.name}
                            />
                          </Grid>

                          {formData[field.name] === true &&
                            field.subfields &&
                            field.subfields.map((subField) => {
                              if (
                                subField.type === "text" ||
                                subField.type === "number"
                              ) {
                                return (
                                  <Grid item xs={getFieldSize(subField.size)}>
                                    <TextField
                                      fullWidth
                                      className={classes.subField}
                                      id={`standard-${subField.name
                                        .split(" ")
                                        .join("")
                                        .toLowerCase()}`}
                                      // label={subField.name}
                                      value={formData[subField.name]}
                                      placeholder={subField.name}
                                      error={formDataErrors[subField.name]}
                                      helperText={
                                        formDataErrors[subField.name]
                                          ? "* required field"
                                          : ""
                                      }
                                      onChange={(e) => {
                                        setFormData({
                                          ...formData,
                                          [subField.name]: e.target.value,
                                        });
                                        if (formDataErrors[subField.name]) {
                                          setFormDataErrors({
                                            ...formDataErrors,
                                            [subField.name]: false,
                                          });
                                        }
                                      }}
                                      size="small"
                                    />
                                  </Grid>
                                );
                              } else if (subField.type === "textarea") {
                                return (
                                  <Grid item xs={getFieldSize(subField.size)}>
                                    <TextField
                                      aria-label="minimum height"
                                      multiline
                                      minRows={2}
                                      fullWidth
                                      maxRows={2}
                                      id={`standard-${subField.name
                                        .split(" ")
                                        .join("")
                                        .toLowerCase()}`}
                                      label={subField.name}
                                      value={formData[subField.name]}
                                      placeholder={subField.name}
                                      error={formDataErrors[subField.name]}
                                      helperText={
                                        formDataErrors[subField.name]
                                          ? "* required field"
                                          : ""
                                      }
                                      onChange={(e) => {
                                        setFormData({
                                          ...formData,
                                          [subField.name]: e.target.value,
                                        });
                                        if (formDataErrors[subField.name]) {
                                          setFormDataErrors({
                                            ...formDataErrors,
                                            [subField.name]: false,
                                          });
                                        }
                                      }}
                                      className={classes.textArea}
                                      inputProps={{
                                        step: 300, // 5 min
                                        style: {
                                          borderColor: formDataErrors[
                                            subField.name
                                          ]
                                            ? "#d32f2f"
                                            : "#000000DE",
                                        },
                                      }}
                                    />
                                  </Grid>
                                );
                              } else if (subField.type === "date") {
                                return (
                                  <Grid item xs={getFieldSize(subField.size)}>
                                    <DatePicker
                                      format="MM/dd/yyyy"
                                      id={`standard-${subField.name
                                        .split(" ")
                                        .join("")
                                        .toLowerCase()}`}
                                      label={subField.name}
                                      value={formData[subField.name] || null}
                                      placeholder={subField.name}
                                      onChange={(e) => {
                                        setFormData({
                                          ...formData,
                                          [subField.name]: e,
                                        });
                                        if (formDataErrors[subField.name]) {
                                          setFormDataErrors({
                                            ...formDataErrors,
                                            [subField.name]: false,
                                          });
                                        }
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          fullWidth
                                          error={formDataErrors[field.name]}
                                          helperText={
                                            formDataErrors[field.name]
                                              ? "* required field"
                                              : ""
                                          }
                                          size="small"
                                        />
                                      )}
                                    />
                                  </Grid>
                                );
                              } else if (subField.type === "dropdown") {
                                return (
                                  <Grid item xs={getFieldSize(subField.size)}>
                                    <FormControl fullWidth>
                                      {/* <InputLabel id="demo-simple-select-standard-label">
                                      {subField.name}
                                    </InputLabel> */}
                                      <Select
                                        className={
                                          formDataErrors[field.name]
                                            ? classes.fieldError
                                            : classes.field
                                        }
                                        size="small"
                                        fullWidth
                                        id={`standard-${subField.name
                                          .split(" ")
                                          .join("")
                                          .toLowerCase()}`}
                                        // label={subField.name}
                                        value={formData[subField.name]}
                                        placeholder={subField.name}
                                        error={formDataErrors[subField.name]}
                                        helperText={
                                          formDataErrors[subField.name]
                                            ? "* required field"
                                            : ""
                                        }
                                        onChange={(e) => {
                                          setFormData({
                                            ...formData,
                                            [subField.name]: e.target.value,
                                          });
                                          if (formDataErrors[subField.name]) {
                                            setFormDataErrors({
                                              ...formDataErrors,
                                              [subField.name]: false,
                                            });
                                          }
                                        }}
                                      >
                                        <MenuItem value="">
                                          <em>None</em>
                                        </MenuItem>
                                        {subField?.options?.map((it, idx) => (
                                          <MenuItem key={idx} value={it.value}>
                                            {it.label}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                );
                              } else if (subField.type === "time") {
                                return (
                                  <Grid item xs={getFieldSize(subField.size)}>
                                    <InputLabel id="demo-simple-select-standard-label">
                                      {subField.label}
                                    </InputLabel>
                                    <TextField
                                      id={subField.name}
                                      label={subField.name}
                                      value={formData[subField.name] || null}
                                      type="time"
                                      size="small"
                                      error={formDataErrors[subField.name]}
                                      helperText={
                                        formDataErrors[subField.name]
                                          ? "* required field"
                                          : ""
                                      }
                                      onChange={(e) => {
                                        setFormData({
                                          ...formData,
                                          [subField.name]: e.target.value,
                                        });
                                        if (formDataErrors[subField.name]) {
                                          setFormDataErrors({
                                            ...formDataErrors,
                                            [subField.name]: false,
                                          });
                                        }
                                      }}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      fullWidth
                                      inputProps={{
                                        step: 300, // 5 min
                                      }}
                                    />
                                  </Grid>
                                );
                              } else if (
                                subField.name === "Who is Hosting" ||
                                subField.type === "autocomplete"
                              ) {
                                return (
                                  <Grid item xs={getFieldSize(subField.size)}>
                                    <Autocomplete
                                      options={
                                        subField.name === "Who is Hosting"
                                          ? users
                                          : field.options
                                      }
                                      fullWidth
                                      variant="standard"
                                      id={`standard-${subField.name
                                        .split(" ")
                                        .join("")
                                        .toLowerCase()}`}
                                      label={subField.name}
                                      value={formData[subField.name]}
                                      onChange={(e, value) => {
                                        setFormData({
                                          ...formData,
                                          [subField.name]: value,
                                        });
                                        if (formDataErrors[subField.name]) {
                                          setFormDataErrors({
                                            ...formDataErrors,
                                            [subField.name]: false,
                                          });
                                        }
                                      }}
                                      renderInput={(params) => {
                                        return (
                                          <TextField
                                            {...params}
                                            fullWidth
                                            size="small"
                                            error={
                                              formDataErrors[subField.name]
                                            }
                                            label={subField.name}
                                            helperText={
                                              formDataErrors[subField.name]
                                                ? "* required field"
                                                : ""
                                            }
                                            InputProps={{
                                              ...params.InputProps,
                                              startAdornment:
                                                formData[subField.name] &&
                                                subField.name ===
                                                  "Who is Hosting" ? (
                                                  <Avatar
                                                    sx={{
                                                      width: "28px",
                                                      height: "28px",
                                                      fontSize: "12px",
                                                      ml: "10px",
                                                    }}
                                                    alt={
                                                      formData[subField.name]
                                                    }
                                                    src={
                                                      usersData.find(
                                                        (item) =>
                                                          item.full_name ===
                                                          formData[
                                                            subField.name
                                                          ]
                                                      )?.profile_images
                                                        ?.profile_img_thumbnail
                                                    } // Assuming `avatar_url` holds the image URL
                                                    className={classes.avatar}
                                                  >{`${usersData
                                                    .find(
                                                      (item) =>
                                                        item.full_name ===
                                                        formData[subField.name]
                                                    )
                                                    ?.first_name?.slice(
                                                      0,
                                                      1
                                                    )}${usersData
                                                    .find(
                                                      (item) =>
                                                        item.full_name ===
                                                        formData[subField.name]
                                                    )
                                                    ?.last_name?.slice(
                                                      0,
                                                      1
                                                    )}`}</Avatar>
                                                ) : null,
                                            }}
                                          />
                                        );
                                      }}
                                      renderOption={
                                        subField.name === "Who is Hosting"
                                          ? (props, option) => (
                                              <li {...props}>
                                                <Avatar
                                                  sx={{
                                                    width: "28px",
                                                    height: "28px",
                                                    fontSize: "12px",
                                                    mr: "10px",
                                                  }}
                                                  alt={option}
                                                  src={
                                                    usersData.find(
                                                      (item) =>
                                                        item.full_name ===
                                                        option
                                                    )?.profile_images
                                                      ?.profile_img_thumbnail
                                                  } // Assuming `avatar_url` holds the image URL
                                                  className={classes.avatar}
                                                >{`${option?.slice(
                                                  0,
                                                  1
                                                )}${option
                                                  ?.split(" ")[0]
                                                  ?.slice(0, 1)}`}</Avatar>
                                                {option}
                                              </li>
                                            )
                                          : null
                                      }
                                      freeSolo
                                    />
                                  </Grid>
                                );
                              }
                            })}
                        </Grid>
                      </Grid>
                    );
                  }
                } else if (field.type === "time") {
                  return (
                    <Grid item xs={getFieldSize(field.size)}>
                      <InputLabel className={classes.inputLabelArea}>
                        {field.label}
                      </InputLabel>
                      <TextField
                        id={field.name}
                        // label={field.name}
                        value={
                          formData[field.name]
                            ? convertTo24HourFormat(formData[field.name])
                            : null
                        }
                        type="time"
                        size="small"
                        error={formDataErrors[field.name]}
                        helperText={
                          formDataErrors[field.name] ? "* required field" : ""
                        }
                        onChange={(e) => {
                          const selectedTime = e.target.value;

                          // Splitting the time string to get hours and minutes
                          const [hours, minutes] = selectedTime.split(":");

                          // Creating a Date object to format the time
                          const date = new Date();
                          date.setHours(parseInt(hours));
                          date.setMinutes(parseInt(minutes));

                          // Formatting the time in 12-hour format using Intl.DateTimeFormat
                          const formattedTime = date.toLocaleString("en-US", {
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                          });

                          setFormData({
                            ...formData,
                            [field.name]: formattedTime,
                          });
                          if (formDataErrors[field.name]) {
                            setFormDataErrors({
                              ...formDataErrors,
                              [field.name]: false,
                            });
                          }
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                        fullWidth
                        autoComplete="off"
                      />
                    </Grid>
                  );
                }
              })}
            {formData?.task_type_id === 1 ? (
              <Grid item xs={12}>
                <InputLabel className={classes.inputLabelArea}>
                  Additional Disclosures/Clauses
                </InputLabel>
                <Autocomplete
                  options={AdditionalClauses}
                  fullWidth
                  clearOnBlur
                  clearOnEscape
                  value={clauseValue}
                  onChange={(e, value) => {
                    setClauseValue(value);
                    if (
                      !selectedClauses?.find((val) => val._id === value?._id)
                    ) {
                      setSelectedClauses((pre) => [...pre, value]);
                    }
                    setTimeout(() => {
                      setClauseValue(null);
                    }, 200);
                  }}
                  getOptionLabel={(option) => option.title}
                  renderInput={(params) => {
                    return <TextField {...params} fullWidth size={"small"} />;
                  }}
                  freeSolo
                />
                <br />
                {selectedClauses?.length
                  ? selectedClauses?.map((item, index) => (
                      <Box key={index} className={classes.clauseItem}>
                        <Box className={classes.clauseHeader}>
                          <Typography className={classes.clauseHeading}>
                            {item?.title}
                          </Typography>
                          <IconButton
                            onClick={() => {
                              setSelectedClauses((pre) => {
                                return [
                                  ...pre?.filter((it) => it?._id !== item?._id),
                                ];
                              });
                            }}
                          >
                            <CloseRoundedIcon />
                          </IconButton>
                        </Box>
                        {item?.fields?.length ? (
                          <Grid
                            container
                            spacing={1}
                            className={classes.fieldsArea}
                          >
                            {item?.fields?.map((field, index) => {
                              switch (field?.type) {
                                case "checkbox":
                                  return (
                                    <Grid
                                      key={index}
                                      item
                                      xs={field.cols || 12}
                                    >
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={field.checked}
                                            onChange={(e) =>
                                              handleUpdateClause(
                                                e.target.checked,
                                                item,
                                                field
                                              )
                                            }
                                            sx={{ mt: "-2px" }}
                                          />
                                        }
                                        label={field?.label}
                                        sx={{ height: "30px" }}
                                      />
                                    </Grid>
                                  );
                                case "textField":
                                  return (
                                    <Grid
                                      key={index}
                                      item
                                      xs={field.cols || 12}
                                    >
                                      {field?.topLabel ? (
                                        <Typography
                                          sx={{
                                            width: field.labelWidth,
                                            mb: "6px",
                                          }}
                                        >
                                          {field?.topLabel}
                                        </Typography>
                                      ) : null}
                                      <Box className={classes.fieldsTextItem}>
                                        {field?.label ? (
                                          <Typography
                                            sx={{ width: field.labelWidth }}
                                          >
                                            {field?.label}
                                          </Typography>
                                        ) : null}

                                        {field?.id === "specify-other" &&
                                        item?.fields?.find(
                                          (it) => it.label === "Other"
                                        )?.checked === false ? null : (
                                          <TextField
                                            key={`${index}`}
                                            type={field?.fieldType || "text"}
                                            placeholder={field?.placeholder}
                                            value={field.value}
                                            size="small"
                                            sx={{ width: field.width }}
                                            onChange={(e) =>
                                              handleUpdateClause(
                                                e.target.value,
                                                item,
                                                field
                                              )
                                            }
                                            InputProps={{
                                              startAdornment:
                                                field?.startAdornment ? (
                                                  <InputAdornment position="start">
                                                    $
                                                  </InputAdornment>
                                                ) : null,
                                            }}
                                            fullWidth
                                          />
                                        )}

                                        <Typography>
                                          {field?.endLabel}
                                        </Typography>
                                      </Box>
                                    </Grid>
                                  );
                                case "datePicker":
                                  return (
                                    <Grid
                                      key={index}
                                      item
                                      xs={field.cols || 12}
                                    >
                                      {field.label ? (
                                        <InputLabel
                                          className={classes.inputLabelArea}
                                        >
                                          {field.label}
                                        </InputLabel>
                                      ) : null}

                                      <DatePicker
                                        format="MM/dd/yyyy"
                                        value={field.value}
                                        onChange={(value) =>
                                          handleUpdateClause(value, item, field)
                                        }
                                        renderInput={(params) => {
                                          return (
                                            <TextField
                                              {...params}
                                              fullWidth
                                              size="small"
                                            />
                                          );
                                        }}
                                        size="small"
                                      />
                                    </Grid>
                                  );
                                case "timePicker":
                                  return (
                                    <Grid
                                      key={index}
                                      item
                                      xs={field.cols || 12}
                                    >
                                      <Box className={classes.fieldsTextItem}>
                                        <TimePicker
                                          onChange={(value) =>
                                            handleUpdateClause(
                                              value,
                                              item,
                                              field
                                            )
                                          }
                                          value={field.value}
                                          renderInput={(params) => {
                                            return (
                                              <TextField
                                                {...params}
                                                fullWidth
                                                size="small"
                                              />
                                            );
                                          }}
                                          sx={{ flex: 1 }}
                                          size="small"
                                        />
                                        <InputLabel
                                          className={classes.inputLabelArea}
                                          sx={{ width: "30px", mt: "5px" }}
                                        >
                                          {field.label}
                                        </InputLabel>
                                      </Box>
                                    </Grid>
                                  );
                                case "attachments":
                                  return (
                                    <Grid
                                      key={index}
                                      item
                                      xs={field.cols || 12}
                                    >
                                      {field?.uploadedAttachments?.length ? (
                                        <Box
                                          className={classes.uploadedSection}
                                        >
                                          {field?.uploadedAttachments?.map(
                                            (attachment, idx) => (
                                              <Box
                                                className={
                                                  classes.attachmentItem
                                                }
                                              >
                                                <img
                                                  src={attachment?.thumbnail}
                                                  alt={`attachment ${idx}`}
                                                />
                                                <Box
                                                  className={
                                                    classes.attachmentICon
                                                  }
                                                  onClick={() =>
                                                    handleRemoveAttachment(
                                                      item,
                                                      field,
                                                      idx
                                                    )
                                                  }
                                                >
                                                  <CloseRoundedIcon
                                                    sx={{ fontSize: "16px" }}
                                                  />
                                                </Box>
                                              </Box>
                                            )
                                          )}
                                        </Box>
                                      ) : null}
                                      {field?.uploadedAttachments?.length <
                                      4 ? (
                                        <Button
                                          variant="outlined"
                                          color="inherit"
                                          sx={{ textTransform: "capitalize" }}
                                          startIcon={
                                            <FileUploadRoundedIcon
                                              fontSize="large"
                                              sx={{
                                                color: "#757575",
                                              }}
                                            />
                                          }
                                          component="label"
                                        >
                                          {field?.buttonText}
                                          <input
                                            onChange={(e) =>
                                              handleFileChange(
                                                e.target.files,
                                                item,
                                                field
                                              )
                                            }
                                            hidden
                                            accept=".pdf, .jpeg, .jpg, .png"
                                            multiple
                                            type="file"
                                          />
                                        </Button>
                                      ) : null}
                                    </Grid>
                                  );
                                default:
                                  return null;
                              }
                            })}
                          </Grid>
                        ) : null}
                      </Box>
                    ))
                  : null}
              </Grid>
            ) : null}
          </Grid>
        </LocalizationProvider>
      </DialogContent>

      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </Box>
  );
}

export default withStyles(styleSheet, { name: "CreateProjectStyle" })(
  CreateProject
);
