import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { DialogTitle, TextField, Box } from "@mui/material";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import LoadingButton from "@mui/lab/LoadingButton";
import ResponseAlert from "../../../components/responseAlert";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useSelector, useDispatch } from "react-redux";
import { updateListing } from "../../../redux/listings/listingDetail/updateListing";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
function ListingExpiryDate(props) {
  let { open, setOpen, classes, ListingData } = props;
  const [expiry, setExpiry] = useState("");
  const preDate = moment(ListingData?.expire_date).format();
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const update = useSelector((state) => state.listings.listingDetail.update);
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
  };
  const handleSuccess = () => {
    setErrorAlert({
      errorMsg: `You have successfully ${
        ListingData?.expire_date ? "Updated" : "Saved"
      }`,
      errorType: "success",
      isOpen: true,
    });
    handleClose();
  };
  const handleError = (error) => {
    setErrorAlert({
      errorMsg: JSON.stringify(error),
      errorType: "success",
      isOpen: true,
    });
  };

  const handleSubmit = () => {
    if (expiry) {
      dispatch(
        updateListing({
          id: ListingData.id,
          handleSuccess,
          handleError,
          schema: {
            expire_date: moment(expiry).format(),
          },
        })
      );
    } else {
      setErrorAlert({
        errorMsg: "Please update first",
        errorType: "warning",
        isOpen: true,
      });
    }
  };

  return (
    <React.Fragment>
      <Dialog
        disablePortal
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle className={classes.modalHeader} id="alert-dialog-title">
          {ListingData?.expire_date ? "Update" : "Add"} Expire Date
          <Box className={classes.modalHeaderRight}>
            <LoadingButton
              onClick={() => handleSubmit()}
              className={classes.saveModalButton}
              color="inherit"
              autoFocus
              disabled={update.isLoading}
              loading={update.isLoading}
              loadingPosition="start"
            >
              {ListingData?.expire_date ? "Update" : "Save"}
            </LoadingButton>
            <Button
              onClick={() => handleClose()}
              sx={{ minWidth: "25px" }}
              variant="text"
              color="inherit"
            >
              <CloseRoundedIcon />
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center !important",
              gap: "10px",
              mt: "20px",
              mb: "15px",
              width: "280px",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={expiry || preDate}
                onChange={(date) => setExpiry(date)}
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}
              />
            </LocalizationProvider>
          </Box>
        </DialogContent>
      </Dialog>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() =>
          setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })
        }
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </React.Fragment>
  );
}

export default withStyles(styleSheet, { name: "ListingExpiryDateStyle" })(
  ListingExpiryDate
);
