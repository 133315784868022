import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Stack,
  CardMedia,
  Menu,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListItem,
  Backdrop,
  CircularProgress,
  Box
} from "@mui/material";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import moment from 'moment'
import { styleSheet } from "./style";
import { uploadImageData } from "../../../../../utils/uploadImage";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { withStyles } from "@mui/styles";
import LockIcon from "@mui/icons-material/Lock";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ResponseAlert from "../../../../../components/responseAlert";
import { useSelector } from "react-redux";
import { isNull } from "util";

const bigAvatar = "https://method-platform.s3.amazonaws.com/defaultNoImage.png";
const HandShotAndDetails = ({ classes }) => {
  const [isEditAble, setIsEditAble] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [headshotDetail, setHeadshotDetail] = useState({
    isolated_headshot: null,
    isolated_headshot_added_by: null,
    isolated_headshot_added_date: null,
    logo: '',
    logo_added_by:'',
    logo_added_date:'',
    profile_img:'',
    profile_img_added_by:"",
    profile_img_added_date:''
  });
  const [uploadInfo, setUploadInfo] = useState({
    text: "",
    type: "",
  });
  const [errorAlert, setErrorAlert] = useState({
    errorMsg: "",
    errorType: "",
    isOpen: false,
  });
  const isMenuOpen = Boolean(anchorEl);
  const agentRosterProfile = useSelector((item) => item.agentRoster.agentRosterProfile);
  const { profile } = agentRosterProfile;
 console.log(profile,"profile")
  useEffect(()=>{
  if(profile?.profile_images){
    setHeadshotDetail({...headshotDetail,...profile?.profile_images})
  }
  },[profile?.profile_images])

  const handleFileChange = async (e, type) => {
    const Data = new FormData();
    Data.append("img", e.target.files[0]);
    Data.append("name", type);
    setLoading(true);
    const result = await uploadImageData(`${process.env.REACT_APP_BASE_URL}api/users/upload-user-images/${profile.id}`, Data, "PUT");
    if (result.status === 200) {
      console.log(result, "");
      setHeadshotDetail({...headshotDetail,...result?.data?.profile_images})
      setErrorAlert({
        errorMsg: "Uploaded successfully",
        errorType: "success",
        isOpen: true,
      });
      setLoading(false);
    } else {
      setErrorAlert({
        errorMsg: result.message || "APi not found ",
        errorType: "error",
        isOpen: true,
      });
      setLoading(false);
    }
  };
const handleDelete=async(type)=>{
  setAnchorEl(isNull)
  setHeadshotDetail({...headshotDetail,[type]:null})
  await fetch(`${process.env.REACT_APP_BASE_URL}api/users/delete-user-images/${profile.id}`, {
    method: 'Put',
    headers: {
      Authorization: 'Bearer ' + localStorage.token,
    },
    body: JSON.stringify({name:type}),
  })
    .then((res) => res.json())
    .then((data) => {
      console.log(data)
    })
    .catch((error) => console.log('GET_DATA_ERROR: ', error));
}

  return (
    <Card variant="outlined" sx={{ backgroundColor: "white" }} className={classes.pageRoot}>
      <CardHeader
        classes={{ title: classes.pageHeading }}
        title="Headshots and Logos"
        action={
          <div>
            <IconButton onClick={() => setIsEditAble(!isEditAble)} aria-label="locked">
              {isEditAble ? <LockOpenIcon sx={{ color: "rgba(0, 0, 0, 0.38)" }} /> : <LockIcon sx={{ color: "rgba(0, 0, 0, 0.38)" }} />}
            </IconButton>
          </div>
        }
      />
      <CardContent>
        <Grid container direction="row" spacing={2}>
          <Grid item lg={4} xs={12}>
            <Card sx={{ padding: "15px" }}>
              {
             headshotDetail.logo?
              <Stack direction="row" justifyContent="center" alignItems="center">
                <CardMedia component="img" height="180" sx={{ objectFit: "contain", width: "200px" }} image={headshotDetail?.logo?headshotDetail?.logo:bigAvatar} alt="Headshot Logo" />
                <CardHeader
                  classes={{
                    title: classes.agentProfileDetail,
                    subheader: classes.agentProfileDetail,
                  }}
                  action={
                    <IconButton
                      sx={{ marginTop: "40px" }}
                      aria-label="settings"
                      onClick={(e) => {
                        if (isEditAble) {
                          setAnchorEl(e.target);
                          setUploadInfo({
                            text: "Upload Logo",
                            type: "logo",
                          });
                        }
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  }
                  title={
                    <div>
                      Logo <b>(Active)</b>
                    </div>
                  }
                  subheader={headshotDetail.logo_added_date?`Added ${moment(headshotDetail.logo_added_date).format('l')} by ${headshotDetail.logo_added_by}`:'Upload Logo'}
                  />
              </Stack>:
             <Box sx={{pointerEvents:isEditAble?'':'none'}} className={classes.UploadCustomerButton} component="label">
               <input onChange={(e) =>  handleFileChange(e, "logo")} hidden accept=".png, .jpg" type="file" />
              <Box component={"center"}>
                <AddRoundedIcon sx={{fontSize:'120px',color:'rgba(0, 0, 0, 0.3)'}} /><br />
                Add Logo
              </Box>
             </Box>
             }
            </Card>
          </Grid>
          <Grid item lg={4} xs={12}>
            <Card sx={{ padding: "15px" }}>
             {
              headshotDetail.profile_img?

              <Stack direction="row" justifyContent="center" alignItems="center">
                <CardMedia component="img" height="180" sx={{ objectFit: "contain", width: "200px" }} image={headshotDetail?.profile_img?headshotDetail?.profile_img:bigAvatar} alt="Paella dish" />
                <CardHeader
                  classes={{
                    title: classes.agentProfileDetail,
                    subheader: classes.agentProfileDetail,
                  }}
                  action={
                    <IconButton
                      sx={{ marginTop: "40px" }}
                      onClick={(e) => {
                        if (isEditAble) {
                          setAnchorEl(e.target);
                          setUploadInfo({
                            text: "Upload  Profile image",
                            type: "profile_img",
                          });
                        }
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  }
                  title={
                    <div>
                      Profile<b>(Active)</b>
                    </div>
                  }
                  subheader={headshotDetail.profile_img_added_date?`Added ${moment(headshotDetail.profile_img_added_date).format('l')} by ${headshotDetail.profile_img_added_by}`:'Upload Profile image'}
                />
              </Stack>: <Box sx={{pointerEvents:isEditAble?'':'none'}} className={classes.UploadCustomerButton} component="label">
               <input onChange={(e) =>  handleFileChange(e, "profile_img")} hidden accept=".png, .jpg" type="file" />
              <Box component={"center"}>
                <AddRoundedIcon sx={{fontSize:'120px',color:'rgba(0, 0, 0, 0.3)'}} /><br />
                Add Profile image
              </Box>
             </Box>
                           }

            </Card>
          </Grid>
          <Grid item lg={4} xs={12}>
            <Card sx={{ padding: "15px" }}>
              {
                headshotDetail.isolated_headshot?
              <Stack direction="row" justifyContent="center" alignItems="center">
                <CardMedia component="img" height="180" sx={{ objectFit: "contain", width: "200px" }} image={headshotDetail?.isolated_headshot?headshotDetail?.isolated_headshot:bigAvatar} alt="Paella dish" />
                <CardHeader
                  classes={{
                    title: classes.agentProfileDetail,
                    subheader: classes.agentProfileDetail,
                  }}
                  action={
                    <IconButton
                      sx={{ marginTop: "40px" }}
                      onClick={(e) => {
                        if (isEditAble) {
                          setAnchorEl(e.target);
                          setUploadInfo({
                            text: "Upload Isolated head shot",
                            type: "isolated_headshot",
                          });
                        }
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  }
                  title={
                    <div>
                      Isolated Headshot <b>(Active)</b>
                    </div>
                  }
                  subheader={headshotDetail.isolated_headshot_added_date?`Added ${moment(headshotDetail.isolated_headshot_added_date).format('l')} by ${headshotDetail.isolated_headshot_added_by}`:'Upload Isolated Headshot'}
                />
              </Stack>:
          <Box className={classes.UploadCustomerButton} sx={{pointerEvents:isEditAble?'':'none'}} component="label">
          <input onChange={(e) =>  handleFileChange(e, "isolated_headshot")} hidden accept=".png, .jpg" type="file" />
         <Box component={"center"}>
           <AddRoundedIcon sx={{fontSize:'120px',color:'rgba(0, 0, 0, 0.3)'}} /><br />
           Add Isolated head shot
         </Box>
        </Box>
                            }
            </Card>
          </Grid>
        </Grid>
      </CardContent>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={isMenuOpen}
        onClose={() => setAnchorEl(false)}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <List sx={{ width: "100%", maxWidth: 360 }} disablePadding>
        <ListItem disablePadding>
            <ListItemButton onClick={() => handleDelete(uploadInfo.type)} component="label">
              <ListItemText primary={"Delete"} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component="label">
              <ListItemText primary={"Replace"} />
              <input onChange={(e) => handleFileChange(e, uploadInfo.type)} hidden accept=".png, .jpg" type="file" />
            </ListItemButton>
          </ListItem>
        </List>
      </Menu>
      <Backdrop color="inherit" invisible={true} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ResponseAlert
        open={errorAlert.isOpen}
        setOpen={() => setErrorAlert({ errorMsg: "", errorType: "", isOpen: false })}
        alertType={errorAlert.errorType}
        alertMessage={errorAlert.errorMsg}
      />
    </Card>
  );
};

export default withStyles(styleSheet, {
  name: "agentRosterDetailsHandShotStyle",
})(HandShotAndDetails);
