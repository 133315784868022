import React from "react";

import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  Card,
  CardContent,
  MenuItem,
  Typography,
} from "@mui/material";
import StatusComponent from "../StatusComponent";
import AvatarComponent from "../AvatarComponent";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import ChatBubbleOutlineRoundedIcon from "@mui/icons-material/ChatBubbleOutlineRounded";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import { useSelector } from "react-redux";
import { assignTask } from "../../../redux/projects/tasks";
import { useDispatch } from "react-redux";
import OutsideClickHandler from "react-outside-click-handler";
import { deptColorsMapping, deptTextColorsMapping } from "../helpers";
import moment from "moment";

function droppingAnimationStyle(style, snapshot) {
  if (!snapshot.isDropAnimating) {
    return style;
  }
  const { moveTo, curve, duration } = snapshot.dropAnimation;
  // move to the right spot
  const translate = `translate(${moveTo.x}px, ${moveTo.y}px)`;

  // patching the existing style
  return {
    ...style,
    transform: `${translate}`,
    transition: `all ${curve} ${duration + 1}s`,
  };
}

const useStyles = makeStyles((theme) => ({
  card: {
    padding: "0px!important",
    marginBottom: "10px",
    width: "auto",
    boxShadow: "0px 0px 0px 1px #E0E0E0",
  },
  cardContent: {
    margin: "0px!important",
    padding: "5px!important",
  },
  cardTitle1: {
    fontSize: "14px!important",
    fontWeight: "500!important",
  },
  cardTitle2: {
    fontSize: "14px!important",
    fontWeight: "500!important",
  },
  details: {
    display: "flex",
    margin: "5px 0px",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "5px",
    width: "250px",
  },
  innerDetails: {
    display: "flex",
    gap: "6px",
    alignItems: "center",
    flexWrap: "wrap",
  },
  innerDetailsSection: {
    display: "flex",
    alignItems: "center",
    gap: "3px",
    flexWrap: "wrap",
    fontSize: "14px !important",
  },
  taskMemberBox: {
    alignItems: "flex-start",
    padding: "8px 0px",
    margin: "0px",
    position: "fixed",
    background: "#FFFFFF",
    boxShadow:
      "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    height: "400px",
    overflowY: "scroll",
    zIndex: "9999",
    "&::-webkit-scrollbar": {
      width: 5,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#ddd",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#0B0909",
      borderRadius: 1,
    },
  },
  agent: {
    fontSize: "9px!important",
    fontWeight: "700!important",
    padding: "0!important",
    margin: "0!important",
    fontFamily: "AvenirNext !important",
  },
  created: {
    fontSize: "9px!important",
    fontWeight: "400!important",
    padding: "0!important",
    margin: "0!important",
    color: "rgba(0, 0, 0, 0.87)",
    fontFamily: "AvenirNext !important",
  },
}));

const TaskCard = ({
  taskDetails,
  handleOpenTask,
  openTeamMemberDropdown,
  teamMemberDropdown,
  provided,
  snapshot,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [teamMemberBoxDistance, setTeamMemberBoxDistance] = React.useState(0);
  const [currentTask, setCurrentTask] = React.useState(taskDetails);

  const departments = useSelector((state) => state.project.departments);
  const users = useSelector((state) =>
    state.users.storedListOfUsers.list ? state.users.storedListOfUsers.list : []
  );

  const assignTaskMember = (user) => {
    setCurrentTask((cTask) => {
      let updated = {
        ...cTask,
        teamMember: {
          title: user.full_name,
          src: user?.profile_images?.profile_img,
        },
      };
      return updated;
    });
    dispatch(assignTask({ task_id: currentTask.id, user_id: user.id, user }));
    openTeamMemberDropdown({});
  };

  React.useEffect(() => {
    setCurrentTask({
      ...taskDetails,
      department: departments.find(
        (dept) => dept.id === taskDetails.department_id
      ),
    });
  }, [taskDetails]);

  return (
    <Card
      className={classes.card}
      variant="outlined"
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      isDragging={snapshot.isDragging && !snapshot.isDropAnimating}
      style={droppingAnimationStyle(provided.draggableProps.style, snapshot)}
    >
      <CardContent
        onClick={() => handleOpenTask(currentTask.id)}
        className={classes.cardContent}
      >
        <Box
          sx={{
            display: "flex",
            gap: "20px",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "45%",
              borderRadius: "2px",
              height: "12px",
              padding: "0px 5px",
              backgroundColor: deptColorsMapping(currentTask.department?.name),
            }}
          >
            <Typography
              sx={{
                color: deptTextColorsMapping(currentTask.department?.name),
                fontSize: "8px",
                fontWeight: "600",
              }}
            >
              {currentTask.department?.name}
            </Typography>
          </Box>
        </Box>

        <Typography className={classes.cardTitle1}>
          {currentTask.task.name}
        </Typography>

        <Typography className={classes.cardTitle2}>
          {currentTask.task.title === "undefined "
            ? ""
            : currentTask.task.title || ""}
        </Typography>

        <Box className={classes.details}>
          <StatusComponent
            sx={{ height: "25px", width: "auto" }}
            status={currentTask.status}
          />

          <Box className={classes.innerDetails}>
            <Typography
              sx={{ color: currentTask.dueIn[0] === "-" ? "#C62828" : "#000" }}
              className={classes.innerDetailsSection}
            >
              {currentTask.dueIn[0] === "-" ? (
                <AccessTimeRoundedIcon fontSize="small" sx={{ color: "red" }} />
              ) : (
                <AccessTimeRoundedIcon fontSize="small" />
              )}

              {currentTask.dueIn}
            </Typography>

            <Typography className={classes.innerDetailsSection}>
              <ChatBubbleOutlineRoundedIcon fontSize="small" />
              {currentTask.comments.length}
            </Typography>

            <Typography className={classes.innerDetailsSection}>
              <AttachFileIcon fontSize="small" />
              {currentTask.attachments.length}
            </Typography>

            {currentTask.teamMember && (
              <AvatarComponent
                alt="Team Member"
                src={currentTask.teamMember.src}
                title={currentTask.teamMember.title}
                nameInitial={currentTask.teamMember.title}
                sx={{ width: "30px", height: "30px" }}
              />
            )}

            {!currentTask.teamMember && (
              <Button
                id={`card-${currentTask.id}`}
                onClick={(e) => {
                  e.stopPropagation();
                  var element = document.getElementById(
                    `card-${currentTask.id}`
                  );
                  var domRect = element.getBoundingClientRect();

                  let position;
                  if (window.innerHeight - domRect.bottom < 400) {
                    position = domRect.top - 400;
                  } else {
                    position = domRect.top;
                  }

                  setTeamMemberBoxDistance(position);
                  openTeamMemberDropdown({ [currentTask.id]: true });
                }}
                sx={{ padding: "0", margin: "0", minWidth: "0" }}
              >
                <AddCircleOutlineRoundedIcon
                  sx={{ color: "grey" }}
                  fontSize="small"
                />
              </Button>
            )}

            {teamMemberDropdown[currentTask.id] && (
              <OutsideClickHandler
                onOutsideClick={() => {
                  openTeamMemberDropdown({});
                }}
              >
                <Box
                  className={classes.taskMemberBox}
                  style={{
                    top: `${teamMemberBoxDistance}px`,
                    overscrollBehavior: "contain",
                  }}
                >
                  {users
                    .filter((user) => user.is_agent === false)
                    .map((user) => (
                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation();
                          assignTaskMember(user);
                        }}
                        value={user.id}
                      >
                        <AvatarComponent
                          sx={{ marginRight: "14px" }}
                          src={
                            user.profile_images
                              ? user.profile_images?.profile_img_thumbnail
                              : null
                          }
                          nameInitial={user.full_name}
                        />
                        {user.full_name}
                      </MenuItem>
                    ))}
                </Box>
              </OutsideClickHandler>
            )}
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "6px 0px 0px 6px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "4px",
              alignItems: "center",
              width: "59%",
            }}
          >
            <AvatarComponent
              src={currentTask.agent.image}
              nameInitial={currentTask.agent.full_name}
              sx={{
                marginLeft: "-5px",
                width: "17px",
                height: "17px",
                borderRadius: "5px",
                fontSize: "9px",
                fontWeight: "500",
                backgroundColor: deptColorsMapping(
                  currentTask.department?.name
                ),
              }}
            />
            <Typography className={classes.agent}>
              {currentTask.agent.full_name}
            </Typography>
          </Box>

          <Box sx={{ width: "57%" }}>
            <Typography className={classes.created}>
              Created{" "}
              {moment(currentTask.created || new Date()).format("MM/DD/YY")} @{" "}
              {moment(currentTask.created || new Date()).format("H:MMa")}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default TaskCard;
