import React, { useState } from "react";
import { Container, Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import AgentsTable from "../common/Table/AgentsTable";
import { styleSheet } from "./style.js";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getAgentRosterList } from "../../../redux/agents/agentRoster/getAgentRosterList";
import AgentsSubHeader from "./subHeader.js";

const AgentsListing = (props) => {
  const { classes } = props;
  const [search, setSearch] = useState(false);
  const dispatch = useDispatch();

  const [filters, setFilters] = useState({
    only_agents: true,
    pageSize: 500,
    pageNumber: 1,
    search: "",
  });
  const data = useSelector((item) => item.agentRoster.agentRosterList);
  const { list, totalAgents, loading } = data;

  useEffect(() => {
    dispatch(getAgentRosterList(filters));
  }, [filters]);

  useEffect(() => {
    if (search !== false) {
      const timeOutId = setTimeout(
        () => setFilters({ ...filters, search }),
        500
      );
      return () => clearTimeout(timeOutId);
    }
  }, [search]);
  return (
    <>
      <AgentsSubHeader search={search} setSearch={setSearch} {...props} />
      <Container maxWidth="lg">
        <div className={classes.pageRoot}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <AgentsTable
                data={list || []}
                setFilters={setFilters}
                filters={filters}
                loading={loading}
                totalAgents={totalAgents || 0}
                search={search}
              />
            </Grid>
          </Grid>
        </div>
      </Container>
    </>
  );
};

export default withStyles(styleSheet, { name: "agentRosterStyle" })(
  AgentsListing
);
