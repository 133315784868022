import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import { withStyles } from "@mui/styles";
import { TabContext } from "@mui/lab";
import { styleSheet } from "./style.js";
import InputBase from "@mui/material/InputBase";

import CreateNewFolderRoundedIcon from "@mui/icons-material/CreateNewFolderRounded";
import { Box, Tabs, Tab, Card, Button } from "@mui/material";
import AddUserModal from "../agentDetails/modals/addUser";
import TabMenu from "./TabMenu";
import { styled, alpha } from "@mui/material/styles";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(0),
    width: "auto",
  },
  height: "36px",
  border: "1px solid rgba(0, 0, 0, 0.23)",
  marginLeft: 0,
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  height: "35px",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(0)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    "&::placeholder": {
      color: "rgba(0, 0, 0, 0.6) !important",
    },
    color: "rgba(0, 0, 0, 0.6)",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const AgentsSubHeader = (props) => {
  const { classes, disableAdd, setSearch } = props;
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const makeDropDown = useMediaQuery("(max-width:1024px)");

  return (
    <>
      <Card
        variant="outlined"
        sx={{ position: "sticky", top: "64px", zIndex: "100" }}
      >
        {" "}
        <Box className={classes.TopUsersBarArea}>
          <Box className={classes.TopUsersBarAreaLeft}>
            <Box>
              <TabContext>
                <Box className={classes.UsersTabsUI}>
                  {!makeDropDown && (
                    <Tabs
                      value={location.pathname}
                      TabIndicatorProps={{
                        sx: { background: "black", color: "black" },
                      }}
                      sx={{
                        display: makeDropDown ? "none" : "",
                        "& .Mui-selected": {
                          color: "#0B0909 !important",
                        },
                        "& .MuiTab-root": {
                          fontSize: "13px !important",
                          minHeight: "40px !important",
                        },
                        "& .MuiTabs-root": {
                          minHeight: "40px  !important",
                        },
                        "& .MuiTabs-scroller": {
                          height: "36px  !important",
                        },
                      }}
                    >
                      <Tab
                        label="Agent Roster"
                        value={`/agent_roster`}
                        to={`/agent_roster`}
                        component={Link}
                        sx={{ padding: "0px 10px" }}
                      />
                      <Tab
                        label="Users"
                        value={`/users`}
                        to={`/users`}
                        component={Link}
                        sx={{ padding: "0px 10px" }}
                      />
                      <Tab
                        label="Lenders"
                        value={`/users/lenders`}
                        to={`/users/lenders`}
                        component={Link}
                        sx={{ padding: "0px 10px" }}
                      />
                      <Tab
                        label="Teams"
                        value={`/teams`}
                        to={`/teams`}
                        component={Link}
                        sx={{ padding: "0px 10px" }}
                      />
                      <Tab
                        label="Map"
                        value={`/agent_roster/map`}
                        to={`/agent_roster/map`}
                        component={Link}
                        sx={{ padding: "0px 10px" }}
                      />
                    </Tabs>
                  )}
                  {makeDropDown && (
                    <Tabs
                      value={location.pathname}
                      TabIndicatorProps={{
                        sx: { background: "black", color: "black" },
                      }}
                      variant="scrollable"
                      aria-label="Users-tabs"
                      sx={{ justifyContent: "space-around" }}
                    >
                      <TabMenu />
                    </Tabs>
                  )}
                </Box>
              </TabContext>{" "}
            </Box>{" "}
          </Box>
          <Box className={classes.TopUsersBarAreaLeft}>
            {" "}
            {disableAdd ? null : (
              <Button
                onClick={() => setOpen(true)}
                className={classes.addTransactionButton}
                variant="contained"
                startIcon={<CreateNewFolderRoundedIcon />}
              >
                Add User
              </Button>
            )}
            <Search>
              <StyledInputBase
                onChange={(e) => {
                  if (setSearch) {
                    setSearch(e.target.value);
                  }
                }}
                placeholder={disableAdd ? "Search Agent" : "Search Users"}
                inputProps={{ "aria-label": "search" }}
              />
            </Search>
          </Box>
        </Box>
      </Card>
      <AddUserModal open={open} setOpen={setOpen} />
    </>
  );
};

export default withStyles(styleSheet, { name: "AgentsSubHeaderStyle" })(
  AgentsSubHeader
);
