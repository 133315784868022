import { createSlice } from "@reduxjs/toolkit";
import {
  changeKanbanView,
  getDepartmentList,
  getDepartmentProjectList,
  resetList,
  saveProjectTaskMessages,
  saveProjectTaskPublicComments,
  setSearchTextField,
  setSelectedDepartmentID,
  setSelectedOpenHouseTab,
  setSelectedTab,
} from "./common";
import {
  changeProjectStatus,
  getKanbanProjectList,
  getOpenHouseProjectList,
  getProject,
  getProjectList,
  getProjectModalFields,
  getProjectTasks,
  setProjectCurrentPageNo,
  setProjectFilterStatus,
  setProjectModalFields,
  getProjectStats,
  getDraftProjects,
  deleteDraftProject,
  createProject,
} from "./projects";
import {
  assignTask,
  changeDeliverableLoading,
  changeTaskStatus,
  getKanbanTaskList,
  getTask,
  getTaskHistory,
  getTaskList,
  setTaskCurrentPageNo,
  setTaskFilterStatus,
  unassignTask,
  uploadDeliverable,
  getTasksStats,
} from "./tasks";

export const taskSortOrder = [
  "correction",
  "working",
  "paused",
  "queued",
  "review",
  "submitted",
  "approved",
  "future",
  "onhold",
  "completed",
  "canceled",
];

export const projectsSortOrder = [
  "queued",
  "pending",
  "ready_to_complete",
  "future",
  "review",
  "submitted",
  "approved",
  "onhold",
  "completed",
  "paused",
  "canceled",
];

const handleSortProjects = (projects) => {
  if (projects?.length) {
    return projects.sort((a, b) => {
      return (
        projectsSortOrder.indexOf(a.status) -
        projectsSortOrder.indexOf(b.status)
      );
    });
  }
  return [];
};

const handleSortTasks = (tasks) => {
  if (tasks?.length) {
    return tasks.sort((a, b) => {
      return taskSortOrder.indexOf(a.status) - taskSortOrder.indexOf(b.status);
    });
  }
  return [];
};

const handleGetValue = (data, param) => {
  return data?.find((ite) => ite.data_field.name === param)?.value;
};
const handleMergeTheSameOpenHouseData = (projectData) => {
  /* groupBy */
  let groupDataByListing = projectData.reduce((group, product) => {
    const { listing_id } = product;
    group[listing_id] = group[listing_id] ?? [];
    group[listing_id].push(product);
    return group;
  }, {});
  let nestedArrayProjects = Object.values(groupDataByListing);
  let newFilteredData = [];
  nestedArrayProjects.map((item) => {
    if (item?.length === 1) {
      newFilteredData.push(item[0]);
    } else {
      item.map((it, i, self) => {
        let date = handleGetValue(it.project_other_data, "Date of Open House");
        let st = handleGetValue(it.project_other_data, "Start Time");
        let et = handleGetValue(it.project_other_data, "End Time");
        let newSelf = self.filter((newValue) => newValue.id !== it.id);
        let findCondition = newSelf.find(
          (selfValue) =>
            handleGetValue(
              selfValue.project_other_data,
              "Date of Open House"
            ) === date &&
            handleGetValue(selfValue.project_other_data, "Start Time") === st &&
            handleGetValue(selfValue.project_other_data, "End Time") === et
        );
        if (findCondition) {
          if (
            it.project_name === "Open House Agent" &&
            findCondition.project_name === "Open House" &&
            newFilteredData.findIndex((newVal) => newVal.id === it.id) === -1
          ) {
            newFilteredData.push(it);
          }
        } else {
          if (
            newFilteredData.findIndex((newVal) => newVal.id === it.id) === -1
          ) {
            newFilteredData.push(it);
          }
        }
      });
    }
  });
  return newFilteredData.filter(
    (item, i, self) => self.findIndex((it) => it.id === item.id) == i
  );
};
const initialState = {
  kanbanView: localStorage.getItem("projectKanbanView") || false,

  searchText: "",
  selectedTab: 0,
  selectedOpenHouseTab: 0,
  selectedDepartment: 0,

  projectsLoading: true,
  projectTasksLoading: false,
  projectLoading: true,
  tasksLoading: true,
  taskLoading: true,
  taskHistoryLoading: true,

  projects: [],
  kanbanProjects: [],
  isProjectDetailsOpen: false,
  projectsCurrentPageNo: 1,
  totalProjects: 0,
  project: {},
  projectsWithoutFilter: [],
  selectedProjectId: null,
  projectToCreate: {},
  departments: [],
  departmentProjects: [],
  projectModalFields: [],
  filterProject: {
    status: "",
  },
  tasks: [],
  kanbanTasks: [],
  tasksCurrentPageNo: 1,
  IsTaskDetailsOpen: false,
  totalTasks: 0,
  task: {},
  selectedTaskId: null,
  taskHistory: [],
  filterTask: {
    status: "",
  },

  errMsg: null,
  projectStats: {
    isLoading: false,
    errMsg: null,
    data: {},
  },
  tasksStats: {
    isLoading: false,
    errMsg: null,
    data: {},
  },
  draftProjects: {
    isLoading: false,
    errMsg: null,
    data: {},
    count: 0,
  },
  deleteDraft: {
    isLoading: false,
    errMsg: null,
    data: {},
  },
};

const project = createSlice({
  name: "project",
  initialState,
  reducers: {
    resetProject: (state, action) => {
      state.project = {};
    },
    resetUploadLoading: (state, action) => {
      state.uploadingDeliverable = false;
    },
    resetTask: (state, action) => {
      state.task = {};
    },
    openTaskDetails: (state, action) => {
      state.isTaskDetailsOpen = true;
    },
    closeTaskDetails: (state, action) => {
      state.isTaskDetailsOpen = false;
    },
    openProjectDetails: (state, action) => {
      state.isProjectDetailsOpen = true;
    },
    closeProjectDetails: (state, action) => {
      state.isProjectDetailsOpen = false;
    },
    setSelectedProjectId: (state, action) => {
      state.selectedProjectId = action.payload;
    },
    setSelectedTaskId: (state, action) => {
      state.selectedTaskId = action.payload;
    },
    deliveriableListUpdate: (state, action) => {
      const { updatedDeliverables, taskID } = action.payload;

      state.task = {
        ...state.task,
        task_deliverables: updatedDeliverables,
      };

      state.kanbanTasks = [
        ...state.kanbanTasks.map((task) => {
          if (task.id === taskID) {
            task = {
              ...task,
              task_deliverables: updatedDeliverables,
            };
          }
          return task;
        }),
      ];
      if (state.project && state.project?.project_tasks) {
        state.project = {
          ...state.project,
          project_tasks: [
            ...state.project.project_tasks.map((task) => {
              if (task.id === taskID) {
                task = {
                  ...task,
                  task_deliverables: updatedDeliverables,
                };
              }
              return task;
            }),
          ],
        };
      }
    },
    updateTaskStatus: (state, action) => {
      let project_tasks = [...state.project.project_tasks];
      let index = project_tasks?.findIndex(
        (item) => item?.id === action.payload.taskId
      );
      project_tasks[index].status = action.payload.status;
      state.project.project_tasks = project_tasks;
    },
    updateTasks: (state, action) => {
      if (state.selectedTab === 1) {
        if (
          state.task.id &&
          action.payload.tasks?.length === 1 &&
          state.task.id === action.payload.tasks[0]?.id
        ) {
          state.task = {
            ...state.task,
            ...action.payload.tasks[0],
            agent: { ...state.task.agent, ...action.payload.tasks[0].agent },
          };
        }
        let newTasks = [...state.tasks];
        action.payload.tasks.forEach((updatedTask) => {
          const index = newTasks.findIndex(
            (task) => task.id === updatedTask.id
          );
          if (index !== -1) {
            // Update the existing task
            newTasks[index] = { ...newTasks[index], ...updatedTask };
          } else {
            // Add the new task if it doesn't exist
            newTasks.unshift(updatedTask);
          }
        });
        state.tasks = newTasks.filter(
          (item, index, self) =>
            self.findIndex((it) => it.id === item.id) === index
        );
      }
    },
    updateProjects: (state, action) => {
      if (state.selectedTab === 0) {
        if (state.project.id) {
          state.project = { ...state.project, ...action.payload.project };
        }

        let newProjects = [...state.projects];
        let index = newProjects?.findIndex(
          (item) => item.id === action.payload.project.id
        );
        newProjects[index] = {
          ...newProjects[index],
          ...action.payload.project,
        };
        state.projects = newProjects;
      }
    },
    addNewTask: (state, action) => {
      if (state.selectedTab === 1) {
        state.tasks = [...action.payload.tasks, ...state.tasks]?.filter(
          (item, index, self) =>
            self.findIndex((it) => it.id === item.id) === index
        );
      }
    },
    addNewProject: (state, action) => {
      if (state.selectedTab === 0) {
        state.projects = [action.payload.project, ...state.projects]?.filter(
          (item, index, self) =>
            self.findIndex((it) => it.id === item.id) === index
        );
      }
    },
  },
  extraReducers: {
    [changeKanbanView.fulfilled]: (state, action) => {
      // Changing View
      state.kanbanView = action.payload;
    },
    [setSearchTextField.fulfilled]: (state, action) => {
      // Global Search Text
      state.searchText = action.payload;
    },
    [setSelectedTab.fulfilled]: (state, action) => {
      // Tab selection - Project Page
      state.selectedTab = action.payload;
    },
    [setSelectedOpenHouseTab.fulfilled]: (state, action) => {
      // Tab selection - Listings Page OpenHouse Project
      state.selectedOpenHouseTab = action.payload;
    },
    [setSelectedDepartmentID.fulfilled]: (state, action) => {
      // selected department id in tab
      state.selectedDepartment = action.payload;
    },
    [resetList.fulfilled]: (state, action) => {
      // for resetting all lists
      state.tasks = [];
      state.projects = [];
      state.tasksCurrentPageNo = 1;
      state.projectsCurrentPageNo = 1;
      state.totalProjects = 0;
      state.totalTasks = 0;
    },

    /* Project Form */
    [getDepartmentList.fulfilled]: (state, action) => {
      state.departments = action.payload.data;
    },
    [getDepartmentProjectList.fulfilled]: (state, action) => {
      // for getting all projects of selected department
      state.departmentProjects = action.payload.data;
    },
    [getProjectModalFields.fulfilled]: (state, action) => {
      // for getting modal fields based on selected department and project
      state.projectModalFields = action.payload.data;
    },
    [setProjectModalFields.fulfilled]: (state, action) => {
      // for setting up project modal fields in ui
      state.projectModalFields = action.payload;
    },

    /* Comments - Project/Task */
    [saveProjectTaskMessages.fulfilled]: (state, action) => {
      // Chat Messages for both project/task
      if (action.payload.type === "task") {
        state.task = {
          ...state.task,
          task_comments: [
            ...state.task.task_comments,
            action.payload.data.data,
          ],
        };
      } else {
        state.project = {
          ...state.project,
          project_comments: [
            ...state.project.project_comments,
            action.payload.data.data,
          ],
        };
      }
    },
    [saveProjectTaskPublicComments.fulfilled]: (state, action) => {
      // Public Comment for both project/task
      const { id, value, type } = action.payload;
      if (type === "task") {
        if (state.project && state.project?.project_tasks) {
          state.project = {
            ...state.project,
            project_tasks: [
              ...state.project.project_tasks.map((task) => {
                if (task.id === id) {
                  task.public_notes = value;
                }
                return task;
              }),
            ],
          };
        }
      } else if (type === "project") {
        if (state.project) {
          state.project = {
            ...state.project,
            public_notes: value,
          };
        }
      }
    },

    /* Project */
    [setProjectCurrentPageNo.fulfilled]: (state, action) => {
      // set project current page no in grid
      state.projectsCurrentPageNo = action.payload;
    },
    [getProjectList.pending]: (state) => {
      // for getting all projects
      state.projectsLoading = true;
    },
    [getProjectList.fulfilled]: (state, action) => {
      state.projectsLoading = false;
      state.totalProjects = action.payload.data.count;
      if (action.payload.pageNumber > 1) {
        state.projects = handleSortProjects([
          ...state.projects,
          ...action.payload.data.data,
        ]);
      } else {
        state.projects = handleSortProjects([...action.payload.data.data]);
      }
    },
    [getProjectList.rejected]: (state, action) => {
      state.projectsLoading = false;
      state.errMsg = action.payload;
    },
    // for getting draft projects
    [getDraftProjects.pending]: (state) => {
      state.draftProjects.isLoading = true;
    },
    [getDraftProjects.fulfilled]: (state, action) => {
      state.draftProjects.isLoading = false;
      state.draftProjects.count = action.payload.data.count || 0;
      if (action.payload.pageNumber > 1) {
        state.draftProjects.data = [
          ...state.draftProjects,
          ...action.payload.data.data,
        ];
      } else {
        state.draftProjects.data = [...action.payload.data.data];
      }
    },
    [getDraftProjects.rejected]: (state, action) => {
      state.draftProjects.isLoading = false;
      state.draftProjects.errMsg = action.payload;
    },

    // delete draft project
    [deleteDraftProject.pending]: (state) => {
      state.deleteDraft.isLoading = true;
    },
    [deleteDraftProject.fulfilled]: (state, action) => {
      state.deleteDraft.isLoading = false;
      state.deleteDraft.data = action.payload.data;
      state.draftProjects.data = state.draftProjects.data?.filter(
        (item) => item.id !== action.payload.id
      );
    },
    [deleteDraftProject.rejected]: (state, action) => {
      state.deleteDraft.isLoading = false;
      state.deleteDraft.errMsg = action.payload;
    },

    [getKanbanProjectList.pending]: (state) => {
      // for getting kanban project list
      state.projectsLoading = true;
    },
    [getKanbanProjectList.fulfilled]: (state, action) => {
      state.projectsLoading = false;
      state.totalProjects = action.payload.data.count;

      if (action.payload.pageNo === 1)
        state.projects = action.payload.data.data;
      else
        state.kanbanProjects = [...state.projects, ...action.payload.data.data];
    },
    [getKanbanProjectList.rejected]: (state, action) => {
      state.projectsLoading = false;
      state.errMsg = action.payload;
    },

    [getOpenHouseProjectList.pending]: (state) => {
      // for getting all open house projects
      state.projectsLoading = true;
    },
    [getOpenHouseProjectList.fulfilled]: (state, action) => {
      state.projectsLoading = false;
      if (action.payload.pageNumber > 1) {
        let projectData = [
          ...state.projectsWithoutFilter,
          ...action.payload.data.data,
        ];
        state.projects = handleMergeTheSameOpenHouseData(projectData);
        state.totalProjects =
          action.payload.data.count -
          (projectData?.length -
            handleMergeTheSameOpenHouseData(projectData)?.length);
      } else {
        let projectData = action.payload.data.data;
        state.projects = handleMergeTheSameOpenHouseData(projectData);
        state.totalProjects =
          action.payload.data.count -
          (projectData?.length -
            handleMergeTheSameOpenHouseData(projectData)?.length);
        state.projectsWithoutFilter = action.payload.data.data;
      }
    },
    [getOpenHouseProjectList.rejected]: (state, action) => {
      state.projectsLoading = false;
      state.errMsg = action.payload;
    },

    [getProject.pending]: (state) => {
      // for getting a particular project in project details dialog
      state.project = {};
      state.projectLoading = true;
    },
    [getProject.fulfilled]: (state, action) => {
      state.projectLoading = false;
      state.project = action.payload.data
        ? action.payload.data.length
          ? action.payload.data[0]
          : action.payload.data
        : action.payload.data;
    },
    [getProject.rejected]: (state, action) => {
      state.projectLoading = false;
      state.errMsg = action.payload;
    },
    // project state section
    [getProjectStats.pending]: (state) => {
      state.projectStats.isLoading = true;
      state.projectStats.data = null;
      state.projectStats.errMsg = null;
    },
    [getProjectStats.fulfilled]: (state, action) => {
      state.projectStats.isLoading = false;
      state.projectStats.data = action.payload.data;
      state.projectStats.errMsg = null;
    },
    [getProjectStats.rejected]: (state, action) => {
      state.projectStats.isLoading = false;
      state.projectStats.data = null;
      state.projectStats.errMsg = action.payload;
    },
    // Task stats section
    [getTasksStats.pending]: (state) => {
      state.tasksStats.isLoading = true;
      state.tasksStats.data = null;
      state.tasksStats.errMsg = null;
    },
    [getTasksStats.fulfilled]: (state, action) => {
      state.tasksStats.isLoading = false;
      state.tasksStats.data = action.payload.data;
      state.tasksStats.errMsg = null;
    },
    [getTasksStats.rejected]: (state, action) => {
      state.tasksStats.isLoading = false;
      state.tasksStats.data = null;
      state.tasksStats.errMsg = action.payload;
    },
    // project state section
    [getProjectStats.pending]: (state) => {
      state.projectStats.isLoading = true;
      state.projectStats.data = null;
      state.projectStats.errMsg = null;
    },
    [getProjectStats.fulfilled]: (state, action) => {
      state.projectStats.isLoading = false;
      state.projectStats.data = action.payload.data;
      state.projectStats.errMsg = null;
    },
    [getProjectStats.rejected]: (state, action) => {
      state.projectStats.isLoading = false;
      state.projectStats.data = null;
      state.projectStats.errMsg = action.payload;
    },
    [getProjectTasks.peding]: (state, action) => {
      // for getting a project's tasks
      state.projectTasksLoading = true;
      state.project = action.payload.data
        ? action.payload.data.length
          ? action.payload.data[0]
          : action.payload.data
        : action.payload.data;
    },
    [getProjectTasks.fulfilled]: (state, action) => {
      state.projectTasksLoading = false;
      state.project = action.payload.data
        ? action.payload.data.length
          ? action.payload.data[0]
          : action.payload.data
        : action.payload.data;
    },

    [changeProjectStatus.fulfilled]: (state, action) => {
      // for changing Project Status
      state.projectLoading = false;
      state.project = { ...state.project, status: action.payload.status };
      state.projects = state.projects?.filter(
        (item) => item.id !== action.payload?.projectID
      );
    },

    [setProjectFilterStatus.fulfilled]: (state, action) => {
      // setting project filter status value in grid
      state.filterProject = { status: action.payload };
    },

    /* Task */
    [setTaskCurrentPageNo.fulfilled]: (state, action) => {
      // set task current task page no in grid
      state.tasksCurrentPageNo = action.payload;
    },

    [getTaskList.pending]: (state) => {
      // for getting all tasks
      state.tasksLoading = true;
    },
    [getTaskList.fulfilled]: (state, action) => {
      state.tasksLoading = false;
      state.totalTasks = action.payload.data.count;
      if (action.payload.pageNumber > 1) {
        state.tasks = handleSortTasks([
          ...state.tasks,
          ...action.payload.data.data,
        ]);
      } else {
        state.tasks = handleSortTasks([...action.payload.data.data]);
      }
    },
    [getTaskList.rejected]: (state, action) => {
      state.tasksLoading = false;
      state.errMsg = action.payload;
    },

    [getKanbanTaskList.pending]: (state) => {
      // for getting Kanban tasks
      state.tasksLoading = true;
    },
    [getKanbanTaskList.fulfilled]: (state, action) => {
      state.tasksLoading = false;
      state.totalTasks = action.payload.data.count;
      state.kanbanTasks = [...action.payload.data.data];
    },
    [getKanbanTaskList.rejected]: (state, action) => {
      state.tasksLoading = false;
      state.errMsg = action.payload;
    },

    [getTask.pending]: (state) => {
      // for getting a particular task in task details dialog
      state.task = {};
      state.taskLoading = true;
    },
    [getTask.fulfilled]: (state, action) => {
      state.taskLoading = false;
      state.task = action.payload.data;
    },
    [getTask.rejected]: (state, action) => {
      state.taskLoading = false;
      state.errMsg = action.payload;
    },

    [getTaskHistory.pending]: (state) => {
      // for getting a particular task history in task details dialog
      state.taskHistoryLoading = true;
    },
    [getTaskHistory.fulfilled]: (state, action) => {
      state.taskHistoryLoading = false;
      state.taskHistory = action.payload.data;
    },
    [getTaskHistory.rejected]: (state, action) => {
      state.taskHistoryLoading = false;
      state.errMsg = action.payload;
    },
    [changeTaskStatus.fulfilled]: (state, action) => {
      // for changing Task Status
      const { taskID, status, taskProject, updateData } = action.payload;
      state.taskLoading = false;
      state.task = { ...state.task, status };

      state.tasks = state.tasks.map((task) => {
        if (task.id === taskID) {
          return { ...task, status, ...updateData };
        }
        return task;
      });
      state.kanbanTasks = state.kanbanTasks.map((task) =>
        task.id === taskID ? { ...task, status, ...updateData } : task
      );

      if (state.project && state.project.project_tasks) {
        state.project = {
          ...state.project,
          status: taskProject.status,
          project_tasks: state.project.project_tasks.map((task) =>
            task.id === taskID ? { ...task, status, ...updateData } : task
          ),
        };
      }
    },
    [assignTask.fulfilled]: (state, action) => {
      // for changing task member
      const { taskID, user } = action.payload;
      // Update the `task` state
      state.task = {
        ...state.task,
        team_member: { ...user },
      };

      // Helper function to update the team member in tasks
      const updateTeamMember = (tasks) =>
        tasks.map((task) =>
          task.id === taskID ? { ...task, team_member: user } : task
        );

      // Update `tasks` and `kanbanTasks` immutably
      state.tasks = updateTeamMember(state.tasks);
      state.kanbanTasks = updateTeamMember(state.kanbanTasks);

      // Update `project_tasks` if `project` exists
      if (state.project && state.project.project_tasks) {
        state.project = {
          ...state.project,
          project_tasks: updateTeamMember(state.project.project_tasks),
        };
      }
      state.taskLoading = false;
    },
    [unassignTask.fulfilled]: (state, action) => {
      // for unassigning task member
      const taskID = action.payload;
      state.task = { ...state.task, team_member: null };
      state.tasks = [
        ...state.tasks.map((task) => {
          if (task.id === taskID) {
            task.team_member = null;
          }
          return task;
        }),
      ];
      state.kanbanTasks = [
        ...state.kanbanTasks.map((task) => {
          if (task.id === taskID) {
            task.team_member = null;
          }
          return task;
        }),
      ];
      if (state.project && state.project?.project_tasks) {
        state.project = {
          ...state.project,
          project_tasks: [
            ...state.project.project_tasks.map((task) => {
              if (task.id === taskID) {
                task.team_member = null;
              }
              return task;
            }),
          ],
        };
      }
      state.taskLoading = false;
    },
    // Task Deliverables
    [changeDeliverableLoading.fulfilled]: (state, action) => {
      state.uploadingDeliverable = action.payload;
    },
    [uploadDeliverable.fulfilled]: (state, action) => {
      // for uploading deliverable
      const { taskID, data } = action.payload;
      state.task = data;
      state.tasks = [
        ...state.tasks.map((task) => {
          if (task.id === taskID) {
            task = data;
          }
          return task;
        }),
      ];
      state.kanbanTasks = [
        ...state.kanbanTasks.map((task) => {
          if (task.id === taskID) {
            task = data;
          }
          return task;
        }),
      ];
      if (state.project && state.project?.project_tasks) {
        state.project = {
          ...state.project,
          project_tasks: [
            ...state.project.project_tasks.map((task) => {
              if (task.id === taskID) {
                task = { ...task, ...data };
              }
              return task;
            }),
          ],
        };
      }
      state.uploadingDeliverable = false;
    },
    [uploadDeliverable.rejected]: (state, action) => {
      state.uploadingDeliverable = false;
      state.errMsg = action.payload;
    },

    [setTaskFilterStatus.fulfilled]: (state, action) => {
      // setting filter status value in Task grid
      state.filterTask = { status: action.payload || "" };
    },
  },
});

export const {
  openTaskDetails,
  closeTaskDetails,
  openProjectDetails,
  closeProjectDetails,
  setSelectedProjectId,
  setSelectedTaskId,
  deliveriableListUpdate,
  resetProject,
  resetTask,
  resetUploadLoading,
  updateTaskStatus,
  updateTasks,
  updateProjects,
  addNewTask,
  addNewProject,
} = project.actions;
export default project.reducer;
