export const styleSheet = () => ({
  agentMapHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "50px",
    marginBottom: "15px",
  },
  agentMapHeaderLeft: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "0px 10px",
  },
  agentMapHeaderRight: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "0px 15px",
  },
  agentMapHeading: {
    fontSize: "18px !important",
    fontWeight: "500 !important",
    color: "#000 !important",
  },
  agentListBox: {
    height: "auto",
    borderRadius: "10px",
    border: "1px solid rgba(0, 0, 0, 0.10)",
    background: "#FFF",
  },
  agentMapBox: {
    height: "100%",
    borderRadius: "10px",
    border: "1px solid rgba(0, 0, 0, 0.10)",
    background: "#FFF",
  },
  agentListBoxHeading: {
    fontSize: "14px !important",
    color: "rgba(0, 0, 0, 0.60) !important",
    padding: "10px 16px !important",
    borderBottom: "1px solid rgba(0, 0, 0, 0.10) !important",
  },
  listItemContent: {
    fontSize: "12px !important",
    color: "#000 !important",
  },
  agentList: {
    height: "80vh",
    overflow: "auto !important",
    "&::-webkit-scrollbar": {
      width: 10,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#C0C0C0",
      borderRadius: 10,
    },
  },
});
