import React, { useContext, useRef, useState } from "react";
import momentTZ from "moment-timezone";
import {
  ListItem,
  List,
  ListItemAvatar,
  ListItemText,
  Typography,
  IconButton,
  Chip,
  Checkbox,
  ListItemIcon,
  ListItemButton,
  ListItemSecondaryAction,
  Paper,
  Tooltip,
  Stack,
  CircularProgress,
  AvatarGroup,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import Avatar from "../../../../../components/uiElements/avatar/index";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EmailIcon from "@mui/icons-material/Email";
import HomeIcon from "@mui/icons-material/Home";
import { AddTasksModal } from "../../../common/Modal/addTasksModal";
import { AddTagsModal } from "../../../common/Modal/addTagsModal";
import { AddAppointmentsModal } from "../../../common/Modal/addAppointmentsModal";
import {
  modalSubmitSuccess,
  uploadAgentFile,
} from "../../../../../redux/agents/getAgentProfile";
import { Box } from "@mui/system";
import { AppContext } from "../../../../../components/callPanel/callContext";
import Input from "../../../../../components/uiElements/input/Input";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { useEffect } from "react";
import { stringAvatar } from "../../../../../utils/avatarInitial";
import { useParams } from "react-router-dom";
import { getTimelinesAgentsRecruitment } from "../../../../../redux/agents/agentRecruitment/getTimelinesAgentsRecruitment";
import { debounce } from "lodash";
import DeleteIcon from "@mui/icons-material/Delete";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import PersonIcon from "@mui/icons-material/Person";
import Button from "../../../../../components/uiElements/button";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import FilePresentRoundedIcon from "@mui/icons-material/FilePresentRounded";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import TaskAltRoundedIcon from "@mui/icons-material/TaskAltRounded";
import CalendarTodayRoundedIcon from "@mui/icons-material/CalendarTodayRounded";
import faceBook from "../../../../../assets/images/social/faceBook.jpg";
import linkdinIcon from "../../../../../assets/images/social/linkdinIcon.png";
import Dropzone from "react-dropzone";
import dragIcon from "../../../../../assets/dragIcon.png";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

// write the method to show the unique array objects javascript
export const NameCard = ({ name, market, avatar }) => {
  return (
    <Paper
      variant="outlined"
      sx={{ backgroundColor: "white", padding: "15px" }}
    >
      <List disablePadding>
        <ListItem disablePadding alignItems="flex-start">
          <ListItemAvatar>
            <Avatar
              sx={{ fontSize: "17px", fontWeight: "400" }}
              {...stringAvatar(name, avatar)}
            />
          </ListItemAvatar>
          <a
            title="Check this on google"
            style={{ color: "rgba(0, 0, 0, 0.87)", textDecoration: "none" }}
            href={`http://www.google.com/search?q=${name?.replace(
              /" "/g,
              "+"
            )}+${market?.replace(/" "/g, "+")}`}
            rel="noreferrer"
            target={"_blank"}
          >
            <ListItemText
              primary={name}
              secondary={<React.Fragment>{market}</React.Fragment>}
            />
          </a>
        </ListItem>
      </List>
    </Paper>
  );
};

const StateLoader = () => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center", color: "#0B0909" }}>
      <CircularProgress size={22} sx={{ color: "#0B0909" }} />
    </Box>
  );
};

export const ContactDetails = ({ list, name, agent_id, agentDetail }) => {
  const { id } = useParams();
  const { dialNumber, incomingCall, outgoingCall } = useContext(AppContext);
  const dispatch = useDispatch();

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };
  const prevIncomingCall = usePrevious(incomingCall);
  const prevOutgoingCall = usePrevious(outgoingCall);
  const refreshTimelineIfUrlRecruitment = () => {
    const queries = `?sortBy=id&sortOrder=desc&pageNumber=1&pageSize=10&agentRecruitmentId=${id}`;
    dispatch(getTimelinesAgentsRecruitment(queries));
  };

  useEffect(() => {
    if (!incomingCall && prevIncomingCall) {
      refreshTimelineIfUrlRecruitment();
    }
    if (!outgoingCall && prevOutgoingCall) {
      refreshTimelineIfUrlRecruitment();
    }
  }, [incomingCall, outgoingCall]);
  return (
    <Paper
      variant="outlined"
      sx={{ backgroundColor: "white", padding: "6px 0px" }}
    >
      {list?.map((item) => (
        <List disablePadding>
          {item.value?.length ? (
            item.value?.map((option, index) => (
              <ListItem
                disablePadding
                sx={{
                  "&:hover": {
                    "& .MuiListItemSecondaryAction-root": {
                      display: "block !important",
                    },
                  },
                }}
                dense
              >
                <ListItemButton
                  onClick={() => {
                    if (!item.value || item.value?.length === 0) {
                      item.onClick();
                    }
                  }}
                  disableRipple
                >
                  <ListItemSecondaryAction sx={{ display: "none" }}>
                    <Stack direction={"row"} spacing={0}>
                      {item.value ? (
                        <CopyToClipboard
                          onCopy={() =>
                            toast.success("Copied Successfully!", {
                              position: "top-right",
                              autoClose: 1000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: "colored",
                            })
                          }
                          text={
                            item.type === "address"
                              ? `${option?.street || ""}, ${
                                  option?.state || ""
                                }, ${option?.country || ""}`
                              : option.value
                          }
                        >
                          <IconButton sx={{ padding: "3px" }}>
                            <ContentCopyRoundedIcon fontSize="small" />
                          </IconButton>
                        </CopyToClipboard>
                      ) : null}
                      {item.value ? (
                        <IconButton
                          sx={{ padding: "3px" }}
                          onClick={item.onClick}
                          edge="end"
                          aria-label="comments"
                        >
                          <CreateRoundedIcon fontSize="small" />
                        </IconButton>
                      ) : null}
                    </Stack>
                  </ListItemSecondaryAction>
                  {item.type === "phone" ? (
                    <LocalPhoneIcon
                      onClick={() => {
                        if (
                          item.value &&
                          item.type === "phone" &&
                          !option.bad_number &&
                          agentDetail?.agent_stellar_data?.realtor_agent_office
                            ?.OfficePhone !== option.value
                        ) {
                          dialNumber(option.value, null, name, agent_id);
                        }
                      }}
                      sx={{
                        fontSize: 22,
                        marginTop: "-2px",
                        color:
                          index > 0 ? "transparent" : "rgba(0, 0, 0, 0.54)",
                        marginRight: "10px",
                      }}
                    />
                  ) : item.type === "email" ? (
                    <EmailIcon
                      sx={{
                        fontSize: 22,
                        marginTop: "-2px",
                        marginRight: "10px",
                        color:
                          index > 0 ? "transparent" : "rgba(0, 0, 0, 0.54)",
                      }}
                    />
                  ) : item.type === "address" ? (
                    <HomeIcon
                      sx={{
                        fontSize: 22,
                        marginTop: "-2px",
                        marginRight: "10px",
                        color:
                          index > 0 ? "transparent" : "rgba(0, 0, 0, 0.54)",
                      }}
                    />
                  ) : null}
                  &nbsp;
                  <ListItemText
                    onClick={() => {
                      if (
                        item.value &&
                        item.type === "phone" &&
                        !option.bad_number &&
                        agentDetail?.agent_stellar_data?.realtor_agent_office
                          ?.OfficePhone !== option.value
                      ) {
                        dialNumber(option.value, null, name, agent_id);
                      }
                    }}
                    sx={{ maxWidth: "70%", overflowWrap: "break-word" }}
                  >
                    {item.type === "address" ? (
                      <>
                        {option?.street || null}, {option?.state || null},{" "}
                        {option?.country || null}
                        {index + 1 === item.value.length ? null : <br />}
                      </>
                    ) : (
                      <>
                        <span
                          style={{
                            textDecoration:
                              option.bad_number ||
                              agentDetail?.agent_stellar_data
                                ?.realtor_agent_office?.OfficePhone ===
                                option.value
                                ? "line-through"
                                : "",
                            color:
                              option.bad_number ||
                              agentDetail?.agent_stellar_data
                                ?.realtor_agent_office?.OfficePhone ===
                                option.value
                                ? "red"
                                : "",
                          }}
                        >
                          {option.value}
                        </span>{" "}
                        {item.type === "phone" ? (
                          <span style={{ color: "rgba(0, 0, 0, 0.6)" }}>
                            ({option.type})
                          </span>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            ))
          ) : (
            <ListItem dense disablePadding>
              <ListItemButton
                onClick={() => {
                  if (!item.value || item.value?.length === 0) {
                    item.onClick();
                  }
                }}
                disableRipple
              >
                {item.type === "phone" ? (
                  <LocalPhoneIcon
                    sx={{
                      fontSize: 22,
                      marginTop: "-2px",
                      color: "rgba(0, 0, 0, 0.54)",
                      marginRight: "10px",
                    }}
                  />
                ) : item.type === "email" ? (
                  <EmailIcon
                    sx={{
                      fontSize: 22,
                      marginTop: "-2px",
                      marginRight: "10px",
                      color: "rgba(0, 0, 0, 0.54)",
                    }}
                  />
                ) : item.type === "address" ? (
                  <>
                    <HomeIcon
                      sx={{
                        fontSize: 25,
                        marginTop: "-2px",
                        marginRight: "10px",
                        color: "rgba(0, 0, 0, 0.54)",
                      }}
                    />
                  </>
                ) : null}
                <ListItemText>Add {item.type}</ListItemText>
              </ListItemButton>
            </ListItem>
          )}
        </List>
      ))}
    </Paper>
  );
};

export const Details = ({ list, title }) => {
  return (
    <Paper variant="outlined" sx={{ backgroundColor: "white" }}>
      <List disablePadding>
        <ListItem>
          <Typography variant="h6" component="h6">
            {title}
          </Typography>
        </ListItem>
        {list.map((item) => (
          <ListItem
            alignItems="center"
            sx={{ justifyContent: "space-between" }}
          >
            <Typography variant="subtitle2" component="p">
              {item.key}:
            </Typography>
            &nbsp; &nbsp;
            <Typography variant="p" component="p" sx={{ fontSize: "14px" }}>
              {item.value}
            </Typography>
          </ListItem>
        ))}
        <br />
      </List>
    </Paper>
  );
};

export const TagsCard = ({ prevTags, loading, removeTags, ...props }) => {
  const dispatch = useDispatch();

  const [addTagsModal, setAddTagsModal] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [updatedTags, setUpdatedTags] = useState([]);

  useEffect(() => {
    if (firstLoad || updatedTags.length !== prevTags?.length) {
      setUpdatedTags(() => prevTags);
      setFirstLoad(false);
    }
  }, [prevTags]);

  useEffect(() => {
    if (!addTagsModal) setUpdatedTags(() => prevTags);
  }, [addTagsModal]);

  const debouncedSearch = useRef(
    debounce(async (tags) => {
      removeTags(tags.map((mp) => mp.tag));
    }, 300)
  ).current;

  const handleClickDelete = (tags) => {
    setUpdatedTags(() => tags);
    debouncedSearch(tags);
  };

  return (
    <>
      <AddTagsModal
        loading={loading}
        state={addTagsModal}
        prevTags={prevTags}
        updatedTags={updatedTags?.map((t) => t.tag)}
        closeModal={() => {
          dispatch(modalSubmitSuccess());
          setAddTagsModal(false);
        }}
        {...props}
      />
      <Paper
        variant="outlined"
        sx={{ backgroundColor: "white", padding: "15px", mt: "0px !important" }}
      >
        <List disablePadding>
          <ListItem disablePadding sx={{ mt: "-5px" }}>
            <Typography variant="h6" component="h6">
              Tags
              <IconButton
                color="primary"
                aria-label="Add Tags"
                component="span"
                onClick={() => setAddTagsModal(true)}
              >
                <AddCircleIcon fontSize="small" sx={{ color: "#0B0909" }} />
              </IconButton>
            </Typography>
          </ListItem>
          {updatedTags && updatedTags.length ? (
            <ListItem disablePadding sx={{ flexWrap: "wrap" }}>
              {updatedTags.map((tag, i) => {
                return (
                  <Chip
                    sx={{ margin: "4px !important", marginBottom: "2px" }}
                    label={tag.tag}
                    onDelete={(e) => {
                      handleClickDelete(
                        updatedTags.filter((t) => t.id !== tag.id)
                      );
                    }}
                    key={tag.id}
                  />
                );
              })}
              <br />
              <br />
            </ListItem>
          ) : (
            <Typography
              sx={{
                color: "#9c9c9c",
                fontFamily: "AvenirNext-400",
                fontSize: "14px",
                mt: "12px",
                paddingBottom: "6px",
              }}
            >
              No Tags found!
            </Typography>
          )}
        </List>
      </Paper>
    </>
  );
};
export const BackgroundCard = ({ bioTextUpdateFnc }) => {
  const [text, setText] = useState("");
  const [editable, setEditable] = useState(false);

  const {
    agentProfile: { list },
  } = useSelector((item) => item.agentProfile);

  useEffect(() => {
    if (list?.Bio) {
      setText(list?.Bio);
    }
  }, [list]);

  const onSave = () => {
    setEditable(false);
    bioTextUpdateFnc(text);
  };

  const onEdit = () => {
    setEditable(true);
  };

  return (
    <Paper variant="outlined" sx={{ backgroundColor: "white" }}>
      <List>
        <ListItem
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "0px",
          }}
        >
          <Typography variant="h6" component="h6">
            Background
          </Typography>
        </ListItem>
        <ListItem
          disablePadding
          sx={{
            display: "block",
            "&:hover": {
              "& .MuiListItemSecondaryAction-root": {
                display: "block !important",
              },
            },
          }}
        >
          {editable ? (
            <Box sx={{ height: "100%", padding: "15px" }}>
              <Input
                value={text}
                block
                multiline
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "10px 0px 12px 10px",
                  },
                }}
                minRows={3}
                maxRows={3}
                onChange={(e) => setText(e.target.value)}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: " flex-end",
                  paddingTop: "5px",
                }}
              >
                <CancelIcon
                  onClick={() => setEditable(false)}
                  sx={{ color: "black", cursor: "pointer", marginRight: "5px" }}
                />

                <CheckCircleIcon
                  sx={{ color: "#42b00c", cursor: "pointer" }}
                  onClick={onSave}
                />
              </Box>
            </Box>
          ) : (
            <ListItemButton
              onClick={() => {
                if (!text) {
                  onEdit();
                }
              }}
              sx={{
                fontSize: text ? "14px" : "16px",
                fontFamily: "AvenirNext-400",
                paddingRight: "30px",
              }}
              disableRipple
            >
              {text ? (
                <ListItemSecondaryAction
                  sx={{ display: "none", position: "absolute", top: "20px" }}
                >
                  <IconButton
                    onClick={onEdit}
                    sx={{ padding: "3px" }}
                    edge="end"
                    aria-label="Background"
                  >
                    <CreateRoundedIcon fontSize="small" />
                  </IconButton>
                </ListItemSecondaryAction>
              ) : null}
              {text.length !== 0 ? text : "Add Background Info"}
            </ListItemButton>
          )}
        </ListItem>
      </List>
    </Paper>
  );
};

export const SocialCard = ({ socialData }) => {
  return (
    <Paper
      variant="outlined"
      sx={{ backgroundColor: "white", padding: "15px" }}
    >
      <List disablePadding>
        <ListItem disablePadding sx={{ mt: "-5px" }}>
          <Typography variant="h6" component="h6">
            Social
          </Typography>
        </ListItem>
        {(socialData?.company && socialData?.title) ||
        socialData?.linkedIn ||
        socialData?.facebook ? (
          <Box>
            {socialData?.company ? (
              <Typography
                sx={{ fontFamily: "AvenirNext-400", fontSize: "14px" }}
              >
                <Box sx={{ fontWeight: "600" }}>Company Name :</Box>
                {socialData?.company}
              </Typography>
            ) : null}
            {socialData?.title ? (
              <Typography
                sx={{ fontFamily: "AvenirNext-400", fontSize: "14px" }}
              >
                <Box sx={{ fontWeight: "600" }}>Title:</Box> {socialData?.title}
              </Typography>
            ) : null}
            <br />
            <Stack direction={"row"} spacing={1}>
              {socialData?.linkedIn ? (
                <a href={socialData?.linkedIn} rel="noreferrer" target="_blank">
                  <Chip
                    sx={{ cursor: "pointer" }}
                    avatar={<Avatar alt="Natacha" src={linkdinIcon} />}
                    label="linkdin"
                    variant="outlined"
                  />
                </a>
              ) : null}
              {socialData?.facebook ? (
                <a href={socialData?.facebook} rel="noreferrer" target="_blank">
                  <Chip
                    sx={{ cursor: "pointer" }}
                    avatar={<Avatar alt="Natacha" src={faceBook} />}
                    label="faceBook"
                    variant="outlined"
                  />
                </a>
              ) : null}
            </Stack>
          </Box>
        ) : (
          <Typography
            sx={{
              color: "#9c9c9c",
              fontFamily: "AvenirNext-400",
              fontSize: "14px",
              mt: "12px",
              paddingBottom: "6px",
            }}
          >
            No Social data found!
          </Typography>
        )}
      </List>
    </Paper>
  );
};

export const TasksCard = ({ tasks, markTaskAsComplete, ...props }) => {
  const [addTaskModal, setAddTaskModal] = useState(false);
  const [actionType, setActionType] = useState("");
  const [initialValue, setInitialValue] = useState({});
  const [completeTask, setCompleteTask] = useState(false);
  const [isOpenCompletedTask, setIsOpenCompletedTask] = useState(false);
  const dispatch = useDispatch();

  const editItem = (task) => () => {
    setInitialValue(task);
    setActionType("Update");
    setAddTaskModal(true);
  };

  const addTagsFunc = () => {
    setAddTaskModal(true);
    setActionType("");
    setInitialValue({});
  };
  const completeTaskFunc = (task, isCompleted) => () => {
    setCompleteTask(task.id);
    markTaskAsComplete(task.id, isCompleted);
  };
  return (
    <>
      <AddTasksModal
        state={addTaskModal}
        initialValue={initialValue}
        type={actionType}
        {...props}
        closeModal={() => {
          dispatch(modalSubmitSuccess());

          setAddTaskModal(false);
        }}
      />

      <Paper
        variant="outlined"
        sx={{
          backgroundColor: "white",
          paddingBottom: "5px",
          mt: "0px !important",
        }}
      >
        <List disablePadding sx={{ padding: "15px", paddingBottom: "0px" }}>
          <ListItem disablePadding sx={{ mt: "-5px" }}>
            <ListItemIcon sx={{ minWidth: "35px" }}>
              <TaskAltRoundedIcon
                sx={{ color: "rgba(0, 0, 0, 0.54)", mt: "-4px" }}
              />
            </ListItemIcon>
            <Typography variant="h6" component="h6">
              Tasks
              <IconButton
                color="primary"
                aria-label="Add Tags"
                component="span"
                onClick={addTagsFunc}
              >
                <AddCircleIcon fontSize="small" sx={{ color: "#0B0909" }} />
              </IconButton>
            </Typography>
          </ListItem>
        </List>
        {tasks && tasks.length > 0 ? (
          <List disablePadding>
            {tasks
              ?.filter((el) => !el?.isCompleted)
              .map((tsk, i) => {
                return (
                  <ListItem disablePadding key={i}>
                    <ListItemButton
                      sx={{ padding: "5px 15px 5px 20px" }}
                      dense
                      alignItems="flex-start"
                    >
                      <ListItemIcon
                        sx={{
                          justifyContent: "left !important",
                          "& .MuiCheckbox-root": { mt: "-12px" },
                          minWidth: "30px",
                        }}
                      >
                        <Checkbox
                          edge="start"
                          disableRipple
                          checked={false}
                          onChange={completeTaskFunc(tsk)}
                        />
                      </ListItemIcon>
                      <ListItemText
                        onClick={editItem(tsk)}
                        id={tsk.id}
                        primary={tsk.name}
                        secondary={
                          <Box
                            sx={{
                              color: "rgba(0, 0, 0, 0.6)",
                              fontFamily: "AvenirNext-400",
                              fontSize: "12px",
                              mt: "8px",
                            }}
                          >
                            <Stack direction={"row"} alignItems={"center"}>
                              <AccessTimeRoundedIcon
                                sx={{ mr: "5px", fontSize: "1rem" }}
                                fontSize="small"
                              />{" "}
                              <Box
                                sx={{
                                  color:
                                    new Date() > new Date(tsk.dueDate)
                                      ? "#FF9800"
                                      : "",
                                }}
                              >
                                {momentTZ
                                  .tz(
                                    tsk.dueDate,
                                    process.env.REACT_APP_TIMEZONE
                                  )
                                  .format("MMM Do YYYY")}
                              </Box>
                            </Stack>
                            <Stack
                              direction={"row"}
                              alignItems={"center"}
                              sx={{ mt: "4px" }}
                            >
                              <PersonIcon
                                fontSize="small"
                                sx={{ mr: "5px", fontSize: "1.05rem" }}
                              />
                              {tsk.AssignedTo}
                            </Stack>
                          </Box>
                        }
                      ></ListItemText>
                    </ListItemButton>
                  </ListItem>
                );
              })}
            {tasks?.filter((el) => el?.isCompleted)?.length ? (
              <Button
                variant="text"
                sx={{
                  color: "#03A9F4",
                  fontFamily: "AvenirNext-400",
                  fontSize: "14px",
                  display: "flex",
                  alignItems: "center",
                  mt: "10px",
                  paddingLeft: "0px",
                  ml: "15px",
                  textTransform: "capitalize",
                }}
                onClick={() => setIsOpenCompletedTask(!isOpenCompletedTask)}
              >
                Completed Tasks{" "}
                {isOpenCompletedTask ? (
                  <KeyboardArrowDownRoundedIcon sx={{ ml: "5px" }} />
                ) : (
                  <KeyboardArrowRightRoundedIcon sx={{ ml: "5px" }} />
                )}
              </Button>
            ) : null}

            {isOpenCompletedTask
              ? tasks
                  ?.filter((el) => el?.isCompleted)
                  .map((tsk, i) => {
                    return (
                      <ListItem disablePadding key={i}>
                        <ListItemButton
                          sx={{ padding: "5px 15px 5px 20px" }}
                          role=""
                          dense
                          alignItems="flex-start"
                        >
                          <ListItemIcon
                            sx={{
                              justifyContent: "left !important",
                              minWidth: "30px",
                              "& .Mui-checked": {
                                color: "rgba(2, 136, 209, 0.54)",
                                mt: "-12px",
                              },
                            }}
                          >
                            <Checkbox
                              edge="start"
                              disableRipple
                              checked={tsk.isCompleted}
                              onChange={completeTaskFunc(tsk, true)}
                            />
                          </ListItemIcon>
                          <ListItemText
                            onClick={editItem(tsk)}
                            id={tsk.id}
                            primary={tsk.name}
                            secondary={
                              <Box
                                sx={{
                                  color: "rgba(0, 0, 0, 0.6)",
                                  fontFamily: "AvenirNext-400",
                                  fontSize: "12px",
                                  mt: "8px",
                                }}
                              >
                                <Stack direction={"row"} alignItems={"center"}>
                                  <AccessTimeRoundedIcon
                                    sx={{ mr: "5px" }}
                                    fontSize="small"
                                  />{" "}
                                  <Box
                                    sx={{
                                      color:
                                        new Date() > new Date(tsk.dueDate)
                                          ? "#FF9800"
                                          : "",
                                    }}
                                  >
                                    {" "}
                                    {momentTZ
                                      .tz(
                                        tsk.dueDate,
                                        process.env.REACT_APP_TIMEZONE
                                      )
                                      .format("MMM Do YYYY - ")}
                                    {momentTZ
                                      .tz(
                                        tsk.dueDateTime,
                                        process.env.REACT_APP_TIMEZONE
                                      )
                                      .format("hh:mma")}
                                  </Box>
                                </Stack>
                                <Stack
                                  direction={"row"}
                                  alignItems={"center"}
                                  sx={{ mt: "4px" }}
                                >
                                  <PersonIcon
                                    fontSize="small"
                                    sx={{ mr: "5px" }}
                                  />
                                  {tsk.AssignedTo}
                                </Stack>
                              </Box>
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  })
              : null}
          </List>
        ) : (
          <Typography
            sx={{
              color: "#9c9c9c",
              fontFamily: "AvenirNext-400",
              fontSize: "14px",
              mt: "12px",
              paddingBottom: "10px",
              paddingLeft: "15px",
            }}
          >
            No tasks found!
          </Typography>
        )}
      </Paper>
    </>
  );
};
export const AppointmentsCard = ({ appointments, ...props }) => {
  const [appointmentModal, setAddAppointmentModal] = useState(false);
  const [actionType, setActionType] = useState("");
  const [initialValue, setInitialValue] = useState({});
  const dispatch = useDispatch();

  const editItem = (appt) => () => {
    setInitialValue(appt);
    setActionType("Update");
    setAddAppointmentModal(true);
  };
  const addAppointmentsFunc = () => {
    setAddAppointmentModal(true);
    setActionType("");
    setInitialValue({});
  };
  return (
    <>
      <AddAppointmentsModal
        state={appointmentModal}
        initialValue={initialValue}
        type={actionType}
        timezone={
          props.adminDetail?.market?.timezone || process.env.REACT_APP_TIMEZONE
        }
        {...props}
        closeModal={() => {
          dispatch(modalSubmitSuccess());
          setAddAppointmentModal(false);
          setInitialValue({});
        }}
      />
      <Paper
        variant="outlined"
        sx={{ backgroundColor: "white", mt: "0px !important" }}
      >
        <List disablePadding>
          <ListItem sx={{ paddingBottom: "0px" }}>
            <ListItemIcon sx={{ minWidth: "35px" }}>
              <CalendarTodayRoundedIcon
                fontSize="small"
                sx={{ color: "rgba(0, 0, 0, 0.54)", mt: "-4px" }}
              />
            </ListItemIcon>
            <Typography variant="h6" component="h6">
              Appointments
              <IconButton
                color="primary"
                aria-label="Add Tags"
                component="span"
                onClick={addAppointmentsFunc}
              >
                <AddCircleIcon fontSize="small" sx={{ color: "#0B0909" }} />
              </IconButton>
            </Typography>
          </ListItem>
          {appointments && appointments.length ? (
            appointments.map((appt, i) => {
              return (
                <ListItem disablePadding key={i} onClick={editItem(appt)}>
                  <ListItemButton
                    role=""
                    onClick={() => null}
                    sx={{
                      "& .MuiListItemText-primary": {
                        fontSize: "14px",
                        fontFamily: "AvenirNext",
                        fontWeight: "600",
                        maxWidth: "20ch",
                      },
                      "& .MuiListItemText-secondary": {
                        fontSize: "12px",
                        fontFamily: "AvenirNext",
                      },
                    }}
                    alignItems="flex-start"
                  >
                    <ListItemText
                      id="1"
                      primary={appt.title}
                      secondary={
                        <>
                          {momentTZ
                            .tz(
                              appt.start,
                              props.adminDetail?.market?.timezone ||
                                process.env.REACT_APP_TIMEZONE
                            )
                            .format("MMM DD YYYY")}{" "}
                          -{" "}
                          {momentTZ
                            .tz(
                              appt.end,
                              props.adminDetail?.market?.timezone ||
                                process.env.REACT_APP_TIMEZONE
                            )
                            .format("MMM DD YYYY")}
                          <br />
                          {momentTZ
                            .tz(
                              appt.start,
                              props.adminDetail?.market?.timezone ||
                                process.env.REACT_APP_TIMEZONE
                            )
                            .format("hh:mma")}{" "}
                          -{" "}
                          {momentTZ
                            .tz(
                              appt.end,
                              props.adminDetail?.market?.timezone ||
                                process.env.REACT_APP_TIMEZONE
                            )
                            .format("hh:mma")}
                        </>
                      }
                    ></ListItemText>
                    <ListItemSecondaryAction>
                      <AvatarGroup
                        max={4}
                        sx={{
                          "& .MuiAvatarGroup-avatar": {
                            width: "30px",
                            height: "30px",
                            fontSize: "13px",
                          },
                        }}
                        variant="circled"
                        spacing="small"
                      >
                        {appt?.realtor_appointment_invitees?.map((inv, i) => {
                          return (
                            <Tooltip title={inv?.name} placement="bottom">
                              <Avatar
                                variant="circled"
                                alt={inv.name}
                                key={i}
                                {...stringAvatar(inv?.name, inv?.picture)}
                              />
                            </Tooltip>
                          );
                        })}
                      </AvatarGroup>
                    </ListItemSecondaryAction>
                  </ListItemButton>
                </ListItem>
              );
            })
          ) : (
            <Typography
              sx={{
                color: "#9c9c9c",
                fontFamily: "AvenirNext-400",
                fontSize: "14px",
                mt: "5px",
                paddingBottom: "15px",
                paddingLeft: "16px",
              }}
            >
              {" "}
              No appointments found!
            </Typography>
          )}
        </List>
      </Paper>
    </>
  );
};

export const CollaboratorsCard = ({
  collaborators,
  setOpenCollaborateModal,
  handleDeleteCollaborate,
  ...props
}) => {
  let { classes } = props;
  return (
    <>
      <Paper
        variant="outlined"
        sx={{ backgroundColor: "white", paddingBottom: "5px" }}
      >
        <List disablePadding sx={{ padding: "15px", paddingBottom: "0px" }}>
          <ListItem disablePadding sx={{ mt: "-5px" }}>
            <ListItemIcon sx={{ minWidth: "35px" }}>
              <PeopleAltRoundedIcon
                sx={{ color: "rgba(0, 0, 0, 0.54)", mt: "-6px" }}
              />
            </ListItemIcon>
            <Typography variant="h6" component="h6">
              Collaborators
              <IconButton
                onClick={() => setOpenCollaborateModal(true)}
                aria-label="Add Tags"
                component="span"
              >
                <AddCircleIcon fontSize="small" sx={{ color: "#0B0909" }} />
              </IconButton>
            </Typography>
          </ListItem>
        </List>
        {collaborators?.length ? (
          <List dense sx={{ width: "100%", bgcolor: "background.paper" }}>
            {collaborators.map((item, value) => {
              const labelId = `checkbox-list-secondary-label-${value}`;
              return (
                <ListItem
                  key={value}
                  disablePadding
                  className={classes.listItem}
                  sx={{
                    "&:hover": {
                      "& .MuiListItemSecondaryAction-root": {
                        display: "block !important",
                      },
                    },
                  }}
                >
                  <ListItemButton>
                    <ListItemAvatar sx={{ minWidth: "40px" }}>
                      <Avatar
                        sx={{ width: "30px", height: "30px", fontSize: "14px" }}
                        src={
                          item?.users_v2?.profile_images?.profile_img ||
                          item?.users_v2?.profile_images?.profile_img_thumbnail
                        }
                      >
                        {`${item.name?.slice(0, 1)}${item.name
                          ?.split(" ")[1]
                          ?.slice(0, 1)}`}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText id={labelId} primary={`${item.name}`} />
                    <ListItemSecondaryAction
                      sx={{ display: "none" }}
                      className={classes.listItemSecondaryAction}
                    >
                      <IconButton
                        onClick={() => handleDeleteCollaborate(item.id)}
                        aria-label="Comments"
                      >
                        <DeleteIcon sx={{ color: "grey" }} />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        ) : (
          <Typography
            sx={{
              color: "#9c9c9c",
              fontFamily: "AvenirNext-400",
              fontSize: "14px",
              mt: "12px",
              paddingBottom: "10px",
              paddingLeft: "15px",
            }}
          >
            No Collaborators
          </Typography>
        )}
      </Paper>
    </>
  );
};

export const FilesCard = ({ id, handleDeleteFile, classes, setErrorAlert }) => {
  const dispatch = useDispatch();
  const hiddenFileInput = React.useRef(null);

  const { agentProfile } = useSelector((item) => item.agentProfile);
  const userList = useSelector((item) => item.users.storedListOfUsers);

  const uploadFile = (file) => {
    const fileUploaded = file;
    const data = {
      formData: { realtor_agent_id: id, attachments: fileUploaded },
      handleSuccess,
      handleError,
    };

    dispatch(uploadAgentFile(data));
  };

  const handleSuccess = () => {
    setErrorAlert({
      errorMsg: "You have successfully uploaded the file",
      errorType: "success",
      isOpen: true,
    });
  };

  const handleError = (error) => {
    setErrorAlert({
      errorMsg: error
        ? JSON.stringify(error)
        : "Something went wrong please try later",
      errorType: "error",
      isOpen: true,
    });
  };

  const handleAddClick = () => {
    hiddenFileInput.current.click();
  };

  const handleFileChangeDrop = (Files, data) => {
    uploadFile(Files[0]);
  };

  const handleDragEnter = () => {
    document.getElementById(`upload-file-for-recruitment`).style.border =
      "2px dotted black";
    document.getElementById(`upload-file-for-recruitment-icon`).style.display =
      "flex";
  };
  const handleDragLeave = () => {
    document.getElementById(`upload-file-for-recruitment`).style.border =
      "none";
    document.getElementById(`upload-file-for-recruitment-icon`).style.display =
      "none";
  };

  return (
    <Paper variant="outlined" sx={{ backgroundColor: "white" }}>
      <Box
        id="upload-file-for-recruitment"
        sx={{
          margin: "6px",
          borderRadius: "6px",
          border: "2px dotted transparent",
        }}
      >
        <Dropzone
          accept={".pdf"}
          noClick={true}
          noKeyboard={true}
          onDragEnter={() => handleDragEnter()}
          onDragLeave={() => handleDragLeave()}
          onDrop={(acceptedFiles) => {
            handleFileChangeDrop(acceptedFiles);
            handleDragLeave();
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <List {...getRootProps()} disablePadding sx={{ margin: "-6px" }}>
              <Box
                id={`upload-file-for-recruitment-icon`}
                sx={{ top: "0px" }}
                className={classes.dropzoneArea}
              >
                <Box className={classes.dropzoneAreaInner}>
                  <img
                    src={dragIcon}
                    alt="dragIcon"
                    style={{ width: "25px", marginRight: "10px" }}
                  />{" "}
                  <Box>Drop file to upload.</Box>
                </Box>
              </Box>
              <ListItem>
                <ListItemIcon sx={{ minWidth: "32.5px" }}>
                  <FilePresentRoundedIcon
                    sx={{
                      color: "rgba(0, 0, 0, 0.54)",
                      mt: "-2px",
                      ml: "-3px",
                    }}
                  />
                </ListItemIcon>
                <Typography
                  variant="h6"
                  component="h6"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontFamily: "AvenirNext!important",
                    fontWeight: "600",
                  }}
                >
                  Files
                  <IconButton
                    color="primary"
                    aria-label="Add Tags"
                    component="span"
                    onClick={handleAddClick}
                  >
                    <AddCircleIcon fontSize="small" sx={{ color: "#0B0909" }} />
                  </IconButton>
                  <input
                    type="file"
                    ref={hiddenFileInput}
                    onChange={(event) => uploadFile(event.target.files[0])}
                    onClick={(event) => (event.target.value = "")}
                    style={{ display: "none" }}
                  />
                </Typography>
              </ListItem>
              {agentProfile?.realtor_files?.status?.pending ? (
                <StateLoader />
              ) : agentProfile?.list?.realtor_files &&
                agentProfile?.list?.realtor_files.length > 0 ? (
                agentProfile?.list?.realtor_files.map((file, i) => {
                  return (
                    <ListItem
                      disablePadding
                      key={i}
                      sx={{
                        "&:hover": {
                          "& .MuiListItemSecondaryAction-root": {
                            display: "block !important",
                          },
                        },
                      }}
                    >
                      <ListItemButton disableRipple alignItems="flex-start">
                        <ListItemSecondaryAction
                          sx={{
                            display: "none",
                            position: "absolute",
                            top: "20px",
                          }}
                        >
                          <IconButton
                            onClick={() => handleDeleteFile(file.id)}
                            sx={{ padding: "3px" }}
                            edge="end"
                            aria-label="Background"
                          >
                            <DeleteForeverIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                        <ListItemIcon>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center !important",
                              width: "85px",
                              height: "95px",
                              border: "1px solid #000000",
                              borderRadius: "5px",
                            }}
                            onClick={() => window.open(file.filePath)}
                          >
                            {file.filePath.slice(-3) === "pdf" ||
                            file.filePath.slice(-3) === "PDF" ? (
                              <iframe
                                src={file.filePath}
                                style={{
                                  overflow: "hidden",
                                  cursor: "pointer",
                                }}
                                title={file.fileName}
                                height="100%"
                                width="100%"
                                scrolling="no"
                                onClick={() => window.open(file.filePath)}
                              />
                            ) : (
                              <Avatar
                                onClick={() => window.open(file.filePath)}
                                variant="rounded"
                                sx={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                                src={file.filePath}
                              />
                            )}
                          </Box>
                        </ListItemIcon>
                        <ListItemText>
                          <Box sx={{ height: "70px", marginLeft: "10px" }}>
                            <Typography
                              onClick={() => window.open(file.filePath)}
                              sx={{
                                color: "#0B0909",
                                fontSize: "14px",
                                fontWeight: "600",
                                fontFamily: "AvenirNext!important",
                                overflowWrap: "break-word",
                                display: "-webkit-box !important",
                                "-webkit-line-clamp": "2 !important",
                                "-webkit-box-orient": "vertical !important",
                                width: "90%",
                                overflow: "hidden !important",
                              }}
                            >
                              {file.fileName}
                            </Typography>

                            <Typography
                              onClick={() => window.open(file.filePath)}
                              sx={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontSize: "14px",
                              }}
                            >
                              {momentTZ
                                .utc(file.createdAt)
                                .format("MMM Do YYYY")}
                            </Typography>

                            <Typography
                              onClick={() => window.open(file.filePath)}
                              sx={{
                                color: "rgba(0, 0, 0, 0.6)",
                                fontSize: "14px",
                              }}
                            >
                              by{" "}
                              {file.createdById
                                ? userList?.list.find(
                                    (item) =>
                                      item.id === Number(file.createdById || 1)
                                  )?.full_name
                                : ""}
                            </Typography>
                          </Box>
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                  );
                })
              ) : (
                <Typography
                  sx={{
                    color: "#9c9c9c",
                    fontFamily: "AvenirNext-400",
                    fontSize: "14px",
                    mt: "5px",
                    paddingBottom: "15px",
                    paddingLeft: "15px",
                  }}
                >
                  No Files yet, drag and drop to upload
                </Typography>
              )}
            </List>
          )}
        </Dropzone>
      </Box>
    </Paper>
  );
};
