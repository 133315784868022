import React from "react";
import { styleSheet } from "./style";
import { withStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import socialIcon from "../../../../../assets/images/marketing/social1.jpg";
function SuggestedSection(props) {
  let { classes } = props;
  const navigate = useNavigate();
  return (
    <Box className={classes.SuggestedSection}>
      <Box className={classes.suggestedHeading}>
        <Typography className={classes.suggestedHeadingLeft}>
          Suggested Designs
        </Typography>
        <Box className={classes.suggestedHeadingRight}></Box>
      </Box>{" "}
      <Box className={classes.templateSuggestionSection}>
        {Array.from(Array(4).keys()).map((item, index) => (
          <Box
            onClick={() =>
              navigate(`/marketing_center/digital/categories/${index + 1}`)
            }
            key={index}
            className={classes.templateSuggestionSectionItem}
          >
            <Box className={classes.templateSuggestionSectionItemTop}>
              <img src={socialIcon} alt="socialMediaIcon" />
            </Box>
            <Typography
              className={classes.templateSuggestionSectionItemContent}
            >
              Renting vs buying
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
export default withStyles(styleSheet, { name: "SuggestedSectionStyles" })(
  SuggestedSection
);
