export const styleSheet = (theme) => ({
  pageRoot: {
    padding: "20px",
    marginBottom: "16px",
  },
  listingStatsCard: {
    display: "flex !important",
    justifyContent: "flex-start !important",
    gap: "20px !important",
    alignItems: "flex-start !important",
    padding: "20px !important",
    marginBottom: "20px !important",
  },
  listingStatsCardLeft: {
    width: "306px !important",
    "& img": {
      objectFit: "cover !important",
      width: "100% !important",
      height: "100% !important",
    },
    "& .MuiAvatar-root": {
      position: "relative",
      width: "306px",
      height: "215px",
      marginRight: "30px",
      [theme.breakpoints.down("md")]: {
        marginRight: 0,
      },
    },
  },
  TransactionUserInfoLeftPanel: {
    display: "flex",
  },
  listingStatsCardRight: {
    flex: 1,
  },
  statsItem: {
    backgroundColor: "#D9D9D980 !important",
    borderRadius: "7px !important",
    width: "100% !important",
    padding: "8px 10px !important",
    border: "2px solid  transparent !important",
    "&:hover": {
      "& .editButton": {
        display: "block !important",
      },
    },
  },
  statsItemEmpty: {
    backgroundColor: "white !important",
    borderRadius: "7px !important",
    width: "100% !important",
    padding: "8px 10px !important",
    border: "2px solid #D9D9D980 !important",
    cursor: "pointer !important",
    "&:hover": {
      backgroundColor: "rgba(217, 217, 217, 0.15) !important",
    },
  },
  TransactionUserInfoDetailStateDes: {
    fontSize: "14px !important",
    fontWeight: "500 !important",
    textTransform: "capitalize !important",
    color: "rgba(0, 0, 0, 0.6)",
    textAlign: "center !important",
    lineHeight: "14px !important",
    marginTop: "6px !important",
    marginBottom: "-3px !important",
    whiteSpace: "nowrap !important",
  },
  TransactionUserInfoDetailStateValue: {
    fontSize: "20px !important",
    fontWeight: "400 !important",
    textTransform: "capitalize !important",
    color: "rgba(0, 0, 0, 0.87)",
    position: "relative !important",
    textAlign: "center !important",
    lineHeight: "20px !important",
    whiteSpace: "nowrap !important",
  },
  customTabsUI: {
    "& .Mui-selected": {
      color: "#0B0909 !important",
    },
  },
  TransactionUserInfoLeftName: {
    fontSize: "20px !important",
    fontWeight: "600 !important",
    textTransform: "capitalize !important",
    color: "rgba(0, 0, 0, 0.87)",
  },
  userInfoDetailPhone: {
    fontSize: "13px !important",
    fontWeight: "500 !important",
    textTransform: "capitalize !important",
    color: "rgba(0, 0, 0, 0.87)",
  },
  userInfoDetailEmail: {
    fontSize: "13px !important",
    fontWeight: "500 !important",
    textTransform: "lowercase !important",
    color: "rgba(0, 0, 0, 0.87)",
  },
  customerTeamChip: {
    width: "176px",
    marginTop: "10px",
    background: "rgba(102, 187, 106, 0.5) !important",
    color: "black !important",
    height: "34px !important",
    fontSize: "14px !important",
    marginRight: "15px !important",
  },
  userInfoRightPanel: {
    textAlign: "right",
    "& .MuiButton-root": {
      width: "40px!important",
      minWidth: "40px!important",
      height: "40px",
      backgroundColor: "white",
      color: "black",
      marginRight: "10px",
      "&:hover": {
        backgroundColor: "black",
        color: "white",
      },
    },
    "& .MuiButton-outlined": {
      border: "1px solid rgba(33, 33, 33, 0.4) !important",
    },
  },
  agentProfileDetail: {
    fontSize: "13px !important",
    fontWeight: "400 !important",
    textTransform: "capitalize !important",
    minWidth: "10ch",
    color: "rgba(0, 0, 0, 0.6)  !important",
  },
  agentProfileDetailHeader: {
    fontSize: "13px !important",
    fontWeight: "500 !important",
    textTransform: "capitalize !important",
    minWidth: "10ch",
  },
  editableLockArea: {
    position: "relative",
  },
  editableLock: {
    position: "absolute",
    right: "0px",
    top: "62px",
  },
  editMLlIcon: {
    position: "absolute !important",
    right: "-24px !important",
    top: "-25px !important",
    border: "1px solid lightgrey !important",
    padding: "2px !important",
  },
});
