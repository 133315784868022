import React, { useState, useEffect, useContext } from "react";
import {
  Avatar,
  Box,
  Typography,
  Button,
  IconButton,
  Skeleton,
} from "@mui/material";
import GoogleMapReact from "google-map-react";
import { renderToString } from "react-dom/server";
import { debounce } from "lodash";
import EastRoundedIcon from "@mui/icons-material/EastRounded";
import "./mapStyle.css";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import flowerIcon from "../../../assets/images/agents/flowerIcon.png";
import PhoneIcon from "@mui/icons-material/Phone";
import { AppContext } from "../../../components/callPanel/callContext";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
function buildContent(profile) {
  const content = document.createElement("div");
  // content.setAttribute(
  //   "style",
  //   `border: 1.5px solid ${property.priceTagBGColor}`
  // );
  const ReactElement = (
    <>
      <div
        className="price-tag"
        style={{
          background: `transparent !important`,
          color: `${profile.priceTagColor}`,
          zIndex: 1,
        }}
      >
        {profile?.profile_images?.isolated_headshot ||
        profile?.profile_images?.profile_img ||
        profile?.profile_images?.profile_img_thumbnail ? (
          <img
            src={
              profile?.profile_images?.isolated_headshot ||
              profile?.profile_images?.profile_img ||
              profile?.profile_images?.profile_img_thumbnail
            }
            className="agent-img"
            alt={profile?.firstName}
            title={`${profile?.first_name} ${profile?.last_name}`}
            style={{
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              zIndex: 0,
            }}
          />
        ) : (
          <Box
            sx={{
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              background: "#8E8E8E",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            title={`${profile?.first_name} ${profile?.last_name}`}
            className="agent-avatar"
          >
            <PersonRoundedIcon sx={{ color: "white" }} />
          </Box>
        )}
      </div>
      <div className="details">
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: "10px",
            width: "280px",
            borderBottom: "1px solid grey",
            paddingBottom: "5px",
          }}
        >
          <Avatar
            src={
              profile?.profile_images?.isolated_headshot ||
              profile?.profile_images?.profile_img ||
              profile?.profile_images?.profile_img_thumbnail
            }
            style={{ width: "30px", height: "30px" }}
            alt={profile?.firstName?.slice(0, 1)}
            variant="rounded"
            title={`${profile?.first_name} ${profile?.last_name}`}
          />
          <Typography sx={{ color: "black", fontSize: "14px" }}>
            {" "}
            {profile?.first_name} {profile?.last_name}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "280px",
            minHeight: "42px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "10px",
            "& .address-copy": {
              display: "none",
            },
            "&:hover": {
              background: "#f4f4f4",
              "& .address-copy": {
                display: "block",
              },
            },
          }}
        >
          <Typography
            sx={{
              color: "black",
              fontSize: "14px",
              mt: "4px",
              whiteSpace: "wrap !important",
              fontFamily: "AvenirNext",
              width: "85%",
            }}
          >
            {" "}
            {profile?.home_address ||
              `${profile?.street || "--"} \n ${profile?.city || ""} ${
                profile?.state || ""
              } ${profile?.postal_code || ""}`}{" "}
          </Typography>
          <div className="address-copy">
            <IconButton id="copyIcon" size="small">
              <ContentCopyRoundedIcon fontSize="small" />
            </IconButton>
          </div>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: "10px",
            width: "280px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <a
              href={`/agent_roster/${profile?.id}/overview`}
              style={{ textDecoration: "none" }}
            >
              <Button
                endIcon={<EastRoundedIcon />}
                size="small"
                variant="text"
                color="inherit"
                sx={{ textTransform: "capitalize", color: "black" }}
              >
                View agent detail
              </Button>
            </a>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <Button
              variant="outlined"
              size="small"
              color="inherit"
              sx={{
                minWidth: "25px",
                width: "34px",
                borderRadius: "8px",
                height: "30px",
              }}
              className="phone_button"
            >
              <PhoneIcon fontSize="small" />
            </Button>
            {profile?.admin_channel_id && profile?.slack_team_id ? (
              <Button
                size="small"
                color="inherit"
                sx={{ minWidth: "25px", borderRadius: "8px", height: "30px" }}
                variant="outlined"
                className="slack_button"
              >
                <img
                  src={flowerIcon}
                  style={{ width: "15px" }}
                  alt="flower icon"
                />
              </Button>
            ) : null}
          </Box>
        </Box>
      </div>
    </>
  );

  content.classList.add("agent_detail");
  const staticElement = renderToString(ReactElement);
  content.innerHTML = staticElement;
  return content;
}

const Marker = (options) => {
  const {
    index,
    marker,
    position,
    map,
    property,
    changed,
    onClick,
    mouseLeaveEvent,
    mouseEnterEvent,
    setMarkers,
  } = options;

  React.useEffect(() => {
    let currentMarker = marker;
    if (currentMarker) {
      currentMarker.map = null;
      currentMarker = null;
    }
    if (!currentMarker && map) {
      const advancedMarkerView =
        new window.google.maps.marker.AdvancedMarkerView({
          position,
          map,
          content: buildContent(property),
          collisionBehavior: window.google.maps.CollisionBehavior.REQUIRED,
        });

      setMarkers(advancedMarkerView);

      const element = advancedMarkerView.element;

      ["pointerenter"].forEach((event) => {
        element.addEventListener(event, (e) => {
          mouseEnterEvent(e, index);
        });
      });

      ["pointerleave"].forEach((event) => {
        element.addEventListener(event, (e) => {
          mouseLeaveEvent(e, index);
        });
      });

      advancedMarkerView.addListener("click", (e) => {
        onClick(e, advancedMarkerView);
      });
    }
  }, [map, changed]);

  return null;
};

function MapSection(props) {
  const { users, selectedAgent, loading } = props;
  const [map, setMap] = useState(null);
  const { dialNumber } = useContext(AppContext);
  const [markers, setMarkers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const debounceMouseLeaveEvent = React.useRef(
    debounce((markers, index) => {
      markers[index].content.classList.remove("highlight");
      markers[index].element.style.zIndex = "";
    }, 800)
  ).current;
  // cleaning up mouse event debounce
  useEffect(() => {
    if (isLoading) {
      setIsLoading(false);
    }
  }, [loading]);
  useEffect(() => {
    return () => {
      debounceMouseLeaveEvent.cancel();
    };
  }, [debounceMouseLeaveEvent]);

  const mapBounds = () => {
    if (users?.length && map) {
      const bounds = new window.google.maps.LatLngBounds();
      users.forEach((marker) => {
        if (marker?.latitude && marker?.longitude) {
          bounds.extend(
            new window.google.maps.LatLng(marker?.latitude, marker?.longitude)
          );
        }
      });
      map?.fitBounds(bounds);
      // setZoomLevel(map?.getZoom() || 10);
      if (bounds.getCenter()) {
        return bounds.getCenter();
      }
      return null;
    }
  };

  // console.log(mapBounds()?.lat(), "users");
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        borderRadius: "5px",
        overflow: "hidden",
      }}
    >
      {isLoading ? (
        <Skeleton
          variant="rectangular"
          sx={{
            height: "100%",
            width: "100%",
            borderRadius: "5px",
          }}
        />
      ) : (
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.REACT_APP_MAP_KEY,
            libraries: ["marker"],
            version: "beta",
          }}
          yesIWantToUseGoogleMapApiInternals={true}
          options={{
            mapId: "d089a9e24f93a07e",
            clickableIcons: true,
          }}
          defaultCenter={
            selectedAgent && selectedAgent?.latitude && selectedAgent?.longitude
              ? {
                  lat: parseFloat(selectedAgent?.latitude),
                  lng: parseFloat(selectedAgent?.longitude),
                }
              : mapBounds()?.lat
              ? mapBounds()
              : {
                  lat: parseFloat(26.0077552),
                  lng: parseFloat(-80.18972959999999),
                }
          }
          center={
            selectedAgent && selectedAgent?.latitude && selectedAgent?.longitude
              ? {
                  lat: parseFloat(selectedAgent?.latitude),
                  lng: parseFloat(selectedAgent?.longitude),
                }
              : null
          }
          defaultZoom={16}
          onGoogleApiLoaded={({ map, maps }) => {
            setMap(map);
          }}
          resetBoundsOnResize={false}
        >
          {map &&
            users?.filter((it) => it.latitude && it.longitude)?.length &&
            users
              ?.filter((it) => it.latitude && it.longitude)
              .map((item, index) => (
                <Marker
                  index={index}
                  marker={markers[index] || null}
                  position={{
                    lat: parseFloat(item?.latitude),
                    lng: parseFloat(item?.longitude),
                  }}
                  setMarkers={(markerView) => {
                    setMarkers((m) => {
                      m[index] = markerView;
                      return m;
                    });
                  }}
                  onClick={(event, markerView) => {
                    if (
                      event.domEvent.target.className === "price-tag" ||
                      event.domEvent.target.className === "agent-img" ||
                      event.domEvent.target.closest(".agent-avatar")
                    ) {
                      const infoWindow = new window.google.maps.InfoWindow();
                      infoWindow.setPosition({
                        lat: parseFloat(item?.latitude),
                        lng: parseFloat(item?.longitude),
                      });
                      infoWindow.setContent("<div></div>");

                      infoWindow.open(map);

                      for (let i = 0; i < markers.length; i++) {
                        if (i !== index) {
                          if (markers[i]) {
                            markers[i].content.classList.remove("highlight");
                            markers[i].element.style.zIndex = "";
                          }
                        }
                      }

                      markers[index].content.classList.add("highlight");
                      markers[index].element.style.zIndex = 9999999;
                    }
                    if (event.domEvent.target.closest(".address-copy")) {
                      if (item?.home_address || item?.street) {
                        navigator.clipboard.writeText(
                          item?.home_address ||
                            `${item?.street || "--"} \n ${item?.city || ""} ${
                              item?.state || ""
                            } ${item?.postal_code || ""}`
                        );
                      }
                    }
                    if (event.domEvent.target.closest(".phone_button")) {
                      dialNumber(
                        item.phone,
                        null,
                        `${item?.first_name} ${item?.last_name}`,
                        null,
                        item.id
                      );
                    }
                    if (event.domEvent.target.closest(".slack_button")) {
                      window.open(
                        `slack://channel?team=${item?.slack_team_id}&id=${item?.admin_channel_id}`
                      );
                    }
                  }}
                  mouseLeaveEvent={() => {
                    debounceMouseLeaveEvent(markers, index);
                  }}
                  mouseEnterEvent={() => {
                    debounceMouseLeaveEvent.cancel();
                  }}
                  map={map}
                  property={{
                    ...item,
                  }}
                />
              ))}
        </GoogleMapReact>
      )}
    </Box>
  );
}

export default MapSection;
